import { AuthModule } from '@tracktik/tt-authentication'

import { EventManagerInterface } from '@/tt-event-manager'
import { ValueOf } from '@tracktik/tt-helpers/lib/types'

export enum MetaKey {
  resources = 'resources',
  openApiSchema = 'openApiSchema',
  localization = 'localization',
}

export const ApiSchemaUrl: Record<MetaKey, string> = {
  resources: '/rest/v1/schemas',
  openApiSchema: '/rest/schema/v1/schema.json',
  localization: '/rest/v1/schemas/locales',
}

export type Meta = Record<MetaKey, any>

export interface FetchAllMetadatasDependencies {
  authModule: AuthModule
  eventManager: EventManagerInterface
  domainUrl?: string
  taskManager?: any
}

export type MetadataProviderInterface = {
  fetchAll(deps: FetchAllMetadatasDependencies): Promise<Meta>
  get<T extends MetaKey>(key: T): ValueOf<Meta[T]>
  hasFetchingErrors(): boolean
  isRemote(): boolean
  resetAll(): void
  setAll(meta: Meta, deps: { eventManager: EventManagerInterface }): void
  setRemote(remote: boolean): void
}
