<template>
  <v-container>
    <div v-if="isLoadingSchema && !form">
      <v-skeleton-loader v-for="index in 4" :key="index" type="list-item" />
    </div>
    <div v-if="form && !isLoadingSchema">
      <v-row no-gutters>
        <v-col>
          <ReportFieldsForm
            v-model="formModel"
            :form="form"
            @input="setReportFormValues($event)"
            @valid="$emit('has-errors', !$event)"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { AppContext } from '@/tt-app-context'
import ReportFieldsForm from '@/tt-widget-views/reports/components/ReportFieldsForm.vue'
import { Resources } from '@/tt-entity-design/src/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { FormHookProvider } from '@/tt-widget-components'
import { ReportFormData } from '@/tt-widget-views/reports/types'
import { DispatchTaskFormServiceProvider } from './types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormReportField',
  components: { ReportFieldsForm },
  inject: ['formHook', 'service'],
  data() {
    return {
      form: null as ReportFormData,
      formModel: null as Record<string, unknown>,
      isLoadingSchema: false,
    }
  },
  computed: {
    reportTemplateId(): number | null {
      return this.formHook().getPathValue('reportTemplate')
    },
  },
  watch: {
    reportTemplateId() {
      // If we have ID we fetch report template
      if (this.reportTemplateId) {
        this.fetchReport(this.reportTemplateId)
        // Else we reset values
      } else {
        this.resetForm()
      }
    },
  },
  methods: {
    setReportFormValues(values: Record<string, unknown>): void {
      this.service.setReportFormValues(values)
    },
    resetForm() {
      this.form = null
      this.formModel = null
      this.service.setReportFormValues(null)
    },
    fetchReport(id: number) {
      const appContext: AppContext = this.$appContext

      this.isLoadingSchema = true

      const setState = ({ dispatcherJsonFormSchema }) => {
        this.form = dispatcherJsonFormSchema
        this.formModel = this.form.values
      }

      return appContext.authModule
        .getApi()
        .get(Resources.REPORT_TEMPLATES, id, {
          extension: ['dispatcherJsonFormSchema'],
        })
        .then(setState)
        .catch((error) => this.onSubmitError(error))
        .finally(() => {
          this.isLoadingSchema = false
        })
    },
    onSubmitError(error): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: error,
      })
    },
  },
})
</script>
