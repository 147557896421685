<template>
  <v-simple-table
    fixed-header
    height="100%"
    dense
    class="tql-query-widget-table"
    :class="tableClass"
    style="overflow: auto; height: 100%"
    :style="tableStyle"
  >
    <thead>
    <tr>
      <th
        v-for="(col,i) in columns"
        :key="`header_${i}`"
        :class="col.class"
        :style="col.size?{width:col.size}:null"
        v-text="col.text"
      />
    </tr>
    </thead>
    <tbody>
    <template v-for="(row, k) in rowData">
      <TQLRow :key="`row_${k}`" @click="$emit('rowClick',row)" :columns="columns" :row-data="row" :row-index="k"/>
    </template>
    </tbody>
    <tfoot v-if="footData">
    <TQLRow :key="`row_foot`" :columns="columns" :row-data="footData"/>
    </tfoot>
  </v-simple-table>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue'
import {TQLMarkup} from "@/tt-widget-components/widgets/TQLQuery/markup";
import TQLRow from "@/tt-widget-components/widgets/TQLQuery/TQLRow.vue";
import {TQLColumn} from "@/tt-widget-components/widgets/TQLQuery/TQLQueryWidgetHook";

export default Vue.extend({
  name: 'TQLTable',
  components: {TQLRow},
  computed: {
    tableClass(): string {
      return this.templateMarkup?.template?.class || ''
    },
    tableStyle(): string {
      return this.templateMarkup?.template?.style || ''
    }
  },
  props: {
    columns: {
      type: Array as PropType<TQLColumn[]>,
      required: true
    },
    rowData: {
      type: Array as PropType<any>,
      required: true
    },
    footData: {
      type: Object
    },
    templateMarkup: {
      type: Object as PropType<TQLMarkup>
    }
  }
})
</script>
<style>
.cell-warning {
  background-color: #FFFAC6;
}

.dark .cell-warning {
  background-color: #5B5B0B;
}

.row-subtotal {
  font-weight: bold;
  margin-bottom: 10px;
}

.row-subtotal td {
  border-top: 1px solid #999;
  border-bottom: 2px solid #999 !important;

  font-weight: bold;
  //padding: 8px !important;
}
.row-subtotal:last-child td{
  border-bottom: none !important;
}

</style>
