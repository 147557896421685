import { TTCloudLocale } from '@tracktik/tt-authentication'

export const ttCloudLocales: TTCloudLocale[] = [
  'EN_US',
  'FR',
  'FR-BE',
  'RO',
  'ES',
  'DE',
  'NL',
  'NL-BE',
  'PT',
  'SV',
  'ZH-HANS',
  'TH',
]

export const frontendLocales = [
  'en',
  'nl-BE',
  'nl-NL',
  'fr-BE',
  'fr-CA',
  'fr-FR',
  'de-DE',
  'pt-PT',
  'ro-RO',
  'es-ES',
  'es-UY',
  'sv-SE',
  'ar-SA',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'es-MX',
  'fi-FI',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'no-NO',
  'pl-PL',
  'sr-RS',
  'th-TH',
  'tr-TR',
  'vi-VN',
  'zh-CN',
  'zh-HK',
  'de-CH',
  'fr-CH',
  'hr-HR',
  'sk-SK',
  'sl-SI',
] as const

export type SupportedLocale = (typeof frontendLocales)[number]

export interface LanguageOption {
  text: string
  value: SupportedLocale
  rtl?: boolean
}

export const LANGUAGE_OPTIONS_ENGLISH: LanguageOption = {
  text: 'English',
  value: 'en',
}

export const LANGUAGE_OPTIONS_DUTCH_BE: LanguageOption = {
  text: 'Dutch (Belgium) [nl-BE]',
  value: 'nl-BE',
}

export const LANGUAGE_OPTIONS_DUTCH_NL: LanguageOption = {
  text: 'Dutch (Netherlands) [nl-NL]',
  value: 'nl-NL',
}

export const LANGUAGE_OPTIONS_FRENCH_BE: LanguageOption = {
  text: 'French (Belgium) [fr-BE]',
  value: 'fr-BE',
}

export const LANGUAGE_OPTIONS_FRENCH_CA: LanguageOption = {
  text: 'French (Canada) [fr-CA]',
  value: 'fr-CA',
}

export const LANGUAGE_OPTIONS_FRENCH_FR: LanguageOption = {
  text: 'French (France) [fr-FR]',
  value: 'fr-FR',
}

export const LANGUAGE_OPTIONS_GERMAN_DE: LanguageOption = {
  text: 'German (Germany) [de-DE]',
  value: 'de-DE',
}

export const LANGUAGE_OPTIONS_PORTUGUESE_PT: LanguageOption = {
  text: 'Portuguese (Portugal) [pt-PT]',
  value: 'pt-PT',
}

export const LANGUAGE_OPTIONS_ROMANIAN_RO: LanguageOption = {
  text: 'Romanian (Romania) [ro-RO]',
  value: 'ro-RO',
}

export const LANGUAGE_OPTIONS_SPANISH_ES: LanguageOption = {
  text: 'Spanish (Spain) [es-ES]',
  value: 'es-ES',
}

export const LANGUAGE_OPTIONS_SPANISH_UY: LanguageOption = {
  text: 'Spanish (Uruguay) [es-UY]',
  value: 'es-UY',
}

export const LANGUAGE_OPTIONS_SWEDISH_SE: LanguageOption = {
  text: 'Swedish (Sweden) [sv-SE]',
  value: 'sv-SE',
}

export const LANGUAGE_OPTIONS_ARABIC_SA: LanguageOption = {
  text: 'Arabic (Saudi Arabia) [ar-SA]',
  value: 'ar-SA',
}

export const LANGUAGE_OPTIONS_CZECH_CZ: LanguageOption = {
  text: 'Czech (Czechia) [cs-CZ]',
  value: 'cs-CZ',
}

export const LANGUAGE_OPTIONS_DANISH_DK: LanguageOption = {
  text: 'Danish (Denmark) [da-DK]',
  value: 'da-DK',
}

export const LANGUAGE_OPTIONS_GERMAN_AT: LanguageOption = {
  text: 'German (Austria) [de-AT]',
  value: 'de-AT',
}

export const LANGUAGE_OPTIONS_SPANISH_MX: LanguageOption = {
  text: 'Spanish (Mexico) [es-MX]',
  value: 'es-MX',
}

export const LANGUAGE_OPTIONS_FINNISH_FI: LanguageOption = {
  text: 'Finnish (Finland) [fi-FI]',
  value: 'fi-FI',
}

export const LANGUAGE_OPTIONS_HUNGARIAN_HU: LanguageOption = {
  text: 'Hungarian (Hungary) [hu-HU]',
  value: 'hu-HU',
}

export const LANGUAGE_OPTIONS_INDONESIAN_ID: LanguageOption = {
  text: 'Indonesian (Indonesia) [id-ID]',
  value: 'id-ID',
}

export const LANGUAGE_OPTIONS_ITALIAN_IT: LanguageOption = {
  text: 'Italian (Italy) [it-IT]',
  value: 'it-IT',
}

export const LANGUAGE_OPTIONS_JAPANESE_JP: LanguageOption = {
  text: 'Japanese (Japan) [ja-JP]',
  value: 'ja-JP',
}

export const LANGUAGE_OPTIONS_KOREAN_KR: LanguageOption = {
  text: 'Korean (Korea) [ko-KR]',
  value: 'ko-KR',
}

export const LANGUAGE_OPTIONS_NORWEGIAN_NO: LanguageOption = {
  text: 'Norwegian [no-NO]',
  value: 'no-NO',
}

export const LANGUAGE_OPTIONS_POLISH_PL: LanguageOption = {
  text: 'Polish (Poland) [pl-PL]',
  value: 'pl-PL',
}

export const LANGUAGE_OPTIONS_SERBIAN_RS: LanguageOption = {
  text: 'Serbian [sr-RS]',
  value: 'sr-RS',
}

export const LANGUAGE_OPTIONS_THAI_TH: LanguageOption = {
  text: 'Thai (Thailand) [th-TH]',
  value: 'th-TH',
}

export const LANGUAGE_OPTIONS_TURKISH_TR: LanguageOption = {
  text: 'Turkish (Turkey) [tr-TR]',
  value: 'tr-TR',
}

export const LANGUAGE_OPTIONS_VIETNAMESE_VN: LanguageOption = {
  text: 'Vietnamese (Vietnam) [vi-VN]',
  value: 'vi-VN',
}

export const LANGUAGE_OPTIONS_CHINESE_CN: LanguageOption = {
  text: 'Chinese (Traditional) [zh-CN]',
  value: 'zh-CN',
}

export const LANGUAGE_OPTIONS_CHINESE_HK: LanguageOption = {
  text: 'Chinese (Simplified) [zh-HK]',
  value: 'zh-HK',
}

export const LANGUAGE_OPTIONS_GERMAN_CH: LanguageOption = {
  text: 'German (Switzerland) [de-CH]',
  value: 'de-CH',
}

export const LANGUAGE_OPTIONS_FRENCH_CH: LanguageOption = {
  text: 'French (Switzerland) [fr-CH]',
  value: 'fr-CH',
}

export const LANGUAGE_OPTIONS_CROATIAN_HR: LanguageOption = {
  text: 'Croatian (Croatia) [hr-HR]',
  value: 'hr-HR',
}

export const LANGUAGE_OPTIONS_SLOVAK_SK: LanguageOption = {
  text: 'Slovak (Slovakia) [sk-SK]',
  value: 'sk-SK',
}

export const LANGUAGE_OPTIONS_SLOVENIAN_SI: LanguageOption = {
  text: 'Slovenian (Slovenia) [sl-SI]',
  value: 'sl-SI',
}

export const LANGUAGE_OPTIONS = [
  LANGUAGE_OPTIONS_ENGLISH,
  LANGUAGE_OPTIONS_DUTCH_BE,
  LANGUAGE_OPTIONS_DUTCH_NL,
  LANGUAGE_OPTIONS_FRENCH_BE,
  LANGUAGE_OPTIONS_FRENCH_CA,
  LANGUAGE_OPTIONS_FRENCH_FR,
  LANGUAGE_OPTIONS_GERMAN_DE,
  LANGUAGE_OPTIONS_PORTUGUESE_PT,
  LANGUAGE_OPTIONS_ROMANIAN_RO,
  LANGUAGE_OPTIONS_SPANISH_ES,
  LANGUAGE_OPTIONS_SPANISH_UY,
  LANGUAGE_OPTIONS_SWEDISH_SE,
  LANGUAGE_OPTIONS_ARABIC_SA,
  LANGUAGE_OPTIONS_CZECH_CZ,
  LANGUAGE_OPTIONS_DANISH_DK,
  LANGUAGE_OPTIONS_GERMAN_AT,
  LANGUAGE_OPTIONS_SPANISH_MX,
  LANGUAGE_OPTIONS_FINNISH_FI,
  LANGUAGE_OPTIONS_HUNGARIAN_HU,
  LANGUAGE_OPTIONS_INDONESIAN_ID,
  LANGUAGE_OPTIONS_ITALIAN_IT,
  LANGUAGE_OPTIONS_JAPANESE_JP,
  LANGUAGE_OPTIONS_KOREAN_KR,
  LANGUAGE_OPTIONS_NORWEGIAN_NO,
  LANGUAGE_OPTIONS_POLISH_PL,
  LANGUAGE_OPTIONS_SERBIAN_RS,
  LANGUAGE_OPTIONS_THAI_TH,
  LANGUAGE_OPTIONS_TURKISH_TR,
  LANGUAGE_OPTIONS_VIETNAMESE_VN,
  LANGUAGE_OPTIONS_CHINESE_CN,
  LANGUAGE_OPTIONS_CHINESE_HK,
  LANGUAGE_OPTIONS_GERMAN_CH,
  LANGUAGE_OPTIONS_FRENCH_CH,
  LANGUAGE_OPTIONS_CROATIAN_HR,
  LANGUAGE_OPTIONS_SLOVAK_SK,
  LANGUAGE_OPTIONS_SLOVENIAN_SI,
]

export const DEFAULT_LANG: SupportedLocale = 'en'
