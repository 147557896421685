<template>
  <v-card class="user-card d-flex align-center pa-2">
    <div class="mr-2">
      <v-avatar v-show="hasAvatar" size="30">
        <tt-attr-img name="assignedUser.avatar" />
      </v-avatar>
    </div>
    <div class="d-flex flex-column w-100">
      <div class="d-flex justify-space-between w-100">
        <tt-attr
          class="text-truncate caption font-weight-medium"
          name="assignedUser.name"
        />
        <v-chip x-small label class="text-uppercase font-weight-bold ml-2">
          <tt-attr
            v-show="hasCustomId"
            class="text-truncate"
            name="assignedUser.customId"
          />
          <tt-attr
            v-show="!hasCustomId"
            class="text-truncate"
            name="assignedUser.id"
          />
        </v-chip>
      </div>

      <tt-attr
        v-show="hasJobTitle"
        class="text-truncate caption"
        name="assignedUser.jobTitle"
      />

      <tt-attr
        v-show="hasRegion"
        class="text-truncate caption"
        name="assignedUser.region.name"
      />
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksUserCard',
  inject: ['getItemHook'],
  computed: {
    hasCustomId() {
      return !!this.getItemHook().getRawValue('assignedUser.customId')
    },
    hasAvatar() {
      return !!this.getItemHook().getRawValue('assignedUser.avatar')
    },
    hasJobTitle() {
      return !!this.getItemHook().getRawValue('assignedUser.jobTitle')
    },
    hasRegion() {
      return !!this.getItemHook().getRawValue('assignedUser.region.name')
    },
  },
})
</script>

<style scoped>
.user-card {
  position: relative;
  width: 100%;
  min-width: 250px;
}
</style>
