<template>
  <div>
    <json-field name="location.region" :rules="rules" />

    <v-tabs
      v-model="tab"
      no-gutters
      slider-color="orange"
      class="small-tabs mb-2 flex-grow-0"
      dense
      small
      short
    >
      <v-tab
        @click="setLocationType(locationType.ADDRESS, true)"
        v-text="$t('tt-entity-design.dispatch-tasks.form-tabs.new-address')"
      />
      <v-tab
        @click="setLocationType(locationType.COORDINATES, false)"
        v-text="$t('tt-entity-design.dispatch-tasks.form-tabs.coordinates')"
      />
    </v-tabs>

    <v-tabs-items v-model="tab" class="px-3 pt-2">
      <v-tab-item>
        <json-field name="location.name" :rules="rules" />
        <json-field name="location.addressLine1" />
        <json-field name="location.addressLine2" />
        <v-row no-gutters>
          <v-col cols="6" class="pr-4">
            <json-field name="location.city" />
          </v-col>
          <v-col cols="6">
            <json-field name="location.state" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" class="pr-4">
            <json-field name="location.postalCode" />
          </v-col>
          <v-col cols="6">
            <json-field name="location.country" />
          </v-col>
        </v-row>
        <DispatchTasksFormNewClientInformationsFields
          @input="$emit('input:new-client', $event)"
        />
      </v-tab-item>

      <v-tab-item>
        <json-field name="location.latitude" />
        <json-field name="location.longitude" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script lang="ts">
import { FormHookProvider } from '@/tt-widget-components'
import { ErrorObject } from '@tracktik/tt-json-schema-form'
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from './types'
import DispatchTasksFormNewClientInformationsFields from './DispatchTasksFormNewClientInformationsFields.vue'
import { LocationType } from '../types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormLocationTabs',
  components: {
    DispatchTasksFormNewClientInformationsFields,
  },
  inject: ['formHook', 'service'],
  data() {
    return {
      tab: 0,
      rules: [] as Array<(v: string | number) => string | boolean>,
      locationTypeValue: LocationType.ADDRESS,
    }
  },
  computed: {
    locationType(): typeof LocationType {
      return LocationType
    },
    isSavedLocation(): boolean {
      return this.service.getIsSavedLocation()
    },
    error(): ErrorObject[] {
      return [
        {
          dataPath: `.location`,
          keyword: 'required',
          message: this.$t('common.required_field'),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    locationName(): string {
      return this.formHook().getPathValue('location.name')
    },
    locationRegion(): number {
      return this.formHook().getPathValue('location.region')
    },
  },
  watch: {
    locationName(value) {
      if (value && this.isSavedLocation) {
        this.formHook().setCustomError('location.name', null)
      }
      if (!value && this.isSavedLocation) {
        this.formHook().setCustomError('location.name', this.error)
      }
    },
    locationRegion(value) {
      if (value) {
        this.formHook().setCustomError('location.region', null)
      }
      if (!value) {
        this.formHook().setCustomError('location.region', this.error)
      }
    },
    isSavedLocation(value) {
      if (value) {
        this.rules = [(v) => !!v || this.$t('common.required_field')]
        if (!this.locationName) {
          this.formHook().setCustomError('location.name', this.error)
        }
      } else {
        this.rules = []
        this.formHook().setCustomError('location.name', null)
        this.formHook().setCustomError('location.region', null)
      }
    },
  },
  created() {
    this.setLocationType(LocationType.ADDRESS, true)

    if (!this.locationRegion) {
      this.formHook().setCustomError('location.region', this.error)
    }
  },
  methods: {
    setLocationType(type: LocationType, autoGeoCode: boolean) {
      this.locationTypeValue = type

      this.service.setErrorsToWhiteList(type)
      this.formHook().setObjectValue('locationType', type)
      this.formHook().setObjectValue('location.type', type)
      this.formHook().setObjectValue('location.autoGeoCode', autoGeoCode)

      if (type === this.locationType.COORDINATES) {
        this.service.setIsSavedLocation(false)
      }
    },
  },
})
</script>
