import { AppContext } from '@/tt-app-context'
import { Action, Resource } from '@/tt-entity-design/src/schema-types'
import { Resolvable } from '@/tt-widget-views/types'

export type {
  BatchFile,
  BatchFileOperation,
  BatchFileResponse,
} from 'tracktik-sdk/lib/common/batch-file'

export {
  BatchFileActions,
  BatchFileOnFailureOptions,
} from 'tracktik-sdk/lib/common/batch-file'

export type SchemaResourceActionsMap = {
  /**
   * `true` allows all the actions for the relevant resource
   */
  [R in Resource]?: Action<R>[] | true
}

export type AllowEntityOption =
  | boolean
  | Resource[]
  | Resolvable<boolean | Resource[]>

export type AllowEntityActionsOption =
  | AllowEntityOption
  | SchemaResourceActionsMap
export interface AllowEntityOperationsProvider {
  allowEntityActions: AllowEntityActionsOption
  allowEntityCreation: AllowEntityOption
  allowEntityDelete: AllowEntityOption
  allowEntityEdit: AllowEntityOption
  actionBarManager?: ActionbarManager
}

export interface InstallOptions {
  entityIntents: boolean
  pushService: boolean
  defaultExtensions: boolean
  entityDesigns: boolean
  allowEntityEdit?: AllowEntityOption
  allowEntityCreation?: AllowEntityOption
  allowEntityActions?: AllowEntityActionsOption
}

export const defaultInstallOptions: InstallOptions = {
  defaultExtensions: true,
  entityDesigns: true,
  entityIntents: true,
  pushService: false,
}

export type InstallSetupCallback = (
  appContext: AppContext,
  services: any,
) => void

export interface TTCAttributes {
  'data-pane-center': 'vacant_layout'
}

export interface WidgetNavigation {
  href: string
  ttcAttributes: TTCAttributes
}

export type PromiseFunction<T> = (action: string) => Promise<T>

export type ActionbarManager = Record<string, unknown>
