<template>
  <v-autocomplete
    v-model="value"
    :items="addresses"
    :loading="loading"
    :search-input.sync="search"
    :hide-no-data="!search"
    :placeholder="searchPlaceholder"
    outlined
    no-filter
    clearable
    dense
    background-color="transparent"
    color="primary"
    @click:clear="$emit('clear')"
    @change="onChange($event)"
  >
    <template #prepend-inner>
      <v-icon v-text="'search'" />
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import Vue from 'vue'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'
import { Geocoding, createGeocodeSdk } from '@tracktik/tt-geo-proxy'

type VuetifyItem = {
  value: Geocoding
  text: string
}

export default Vue.extend({
  name: 'TsearchAddress',
  data() {
    return {
      entries: [] as Geocoding[],
      value: null as Geocoding | null,
      search: null as string | null,
      loading: false,
    }
  },
  computed: {
    debounceSearch(): DebouncedFunc<() => void> {
      return debounce(() => {
        if (!this.search) {
          this.entries = []
          return
        }
        this.fetchAddress()
      }, 500)
    },
    searchPlaceholder(): string {
      return this.$t('common.label.search_address')
    },
    addresses(): VuetifyItem[] {
      return [...this.entries].map(
        (geocoding): VuetifyItem => ({
          text: geocoding.addressLabel,
          value: geocoding,
        }),
      )
    },
  },
  watch: {
    search() {
      this.debounceSearch()
    },
  },
  methods: {
    onChange(geocoding: Geocoding): void {
      this.$emit('input', geocoding)
    },
    async fetchAddress(): Promise<void> {
      if (this.loading) return

      this.loading = true

      const api = createGeocodeSdk(this.$appContext.authModule)

      await api
        .fetchGeocoding(this.search)
        .then((geocoding) => {
          this.entries = geocoding
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
