<template>
  <div>
    <json-field name="id" />
    <json-field name="taskType" />
    <json-field name="site" />
    <json-field name="jobInstructions" />
    <json-field name="generalInstructions" />
    <json-field name="checkPointTour" />
    <json-field name="priceTier" />
    <json-field name="dispatchSla" />
    <json-field name="generalDispatchSla" />
    <json-field name="dispatchableAssets" />
    <json-field name="schedulableAssets" />
    <json-field name="siteTaskAssets" />
    <span class="text-body-1 grey--text text--darken-3">
      {{ $t('vendors.tabs.assign-job-type-form.assign-vendor') }}
    </span>
    <v-divider class="mt-2 mb-4" />
    <json-field
      :disabled="!selectedTaskType"
      :additional-query-options="additionalQueryOptions"
      name="vendor"
    />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { Filter, FormHookProvider } from '@/tt-widget-components'
export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'TaskSiteInstructionsForm',
  inject: ['formHook'],
  computed: {
    selectedTaskType(): number | null {
      return this.formHook().getPathValue('taskType') || null
    },
    additionalQueryOptions(): { filters: Filter[] } {
      return {
        filters: [
          {
            attribute: 'serviceById',
            operator: 'EQUAL',
            value: this.selectedTaskType,
          },
        ],
      }
    },
  },
  watch: {
    selectedTaskType() {
      this.formHook().setObjectValue('vendor', null)
    },
  },
})
</script>
