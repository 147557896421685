<template>
  <div :class="paginationClasses" class="list-pagination level0">
    <!-- for some reasons, this bad boy emit the click event when we change the limit in DataTables -->
    <!-- https://tracktik.atlassian.net/browse/FE-1423 -->
    <ejs-pager
      :total-records-count="total"
      :page-count="3"
      :page-size="limit"
      :current-page="page"
      @click="updateOffset($event)"
    />
    <v-select
      v-show="showSelect"
      class="select"
      dense
      hide-details
      :items="[10, 20, 50, 100]"
      :value="limit"
      @input="updateLimit($event)"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { OffsetEventUpdate } from '../types'

export default Vue.extend({
  name: 'ListPagination',
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    showSelect(): boolean {
      return this.width > 600
    },
    paginationClasses(): any {
      return {
        'medium-pagination': this.width > 320 && this.width <= 600,
        'small-pagination': this.width > 250 && this.width <= 320,
        'x-small-pagination': this.width > 0 && this.width <= 250,
      }
    },
    page(): number {
      const p = Math.ceil(this.offset / this.limit + 1)
      return p
    },
  },
  methods: {
    updateOffset($event: OffsetEventUpdate) {
      const newOffset = (($event.currentPage as number) - 1) * this.limit
      this.$emit('offsetUpdated', newOffset)
    },
    updateLimit(newLimit: number) {
      this.$emit('limitUpdated', newLimit)
    },
  },
})
</script>

<style scoped lang="scss">
@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-vue-grids/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';

/**
 * Select element
 */
.select {
  font-size: 13px;
  margin-top: 0;
  margin-left: 20px;
  margin-right: auto;
  max-width: 60px;
  position: relative;
}

/**
 * List pagination generic styles
 */
.list-pagination {
  height: auto !important;
  position: sticky !important;
  bottom: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid lightgrey;

  // pager wrapper
  ::v-deep .e-pager {
    background-color: transparent;
    display: flex;
    width: auto;
    align-items: center;
    text-align: center;
    border: none;
    padding: 0;

    // page numbers container
    .e-pagercontainer {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-radius: 0;

      .e-pager-default {
        background: transparent;
      }

      // edges arrows
      .e-firstpage,
      .e-lastpage,
      .e-prevpage,
      .e-nextpage,
      .e-nextpagedisabled,
      .e-lastpagedisabled,
      .e-firstpagedisabled,
      .e-prevpagedisabled {
        margin-right: 0;
        margin-top: 0;
        padding: 10px;
        background-color: transparent;
      }
    }

    // built-in mobile controls will be hidden
    > .e-mfirst,
    > .e-mprev,
    > .e-parentmsgbar,
    > .e-mnext,
    > .e-mlast {
      display: none;
    }
  }
}

/**
 * Extra small (small dashboard columns)
 * 0 > 250
 */
.x-small-pagination {
  ::v-deep .e-pager {
    width: 100%;

    // remove first and last page controls
    & .e-lastpagedisabled,
    & .e-firstpagedisabled,
    & .e-firstpage,
    & .e-lastpage {
      display: none;
    }

    .e-numericitem {
      margin: 0;
    }

    .e-numericitem:not(.e-currentitem) {
      display: none;
    }
  }
}

/**
 * Small (regular dashboard columns)
 * 250 > 320
 */
.small-pagination {
  ::v-deep .e-pager {
    width: 100%;

    // remove first and last page controls
    & .e-lastpagedisabled,
    & .e-firstpagedisabled,
    & .e-firstpage,
    & .e-lastpage {
      display: none;
    }

    .e-numericitem {
      margin: 0;
    }
  }
}

/**
 * Medium (small devices)
 * 320 > 768
 */
.medium-pagination {
  ::v-deep .e-pager {
    width: 100%;
  }
}
</style>
