<template>
  <div class="fill-height" style="background: white">
    <PageTitleBar :title="conflictsTitle">
      <template #default>
        <v-btn small icon @click="$emit('back')">
          <v-icon small> arrow_back </v-icon>
        </v-btn>
      </template>
      <slot />
    </PageTitleBar>
    <v-toolbar class="toolbar3 text-capitalize py-0 px-6" flat short>
      <v-tabs v-model="selectedTab" slider-color="ttPrimary">
        <v-tab
          v-for="conflict in conflictInfo"
          :key="conflict.resource"
          class="text-capitalize px-2 mr-6"
          active-class="active-icon"
        >
          <span class="pr-1">{{ conflict.text }}</span>
          <v-chip
            small
            link
            pill
            :color="conflict.color.background"
            :text-color="conflict.color.text"
          >
            {{ conflict.amount }}
          </v-chip>
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <WidgetContainer style="height: 400px">
      <WidgetFactory v-if="isReady" :widget="listWidget" />
    </WidgetContainer>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  ComponentReference,
  Filter,
  ListWidgetModel,
  Sort,
} from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'
import { ConflictInfo, LeaveRequest, Shift } from './types'
import { Resources } from '../../types'

export default Vue.extend({
  name: 'LeaveRequestsConflictsList',
  props: {
    conflictInfo: {
      type: Array as PropType<ConflictInfo[]>,
      default: () => [],
    },
    tabSelected: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedTab: this.tabSelected,
    }
  },
  computed: {
    isReady(): boolean {
      return this.$appContext.isReady()
    },
    conflictsTitle(): string {
      return this.$t('common.conflicts')
    },
    resource(): string {
      return this.conflictInfo[this.selectedTab].resource
    },
    conflicts(): Shift[] | LeaveRequest[] {
      return this.conflictInfo[this.selectedTab].conflicts
    },
    listFilter(): Filter {
      return {
        attribute: 'id',
        operator: FilterOperatorType.IN,
        value: this.conflicts?.map((request) => request.id).join(','),
      }
    },
    sortByLeaveStart(): Sort {
      return {
        attribute:
          this.resource === Resources.SHIFTS ? 'startDateTime' : 'startedOn',
        direction: 'ASC',
      }
    },
    conflictListItem(): ComponentReference {
      return {
        is:
          this.resource === Resources.SHIFTS
            ? 'ShiftsTile'
            : 'LeaveRequestsTile',
        props:
          this.resource === Resources.LEAVE_REQUESTS
            ? {
                showActions: false,
                isListView: true,
              }
            : {},
      }
    },
    listWidget(): ListWidgetModel {
      return {
        is: 'ListWidget',
        title: '',
        query: {
          resource: this.resource,
          filters: [this.listFilter],
          sort: [this.sortByLeaveStart],
        },
        listItem: this.conflictListItem,
        toolbar: {
          show: false,
        },
        disableResourcePreviewOnClick: true,
      }
    },
  },
})
</script>
