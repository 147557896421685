import {
  Resource,
  ResourceMetaManagerInterface,
  SecurityRule,
  SecurityRuleOperation,
} from '@/tt-widget-factory/services/resource-meta/types'

const getResourceOperationSecurityRules = (
  resourceMetaManager: ResourceMetaManagerInterface,
  resourceName: string,
  operation?: SecurityRuleOperation,
): SecurityRule[] => {
  const resource: Resource = resourceMetaManager.getResource(resourceName)
  const rules: SecurityRule[] = resource?.secured ?? []

  const includesOperation = (rule: SecurityRule) =>
    rule.operations.includes(operation)

  return operation ? rules.filter(includesOperation) : rules
}

const securityRuleToPermissions = (
  rule: SecurityRule,
  resourceName: string,
  operation?: SecurityRuleOperation,
) => {
  const combinations = rule.operations.reduce((acc, ruleOperation) => {
    if (operation && ruleOperation !== operation) return acc
    const pairs = rule.portals.map((portal) => [ruleOperation, portal])
    return [...acc, ...pairs]
  }, [])

  return combinations.map(([operation, portal]) =>
    [portal, resourceName, operation].join(':'),
  )
}

const securityRulesToPermissions = (
  rules: SecurityRule[],
  resourceName: string,
  operation?: SecurityRuleOperation,
): string[] => {
  const getRulePermissions = (rule: SecurityRule) =>
    securityRuleToPermissions(rule, resourceName, operation)

  return rules.map(getRulePermissions).flat()
}

export const getResourcePermissions = (
  resourceMetaManager: ResourceMetaManagerInterface,
  resourceName: string,
  operation?: SecurityRuleOperation,
): string[] => {
  const rules: SecurityRule[] = getResourceOperationSecurityRules(
    resourceMetaManager,
    resourceName,
    operation,
  )
  return securityRulesToPermissions(rules, resourceName, operation)
}
