// Enter your custom installation here

import LeaveRequestsDetail from './LeaveRequestsDetail.vue'
import ApplyActionProvider from '@/tt-entity-design/src/components/leave-requests/ApplyActionProvider.vue'
import LeaveRequestsBtnAction from '@/tt-entity-design/src/components/leave-requests/LeaveRequestsBtnAction.vue'
import LeaveRequestsBtnGroupAction from '@/tt-entity-design/src/components/leave-requests/LeaveRequestsBtnGroupAction.vue'
import LeaveRequestsDropdownAction from '@/tt-entity-design/src/components/leave-requests/LeaveRequestsDropdownAction.vue'
import LeaveRequestsLogForm from '@/tt-entity-design/src/components/leave-requests/LeaveRequestsLogForm.vue'
import LeaveRequestsDetailAffectedShifts from '@/tt-entity-design/src/components/leave-requests/LeaveRequestsDetailAffectedShifts.vue'
import LeaveRequestsApproveForm from './LeaveRequestsApproveForm.vue'
import LeaveRequestsDeclineForm from './LeaveRequestsDeclineForm.vue'
import LeaveRequestsTileListView from './LeaveRequestsTileListView.vue'
import LeaveRequestsApproveCancellationForm from './LeaveRequestsApproveCancellationForm.vue'
import LeaveRequestsDeclineCancellationForm from './LeaveRequestsDeclineCancellationForm.vue'
import LeaveRequestsAffectedShiftsCounter from './LeaveRequestsAffectedShiftsCounter.vue'
import LeaveRequestsRequestedDays from './LeaveRequestsRequestedDays.vue'
import ServiceLevelImpactPercentage from '@/tt-widget-views/leave-management/leave-requests/ServiceLevelImpactPercentage.vue'
import LeaveRequestsDateTimeIconColumn from '@/tt-widget-views/leave-management/leave-requests/LeaveRequestsDateTimeIconColumn.vue'
import LeaveRequestsBalanceDays from './LeaveRequestsBalanceDays.vue'
import LeaveRequestsRequestCancellationForm from './LeaveRequestsRequestCancellationForm.vue'
import LeaveRequestsCancelForm from './LeaveRequestsCancelForm.vue'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { Resources } from '../../types'
import { ListWidgetModel } from '@/tt-widget-components'

export default {
  install(Vue) {
    Vue.component('LeaveRequestsDetail', LeaveRequestsDetail)
    Vue.component('ApplyActionProvider', ApplyActionProvider)
    Vue.component('LeaveRequestsBtnAction', LeaveRequestsBtnAction)
    Vue.component('LeaveRequestsBtnGroupAction', LeaveRequestsBtnGroupAction)
    Vue.component('LeaveRequestsDropdownAction', LeaveRequestsDropdownAction)
    Vue.component('LeaveRequestsLogForm', LeaveRequestsLogForm)
    Vue.component(
      'LeaveRequestsDetailAffectedShifts',
      LeaveRequestsDetailAffectedShifts,
    )
    Vue.component('LeaveRequestsApproveForm', LeaveRequestsApproveForm)
    Vue.component('LeaveRequestsDeclineForm', LeaveRequestsDeclineForm)
    Vue.component(
      'LeaveRequestsApproveCancellationForm',
      LeaveRequestsApproveCancellationForm,
    )
    Vue.component(
      'LeaveRequestsDeclineCancellationForm',
      LeaveRequestsDeclineCancellationForm,
    )
    Vue.component(
      'LeaveRequestsRequestCancellationForm',
      LeaveRequestsRequestCancellationForm,
    )
    Vue.component('LeaveRequestsCancelForm', LeaveRequestsCancelForm)
    Vue.component(
      'LeaveRequestsAffectedShiftsCounter',
      LeaveRequestsAffectedShiftsCounter,
    )
    Vue.component('LeaveRequestsRequestedDays', LeaveRequestsRequestedDays)
    Vue.component('ServiceLevelImpactPercentage', ServiceLevelImpactPercentage)
    Vue.component('LeaveRequestsBalanceDays', LeaveRequestsBalanceDays)
    Vue.component(
      'LeaveRequestsDateTimeIconColumn',
      LeaveRequestsDateTimeIconColumn,
    )
    Vue.component('LeaveRequestsTileListView', LeaveRequestsTileListView)

    const leaveRequestLogList: ListWidgetModel = {
      is: 'ListWidget',
      title: '',
      query: {
        resource: Resources.LEAVE_REQUEST_LOGS,
      },
    }

    addPreviewTab(Resources.LEAVE_REQUESTS, {
      title: 'res.leave-requests.attr.logs.label',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'leaveRequest.id',
        widget: leaveRequestLogList,
      },
    })
  },
}
