<template>
  <div style="display: contents">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { DefinitionOptionMap, FormOptions } from '@tracktik/tt-json-schema-form'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { actionFormTitleOptions } from './types'

export type ApplyActionProvider = { applyAction: (a: string) => void }

export default (
  Vue as VueConstructor<Vue & ItemHookProvider & ApplyActionProvider>
).extend({
  name: 'ApplyActionProvider',
  inject: ['getItemHook'],
  provide() {
    return {
      applyAction: this.applyAction,
    }
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
  methods: {
    applyAction(action: string): void {
      const { eventManager } = this.getItemHook().appContext
      const customFormDefinitionOptions: DefinitionOptionMap = {
        LeaveRequestsApprove: {
          view: {
            is: 'LeaveRequestsApproveForm',
            props: {
              itemHook: this.itemHook,
            },
          },
        },
        LeaveRequestsDecline: {
          view: {
            is: 'LeaveRequestsDeclineForm',
          },
        },
        LeaveRequestsApproveCancellation: {
          view: {
            is: 'LeaveRequestsApproveCancellationForm',
          },
        },
        LeaveRequestsRequestCancellation: {
          view: {
            is: 'LeaveRequestsRequestCancellationForm',
          },
        },
        LeaveRequestsDeclineCancellation: {
          view: {
            is: 'LeaveRequestsDeclineCancellationForm',
          },
        },
        LeaveRequestsCancel: {
          view: {
            is: 'LeaveRequestsCancelForm',
          },
        },
      }

      const formOptions: FormOptions = {
        definitions: customFormDefinitionOptions,
      }

      eventManager.dispatchEvent(EntityIntentTypes.RUN_ACTION, {
        actionName: action,
        ...this.getItemHook().entityReference,
        formOptions,
        title: actionFormTitleOptions[action],
      })
    },
  },
})
</script>
