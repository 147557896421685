<template>
  <div v-if="resource">
    <v-row v-if="formatFormValues.length > 1" class="no-gutters mb-3">
      <v-col>
        <ChipListInput
          class="mb-2"
          color="blue"
          dark
          x-small
          :items="formatFormValues"
          :value="value.format"
          @input="setMeasurePath('format', $event)"
        />
      </v-col>
    </v-row>
    <v-row class="no-gutters">
      <v-col cols="4">
        <v-select
          :disabled="!resource"
          outlined
          dense
          :value="value.operation"
          :label="operationFieldLabel"
          :items="operations"
          :placeholder="$t('components.resource_measure_selector.operation')"
          :error="hasErrors('operation')"
          :attach="true"
          @input="setOperation($event)"
        />
      </v-col>
      <v-col v-if="showAttributeSelection" cols="4" class="pl-2">
        <ResourceAttributeSelector
          :key="value.operation"
          :value="value.attribute"
          disable-selection-scope
          :label="attributeFieldLabel"
          :filter="filter"
          :error="hasErrors('attribute')"
          :content-style="{ maxWidth: '185px' }"
          @input="setMeasurePath('attribute', $event)"
        />
      </v-col>
      <v-col cols="4" class="pl-2">
        <v-text-field
          :disabled="!resource"
          outlined
          :value="value.alias"
          dense
          :label="aliasFieldLabel"
          :placeholder="$t('components.resource_measure_selector.column_name')"
          :error="hasErrors('alias')"
          @input="setMeasurePath('alias', $event, true)"
        />
      </v-col>
      <v-col v-if="isExpression" class="pl-2" @click="displayValidAttributes">
        <v-btn icon>
          <v-icon> mdi-information </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isExpression" no-gutters>
      <v-col>
        <WhereQLEditor v-model="model.attribute" :resource="resource" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { PropType, VueConstructor } from 'vue'

import { capitalize, isEmpty } from 'lodash'

import BaseObjectInput from '@/tt-widget-components/components/BaseObjectInput'
import ChipListInput from '@/tt-widget-components/components/ChipListInput.vue'
import WhereQLEditor from '@/tt-tql-inputs/src/components/WhereQLEditor.vue'
import { AttributeFilterInterface } from '@/tt-widget-factory/services/resource-meta/types'
import { DialogInterface, LayoutWindowEvent } from '@/tt-app-layout'
import { FormHookProvider, NamespaceProvider, ResourceProvider } from '../types'
import { getFormatFormValuesByMeasure } from '@/tt-widget-components/components/measure-helpers'
import {
  MeasureFormat,
  MeasureFormatFormValue,
  MeasureOperation,
  measureOperations,
} from '@/tt-widget-components/components/measure-types'

interface Measure {
  attribute: string
  operation: string
  alias: string
  format: string
}

export default (
  BaseObjectInput as VueConstructor<
    InstanceType<typeof BaseObjectInput> &
      FormHookProvider &
      ResourceProvider &
      NamespaceProvider
  >
).extend({
  name: 'ResourceMeasureSelector',
  components: { ChipListInput, WhereQLEditor },
  inject: ['formHook', 'resourceProvider', 'namespace'],
  props: {
    value: {
      type: Object as PropType<Measure>,
      default: () => ({}),
    },
  },
  data() {
    return {
      operations: measureOperations,
      attributeList: [],
      attributes: ['id'],
      filter: (attributeFilter: AttributeFilterInterface) =>
        attributeFilter.attribute?.measure?.[
          this.value?.operation?.toLowerCase()
        ],
    }
  },
  computed: {
    aliasFieldLabel(): string {
      const fieldName = this.attributeFieldName('alias')
      const baseLabel = this.$t('components.resource_dimension_selector.label')
      return this.formHook().getFieldLabel(fieldName, baseLabel)
    },
    attributeFieldLabel(): string {
      const baseLabel = this.$t('components.resource_measure_selector.of')

      // @todo: first argument should be `this.attributeFieldName('attribute')` FE-835
      return this.formHook().getFieldLabel(this.name, baseLabel)
    },
    operationFieldLabel(): string {
      const fieldName = this.attributeFieldName('operation')
      const baseLabel = this.$t('widget_editor.measure.label')
      return this.formHook().getFieldLabel(fieldName, baseLabel)
    },
    showAttributeSelection(): boolean {
      return !this.isCount && !this.isExpression
    },
    isExpression(): boolean {
      return this.value?.operation === MeasureOperation.EXPRESSION
    },
    isCount(): boolean {
      return this.value?.operation === MeasureOperation.COUNT
    },
    resource(): string | null {
      return this.resourceProvider.resource
    },
    formatFormValues(): MeasureFormatFormValue[] {
      if (!this.value?.operation) return []

      const formatFormValues = getFormatFormValuesByMeasure(
        this.value.operation,
      )
      const selectedWidget = this.formHook().getPathValue('is')
      if (
        selectedWidget === 'PivotTableWidget' ||
        selectedWidget === 'HeatMapWidget'
      ) {
        const isNotSuffix = ({ value }) => value !== MeasureFormat.SUFFIX
        return formatFormValues.filter(isNotSuffix)
      }

      return formatFormValues
    },
    operation: {
      get(): MeasureOperation {
        return this.value.operation
      },
      set(operation: MeasureOperation) {
        this.setMeasure({
          operation,
          attribute: this.getAttributeFromOperation(operation),
          alias: capitalize(operation),
          format: MeasureFormat.ROUND_0,
        })
      },
    },
  },
  methods: {
    attributeFieldName(attribute: string): string {
      return `${this.namespace}.${attribute}`
    },
    displayValidAttributes(): void {
      this.$appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: 'ResourceAttributesDialog',
        title: `widgets.resource_attributes_dialog.title`,
        props: {
          validAttributes:
            this.$appContext.widgetServices.resourceMetaManager.getAttributes(
              this.resource,
              3,
            ),
          resourceName: this.resource,
        },
      } as DialogInterface)
    },
    getAttributeFromOperation(operation: MeasureOperation): string {
      const originalAttribute = this.value.attribute
      // Selected attribute is not supported with the new operation
      const getValidAttribute = () => {
        // prevents expression to inherit attribute value from other operations
        if (operation === MeasureOperation.EXPRESSION) {
          return null
        }
        // Look if the original attribute is compatible
        // with the new operation
        if (originalAttribute) {
          const attribute =
            this.$appContext.widgetServices.resourceMetaManager.getAttribute(
              this.resource,
              originalAttribute,
            )

          return attribute?.measure?.[operation.toLowerCase()]
            ? originalAttribute
            : null
        }
      }

      return operation === MeasureOperation.COUNT ? 'id' : getValidAttribute()
    },
    hasErrors(key: keyof Measure): boolean {
      const errors = this.formHook().errors
      const path = this.attributeFieldName(key)
      return !isEmpty(errors[this.namespace]) || !isEmpty(errors[path])
    },
    setOperation(operation: MeasureOperation): void {
      this.operation = operation
    },
    setMeasure(measure: Measure): void {
      this.formHook().setObjectValue(this.namespace, measure, {
        debounce: false,
      })
    },
    setMeasurePath(
      key: keyof Measure,
      measure: string,
      debounce = false,
    ): void {
      const path = this.attributeFieldName(key)
      this.formHook().setObjectValue(path, measure, { debounce })
    },
  },
})
</script>
