<template>
  <div>
    <json-object :name="dayName">
      <v-row :class="{ disabledRow: !enableRow }">
        <v-col cols="4" class="d-flex">
          <v-checkbox
            v-if="!alwaysEnabled"
            v-model="enableRow"
            :label="effectiveLabel"
          >
            <template #prepend>
              <v-btn
                v-if="hasChildren"
                icon
                small
                @click="expanded = !expanded"
              >
                <v-icon>
                  {{ expanded ? 'mdi-menu-down' : 'mdi-menu-right' }}
                </v-icon>
              </v-btn>
            </template>
          </v-checkbox>
          <div v-else class="mt-5">
            {{ effectiveLabel }}
          </div>
          <v-spacer />
          <!--Fake button to show when the row is disabled -->
          <div v-if="isHoliday && !enableRow" class="mt-1">
            <SchedulingGroupDoNotPerformButton
              :value="parentDoNotPerform"
              disabled
            />
          </div>
          <json-field
            v-else-if="isHoliday && enableRow"
            class="mt-1"
            :name="doNotPerformKey"
            as="SchedulingGroupDoNotPerformButton"
          />
        </v-col>
        <v-col cols="8" @click="enableRow = true">
          <div v-if="!enableRow" inert>
            <!-- Show the parent value if it's available, otherwise just show the disabled inputs for the current object-->
            <slot name="parentValue">
              <v-row>
                <v-col
                  v-for="property in properties"
                  :key="`${dayName}-${property}`"
                  cols="6"
                >
                  <json-field :name="property" />
                </v-col>
              </v-row>
            </slot>
          </div>
          <div v-else>
            <v-row v-if="perform">
              <v-col
                v-for="property in properties"
                :key="`${dayName}-${property}`"
                cols="6"
              >
                <json-field :name="property" />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </json-object>
    <div v-if="hasChildren" v-show="expanded">
      <v-row>
        <v-col cols="11" offset="1">
          <SchedulingGroupDaySetupDayRow
            v-for="day in children"
            :key="`${dayName}-${day}`"
            :day-name="day"
            :is-holiday="isHoliday"
            :parent-do-not-perform="!perform"
          >
            <template #parentValue>
              <div>
                <v-row v-if="perform" class="parentField">
                  <v-col
                    v-for="property in properties"
                    :key="`${dayName}-${property}`"
                    cols="6"
                  >
                    <json-field
                      :name="`${parentName}.${dayName}.${property}`"
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
          </SchedulingGroupDaySetupDayRow>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'

import { FormHookProvider, NamespaceProvider } from '@/tt-widget-components'
import { rangeTimeKeys } from '@/tt-entity-design/src/components/mobile-runsheet-groups/types'

import { weekDays, weekEnds } from '../types'

type VueWithInjections = Vue & FormHookProvider & NamespaceProvider

export default (Vue as VueConstructor<VueWithInjections>).extend({
  name: 'SchedulingGroupDaySetupDayRow',
  inject: ['formHook', 'namespace'],
  props: {
    alwaysEnabled: { type: Boolean, default: false },
    children: { type: Array as PropType<string[]>, default: null },
    dayName: { type: String, required: true },
    disabled: { type: Boolean, default: true },
    isHoliday: { type: Boolean, default: false },
    parentDoNotPerform: { type: Boolean, default: false },
  },
  data() {
    return {
      enableRow: !this.disabled || this.alwaysEnabled,
      expanded: false,
    }
  },
  computed: {
    absoluteName(): string {
      if (!this.name) {
        return ''
      }

      return this.name.includes('.')
        ? this.name
        : this.namespace
        ? `${this.namespace}.${this.name}`
        : this.name
    },
    childrenValues(): Record<string, Record<string, unknown>> | null {
      if (!this.children) return null

      const entries = this.children.map((child) => [
        child,
        this.formHook().getPathValue(this.getFieldPath(child)),
      ])

      return Object.fromEntries(entries)
    },
    childrenHaveValues(): boolean {
      return Object.values(this.childrenValues ?? {}).some(
        (value) => value != null,
      )
    },
    doNotPerformKey(): string {
      return rangeTimeKeys.DO_NOT_PERFORM
    },
    effectiveLabel(): string {
      return this.label || this.translateDayName(this.dayName)
    },
    name(): string {
      return this.dayName
    },
    fieldPath(): string {
      return this.getFieldPath(this.dayName)
    },
    hasChildren(): boolean {
      return this.children?.length > 0
    },
    label(): string {
      return this.viewComponentProps.label
    },
    parentName(): string {
      return this.isHoliday ? 'holidays' : 'days'
    },
    perform(): boolean {
      return (
        !this.isHoliday ||
        !this.formHook().getPathValue(
          `${this.parentName}.${this.dayName}.${this.doNotPerformKey}`,
        )
      )
    },
    properties(): string[] {
      return [rangeTimeKeys.START_TIME_KEY, rangeTimeKeys.END_TIME_KEY]
    },
    value(): Record<string, unknown> {
      return this.formHook().getPathValue(this.fieldPath)
    },
    viewComponentProps(): any {
      return this.formHook().getViewComponentProps(this.absoluteName)
    },
  },
  watch: {
    children: {
      immediate: true,
      handler(children: string[] | null) {
        // Automatically expand row if children have values
        if (children && this.childrenHaveValues && !this.expanded) {
          this.expanded = true
        }
      },
    },
    enableRow(isRowEnabled: boolean) {
      if (isRowEnabled && this.value == null) {
        this.formHook().setObjectValue(this.fieldPath, {})
      } else if (!isRowEnabled && this.value) {
        this.formHook().setObjectValue(this.fieldPath, null)
      }
    },
    value: {
      immediate: true,
      handler(value: Record<string, unknown> | null) {
        // Automatically enables row if it has value
        if (value != null && !this.enableRow) {
          this.enableRow = true
        }
      },
    },
  },
  methods: {
    getFieldPath(dayName: string): string {
      return `${this.parentName}.${dayName}`
    },
    translateDayName(dayName: string): string {
      // @ts-ignore
      const key = [...weekDays, ...weekEnds].includes(dayName)
        ? `res.mobile-runsheets.attr.dayOfWeek.list.${dayName}.label`
        : `scheduling_group_create_wizard.days_setup.${dayName.toLowerCase()}`

      return this.$t(key) as string
    },
  },
})
</script>
<style scoped>
.disabledRow >>> fieldset {
  color: rgba(0, 0, 0, 0.38) !important;
}
.disabledRow >>> .v-input label {
  color: rgba(0, 0, 0, 0.38);
}
[inert] >>> fieldset,
[inert] >>> .v-input input {
  color: rgba(0, 0, 0, 0.38);
}
/* Hide clear button on duplicated fields */
[inert] >>> .v-input__append-inner {
  display: none;
}
.parentField >>> .v-input .error--text {
  color: rgba(0, 0, 0, 0.38) !important;
}

.parentField >>> .v-text-field__details {
  display: none;
}
</style>
