<template>
  <v-navigation-drawer
    :value="isOpen"
    class="hidden-print-only level1 sidesheet"
    clipped
    disable-resize-watcher
    :width="width"
    :temporary="(isOpen && isMobile) || isAbsolute"
    hide-overlay
    right
    app
    stateless
    :style="styles"
    @input="onStateChange"
  >
    <ResizeBox
      v-if="!isMobile"
      :start-width="width"
      :max-width="459"
      :min-width="320"
      use-pixel
      grow-left
      @size-change="width = $event"
    >
      <AppWindow
        :value="isOpen"
        closable
        :event="eventType.SIDE_SHEET"
        :page-stack-handler="pageStackHandler"
        :layout-component-name="layoutComponentName"
        @updatePages="updatePages"
        @input="onStateChange"
      />
    </ResizeBox>
    <AppWindow
      v-if="isMobile"
      :value="isOpen"
      closable
      :event="eventType.SIDE_SHEET"
      :page-stack-handler="pageStackHandler"
      :layout-component-name="layoutComponentName"
      @updatePages="updatePages"
      @input="onStateChange"
    />
  </v-navigation-drawer>
</template>

<script lang="ts">
import { PropType } from 'vue'

import BaseAppLayoutComponent from './BaseAppLayoutComponent'
import LayoutManager from '../LayoutManager'
import ResizeBox from './ResizeBox.vue'
import { createLayoutPageStackHandler } from '../LayoutPageStackHandler'
import { LayoutComponents, PageInterface, LayoutWindowEvent } from '../types'
import { PageStackHandler } from '../PageStackHandler'

export enum SideSheetBehaviour {
  Adaptative = 'ADAPTATIVE',
  Overlay = 'OVERLAY',
}

const SIDE_SHEET_WIDTH = 459

export default BaseAppLayoutComponent.extend({
  name: 'AppLayoutSideSheet',

  props: {
    behaviour: {
      type: String as PropType<SideSheetBehaviour>,
      default: SideSheetBehaviour.Adaptative,
    },
    top: {
      type: Number,
      default: undefined,
    },
  },
  data: () => ({
    width: SIDE_SHEET_WIDTH,
    unsubscribe: undefined,
  }),
  watch: {
    isOpen(newValue: boolean) {
      window.dispatchEvent(new Event('resize'))
      if (newValue === false) {
        this.$eventManager.dispatchEvent(
          LayoutWindowEvent.SIDE_SHEET_CLOSED,
          {},
        )
      }
    },
  },
  components: {
    ResizeBox,
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isAbsolute(): boolean {
      return !!this.layoutManager.configuration.sideSheet?.absolute
    },
    isOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
    isOverlay(): boolean {
      return this.behaviour === SideSheetBehaviour.Overlay
    },
    hasAtLeastOnePage(): boolean {
      return this.pageStackHandler.hasAtLeastOnePage()
    },
    startWidth() {
      return SIDE_SHEET_WIDTH
    },
    styles(): Record<string, string> | void {
      if (this.top != null) {
        return {
          maxHeight: `calc(100vh - ${this.top}px) !important`,
          top: `${this.top}px !important`,
        }
      }
    },
    pageStackHandler(): PageStackHandler {
      return createLayoutPageStackHandler(
        this.layoutManager,
        LayoutComponents.sideSheet,
      )
    },
    currentPages(): PageInterface[] {
      return this.pageStackHandler.getPageStack()
    },
    layoutComponentName(): LayoutComponents {
      return LayoutComponents.sideSheet
    },
  },
  mounted() {
    this.unsubscribe = this.$eventManager.subscribeEvent(
      LayoutWindowEvent.SIDE_SHEET_EDIT_TITLE,
      (payload: { title: string }) => {
        this.updateTitle(payload.title)
      },
    )
  },
  methods: {
    updateTitle(widgetType: string): void {
      const title = widgetType
        ? this.$tc(`widget_edit.${widgetType}.label`)
        : this.$tc(`widget_edit.editor_title.label`)

      this.layoutManager.setPageTitle(
        LayoutComponents.sideSheet,
        title,
        this.currentPages.length - 1,
      )
    },
    close(): void {
      this.pageStackHandler.reset()
      this.layoutManager.close(LayoutComponents.sideSheet)
    },
    onStateChange(isOpening: boolean): void {
      if (!isOpening) this.close()
    },
    updatePages(pages): void {
      this.layoutManager.setPages(LayoutComponents.sideSheet, pages)
    },
  },
})
</script>
