import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { TextClass } from '@/helpers/text/typo'

export default [
  {
    attributeName: 'name',
    style: {
      cssClass: TextClass.BOLD,
    },
  },
  'customId',
  'category',
  'manufacturer',
  'isCheckable',
  'isBundle',
  {
    attributeName: 'beContainedIntoBundle',
    headerText: 'tt-entity-design.asset-hub.be-contained-in-bundle',
  },
  'isSerialNumberUnique',
  {
    attributeName: 'status',
    component: {
      is: 'AssetsStatusAttr',
    },
  },
] as ColumnInputDefinition[]
