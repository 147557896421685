import { CTAType, Status } from './type'
import { TYPE_VALUES } from './constants'

const dispatchableType = [
  TYPE_VALUES.PANIC_TRIGGERED_TICKET,
  TYPE_VALUES.INACTIVE_MOBILE_USER_TICKET,
  TYPE_VALUES.GPS_DISABLED_TICKET,
  TYPE_VALUES.THRESHOLD_ALERT_TICKET,
  TYPE_VALUES.THRESHOLD_WARNING_TICKET,
  TYPE_VALUES.TIMER_EXCEEDED_TICKET,
]

const SYSTEM_EXCEPTION_TICKET_STATUS_CLASS: Record<Status, string> = {
  OPEN: 'font-weight-bold text--darken-2 red--text red lighten-5 text-uppercase',
  CLOSED:
    'font-weight-bold text--darken-2 green--text green lighten-5 text-uppercase',
  PENDING:
    'font-weight-bold text--darken-2 orange--text orange lighten-5 text-uppercase',
}

const DEFAULT_STATUS_COLOR =
  'font-weight-bold text--darken-2 grey--text grey lighten-5 text-uppercase'

export const getStatusClass = (status: string) =>
  SYSTEM_EXCEPTION_TICKET_STATUS_CLASS[status] || DEFAULT_STATUS_COLOR

export const isDispatchableSystemExceptionTickets = (type: CTAType): boolean =>
  dispatchableType.includes(type)

export const isPanicTriggeredTicket = (type: CTAType): boolean =>
  TYPE_VALUES.PANIC_TRIGGERED_TICKET === type
