<template>
  <div class="tree--container">
    <v-divider class="mx-3" />
    <TreeviewSelector
      v-model="selected"
      :items="items"
      class="content px-6 pt-0 pb-4"
    />
    <v-divider class="mx-3" />
    <v-toolbar flat>
      <v-spacer />
      <v-btn
        :disabled="!selected.length"
        text
        color="ttPrimary"
        @click="onClear"
      >
        <span v-text="$t('common.clear.btn')"></span>
      </v-btn>
      <v-btn
        :disabled="!selected.length"
        class="ml-2 white--text"
        color="ttPrimary"
        @click="onApply"
      >
        <span v-text="$t('common.apply.btn')"></span>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { Filter, FilterWithValue } from '../schemas-types'
import { TreeviewItem } from './treeview/types'
import TreeviewSelector from './treeview/TreeviewSelector.vue'

const filterSelectedValues = (value: Filter['value']): string[] =>
  (value ?? '')
    .toString()
    .split(',')
    .filter((item) => item !== '')

export default Vue.extend({
  name: 'AttributeTreeFilter',
  components: { TreeviewSelector },
  props: {
    filter: {
      type: Object as PropType<FilterWithValue>,
      required: true,
    },
    items: {
      type: Array as PropType<TreeviewItem[]>,
      default: () => [],
    },
  },
  data() {
    return {
      selected: filterSelectedValues(this.filter.value),
    }
  },
  methods: {
    onApply(): void {
      this.$emit('apply', this.selected)
    },
    onClear(): void {
      this.$emit('clear')
    },
  },
})
</script>

<style scoped>
.tree--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
.content {
  overflow: auto;
}
</style>
