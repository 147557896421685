<template>
  <div>
    <InfoBox>{{
      $t('widgets.tql.export_option_input.info_box.export_options')
    }}</InfoBox>
    <v-switch
      v-model="model.disableExport"
      :label="$t('widgets.tql.export_option_input.info_box.disable_export')"
    />
    <v-divider />
    <template v-if="!model.disableExport">
      <v-text-field
        v-model="model.fileName"
        outlined
        dense
        :label="$t('widgets.tql.export_option_input.info_box.file_name')"
      />

      <CollapsibleHeader
        :title="
          $t(
            'widgets.tql.export_option_input.info_box.collapsible_header_title',
          )
        "
      >
        <v-checkbox
          v-model="model.excludeHeaders"
          outlined
          dense
          :label="
            $t('widgets.tql.export_option_input.info_box.exclude_headers.label')
          "
          persistent-hint
          :hint="
            $t(
              'widgets.tql.export_option_input.info_box.exclude_headers.description',
            )
          "
        />
        <template v-if="model.csvOptions">
          <v-checkbox
            v-model="model.csvOptions.encloseAll"
            outlined
            dense
            :label="
              $t('widgets.tql.export_option_input.info_box.enclose_all.label')
            "
            persistent-hint
            :hint="
              $t(
                'widgets.tql.export_option_input.info_box.enclose_all.description',
              )
            "
          />
          <v-row>
            <v-col>
              <v-text-field
                v-model="model.csvOptions.delimiter"
                outlined
                dense
                :label="
                  $t('widgets.tql.export_option_input.info_box.delimiter')
                "
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="model.csvOptions.enclosure"
                outlined
                dense
                :label="
                  $t('widgets.tql.export_option_input.info_box.enclosure')
                "
              />
            </v-col>
          </v-row>
        </template>
      </CollapsibleHeader>
    </template>
  </div>
</template>
<script lang="ts">
import { ObjectInput } from '@/tt-widget-components/ObjectInput'

export default ObjectInput.extend({
  name: 'TQLQueryExportOptionsInput',
  methods: {
    onCreated(val) {
      if (!val) {
        return val
      }
      // Versioning
      if (val.AdditionalExportOptions) {
        val.csvOptions = val.AdditionalExportOptions
        delete val.AdditionalExportOptions
      }
      val.csvOptions = val.csvOptions ? val.csvOptions : { encloseAll: false }
      return val
    },
  },
  computed: {
    // Return null when it is
    emitNullOnDefault(): true {
      return true
    },
    defaultModel(): {
      disableExport: boolean
      fileName: any
      excludeHeaders: boolean
      csvOptions: {
        encloseAll: boolean
      }
    } {
      return {
        disableExport: false,
        fileName: null,
        excludeHeaders: false,
        csvOptions: {
          encloseAll: false,
        },
      }
    },
  },
})
</script>
