import {
  DefinitionOption,
  DefinitionOptionMap,
} from '@tracktik/tt-json-schema-form'
import isEmpty from 'lodash/isEmpty'
import { Resolvable } from './types'
import VueI18n from 'vue-i18n/types'

export const resolve = <T>(
  resolvable: Resolvable<T>,
  defaultValue?: T,
  args: any[] = [],
): T => {
  const resolved =
    resolvable instanceof Function ? resolvable(...args) : resolvable

  return resolved ?? defaultValue
}

/**
 *
 * @param i18Messages - use this.$i18n.messages as input to this method to check if translations have been loaded
 * @returns whether or not translations have been loaded
 */
export const isTranslationLoaded = (
  i18Messages: VueI18n.LocaleMessages,
): boolean => {
  return !isEmpty(i18Messages)
}

/**
 * Creates a definition of a date time field that considers the timezone sent instead of using the user
 * default timezone, used in the report template builder and the report submission components
 */
export const getReportDateTimeNullableDefinitionOption = (
  currentDefinitions: DefinitionOptionMap = {},
  { timeZone }: { timeZone?: string } = {},
): DefinitionOption => ({
  view: {
    is: 'DateTimeFieldWrapper',
    props: {
      ...currentDefinitions?.DateTimeNullable?.view?.props,
      options: { timeZone },
    },
  },
})
