import { VueConstructor } from 'vue'

import WeekSelector from '@/tt-entity-design/src/components/WeekSelector.vue'
import widgetEntityActionFormInstaller from '@/tt-widget-entity-action-forms'

import ChipLabelBoolean from './components/ChipLabelBoolean.vue'
import presetDefaults from './components/entities'
import SchedulePreview from './components/SchedulePreview.vue'
import TruncateText from './components/TruncateText.vue'
import ChipLabelText from './components/ChipLabelText.vue'
import FallbackReference from './components/FallbackReference.vue'
import CheckBooleanColumn from './components/CheckBooleanColumn.vue'
import { PagerPlugin } from '@syncfusion/ej2-vue-grids'

let imported = false

export function importEntityDesigns(Vue: VueConstructor) {
  if (imported) {
    return
  }

  Vue.use(widgetEntityActionFormInstaller)
  Vue.use(presetDefaults)
  Vue.use(PagerPlugin)
  Vue.component('ChipLabelBoolean', ChipLabelBoolean)
  Vue.component('ChipLabelText', ChipLabelText)
  Vue.component('SchedulePreview', SchedulePreview)
  Vue.component('TruncateText', TruncateText)
  Vue.component('WeekSelector', WeekSelector)
  Vue.component('FallbackReference', FallbackReference)
  Vue.component('CheckBooleanColumn', CheckBooleanColumn)

  imported = true
}
