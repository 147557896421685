import { AppContext } from '@/tt-app-context'
import { Resources } from '@/tt-entity-design/src/types'
import { AttributeName } from '@/tt-widget-components/lib/names'

export type ContextAccount = {
  id: number
  timeZone: string
}

type AccoundId = string | number

const { ACCOUNT_ATTRIBUTE } = AttributeName

/**
 * Service to get/set the context account.
 *
 * Sets the account as a filter into the main ContextManager,
 * and sets the account timezone into the authModule to be used throughout the app (in place of the user session timezone).
 */
export const ContextAccountHandler = (appContext: AppContext) => {
  const setAccountFilter = (account: ContextAccount) => {
    appContext.contextManager.setContextFilter({
      attribute: ACCOUNT_ATTRIBUTE,
      operator: 'EQUAL',
      value: account.id,
    })
    return account
  }

  const removeAccountFilter = () =>
    appContext.contextManager.removeContextFilter(ACCOUNT_ATTRIBUTE)

  const setTimezone = (account: ContextAccount) => {
    appContext.authModule.setCustomTimezone(account.timeZone)
    return account
  }

  const fetchAccount = (id: AccoundId) =>
    appContext.authModule
      .getApi()
      .get<AccoundId, ContextAccount>(Resources.ACCOUNTS, id, {
        fields: [{ attribute: 'id' }, { attribute: 'timeZone' }],
      })

  return {
    get: (): number | undefined => {
      const contextFilter =
        appContext.contextManager.getContextFilter(ACCOUNT_ATTRIBUTE)
      return contextFilter?.value as number
    },
    set: async (accountId: AccoundId): Promise<ContextAccount | undefined> => {
      if (!accountId) {
        removeAccountFilter()
        return
      }

      return fetchAccount(accountId)
        .then(setAccountFilter)
        .then(setTimezone)
        .catch((err) => {
          console.error(err)
          throw err
        })
    },
  }
}
