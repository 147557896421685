<template>
  <ResourceAllowedOperations v-slot="{ allowsEdit }" :resource-name="'reports'">
    <v-btn
      v-if="allowsEdit"
      small
      fab
      dark
      absolute
      right
      color="green"
      class="mt-2"
      @click="openForm"
    >
      <v-icon>edit</v-icon>
    </v-btn>
    <TtAttrRelation v-bind="{ name, type }" />
  </ResourceAllowedOperations>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { PresetTypes } from '@/tt-app-modular'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { LayoutWindowEvent } from '@/tt-app-layout'
import DispatchTasksEditForm from './DispatchTasksEditForm.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksReportWrapper',
  inject: ['getItemHook'],
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<PresetTypes>,
      default: PresetTypes.RELATION_REFERENCE,
    },
  },
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
  },
  methods: {
    openForm() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: DispatchTasksEditForm,
        title: 'tt-entity-design.edit-form',
        props: {
          reportId: this.itemHook.getRawValue('report.id'),
        },
      })
    },
  },
})
</script>
