<template>
  <tt-tile v-bind="$props" class="dispatch-tasks--tile">
    <div slot="title">
      <tt-attr name="taskType" class="font-weight-bold" />
    </div>
    <div slot="line2" class="pb-5">
      <tt-attr name="createdOn" class="dispatch-tasks--attr" />
    </div>

    <div slot="line3">
      <template v-if="assignedUserId">
        <tt-attr name="assignedUser" />
      </template>
      <template v-else-if="assignedGroupResource && assignedGroupEntityId">
        <EntityPresetRelation
          :resource-name="assignedGroupResource"
          :entity="assignedGroupEntity"
          fetch-enabled
        />
      </template>
      <div v-else>
        {{ $t('tt-entity-design.dispatch-tasks.assign-action.not-assigned') }}
      </div>
    </div>
    <div slot="actionRight">
      <DispatchTasksPriorityChipAttr />
    </div>
    <div slot="actionRight" class="dispatch-tasks--line w-100">
      <v-skeleton-loader
        v-if="isLoading"
        type="list-item-two-line"
        min-width="55"
        class="pa-0"
      />
      <template v-else>
        <DispatchTasksSlaAttr v-if="hasSla" />
        <template v-else>
          <div slot="line2">
            <span
              class="dispatch-tasks--line font-weight-bold text-uppercase grey--text"
              v-text="$t('tt-entity-design.dispatch-tasks.elapsed-time')"
            />
          </div>
          <div slot="line3" class="pb-1 text-end">
            <span
              class="dispatch-tasks--line text-h6 text-capitalize font-weight-bold"
              v-text="elapsedTime"
            />
          </div>
        </template>
      </template>
    </div>
  </tt-tile>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import EntityPresetRelation from '@/tt-widget-entity-flow/components/EntityPresetRelation.vue'
import { getElapsedTime } from '@/helpers/formats/dates/utils'
import DispatchTasksPriorityChipAttr from './DispatchTasksPriorityChipAttr.vue'
import DispatchTasksSlaAttr from './sla/DispatchTasksSlaAttr.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksTile',
  components: {
    EntityPresetRelation,
    DispatchTasksPriorityChipAttr,
    DispatchTasksSlaAttr,
  },
  inject: ['getItemHook'],
  computed: {
    isLoading(): boolean {
      return this.getItemHook().loading
    },
    assignedUserId(): number {
      return this.getItemHook().getRawValue('assignedUser.id')
    },
    assignedGroupResource(): string {
      return this.getItemHook().getRawValue('assignedGroup.resourceType')
    },
    assignedGroupEntityId(): number {
      return this.getItemHook().getRawValue('assignedGroup.id')
    },
    assignedGroupEntity(): unknown {
      return {
        id: this.assignedGroupEntityId,
      }
    },
    modifiedOn(): string {
      return this.getItemHook().getRawValue('workflowInstance.modifiedOn')
    },
    elapsedTime(): string {
      if (!this.modifiedOn) return 'N/A'

      return getElapsedTime(this.modifiedOn) ?? 'N/A'
    },
    hasSla(): boolean {
      return !!this.getItemHook().getRawValue('sla.slaStartedOn')
    },
  },
  created() {
    this.getItemHook().addAttribute('assignedUser.id')
    this.getItemHook().addAttribute('assignedGroup.resourceType')
    this.getItemHook().addAttribute('assignedGroup.id')
    this.getItemHook().addAttribute('workflowInstance.modifiedOn')
    this.getItemHook().addAttribute('sla')
    this.getItemHook().addAttribute('workflowInstance.currentStatus.slaAction')
  },
})
</script>
<style scoped>
.dispatch-tasks--tile ::v-deep .v-list-item__action {
  align-self: flex-start;
}

.dispatch-tasks--attr ::v-deep .entity-attribute-cell {
  cursor: text !important;
  pointer-events: none !important;
}

.dispatch-tasks--line {
  line-height: 1.2;
}

.dispatch-tasks--tile ::v-deep .v-list-item__action--stack {
  align-self: stretch !important;
}

.dispatch-tasks--tile ::v-deep .v-skeleton-loader__list-item-two-line {
  padding: 0 !important;
}
</style>
