// Enter your custom installation here
import SystemExceptionTicketsRelationAttr from './SystemExceptionTicketsRelationAttr.vue'
import SystemExceptionTicketsStatusAttr from './SystemExceptionTicketsStatusAttr.vue'
import SystemExceptionTicketsActionBar from './SystemExceptionTicketsActionBar.vue'
import SystemExceptionTicketsNotesListWidget from './notes/SystemExceptionTicketsNotesListWidget.vue'
import { modularManager } from '@/tt-app-modular'
import { Resources } from '../../types'
import { addPreviewTab } from '@/tt-widget-entity-flow/helper'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'

export default {
  install(Vue) {
    Vue.component(
      'SystemExceptionTicketsRelationAttr',
      SystemExceptionTicketsRelationAttr,
    )
    Vue.component(
      'SystemExceptionTicketsStatusAttr',
      SystemExceptionTicketsStatusAttr,
    )
    Vue.component(
      'SystemExceptionTicketsActionBar',
      SystemExceptionTicketsActionBar,
    )
    modularManager.registerActionBar(Resources.SYSTEM_EXCEPTION_TICKETS, {
      is: SystemExceptionTicketsActionBar,
    })

    addPreviewTab(Resources.SYSTEM_EXCEPTION_TICKETS, {
      title: createI18nResourceKey(
        Resources.SYSTEM_EXCEPTION_TICKETS,
        'comments',
      ),
      is: SystemExceptionTicketsNotesListWidget,
    })
  },
}
