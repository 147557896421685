import { Action, Resource } from '../../schema-types'
import { AssignedGroupResourceTypeEnums } from './types'

/**
 * @description Icon dictionnary for assigned group resource type
 */
export const iconDictionnary: Record<
  AssignedGroupResourceTypeEnums,
  { color: string; icon: string }
> = {
  [AssignedGroupResourceTypeEnums.EMPLOYEE]: {
    color: 'green',
    icon: 'mdi-account',
  },
  [AssignedGroupResourceTypeEnums.VENDOR]: {
    color: 'purple',
    icon: 'mdi-link',
  },
  [AssignedGroupResourceTypeEnums.ACCOUNT]: {
    color: 'orange',
    icon: 'mdi-office-building',
  },
  [AssignedGroupResourceTypeEnums.CLIENT]: {
    color: 'blue',
    icon: 'mdi-office-building',
  },
  [AssignedGroupResourceTypeEnums.POSITION]: {
    color: 'teal',
    icon: 'mdi-ballot',
  },
  [AssignedGroupResourceTypeEnums.ZONE]: {
    color: 'green',
    icon: 'mdi-earth',
  },
  [AssignedGroupResourceTypeEnums.DEPARTMENT]: {
    color: 'purple',
    icon: 'mdi-link',
  },
}

/**
 * @description Default icon data
 */
export const defaultIconData: { color: string; icon: string } = {
  color: 'purple',
  icon: 'mdi-link',
}

export const CLIENT_LOCATION_ATTRIBUTE_LIST = [
  'locationType',
  'location.addressLine1',
  'location.addressLine2',
  'location.city',
  'location.state',
  'location.postalCode',
  'location.latitude',
  'location.longitude',
  'location.country',
  'client.address.addressLine1',
  'client.address.addressLine2',
  'client.address.city',
  'client.address.state',
  'client.address.postalCode',
  'client.address.country',
  'client.name',
  'client.customId',
  'client.id',
]

export const ADDRESS_ATTRIBUTE_LIST = [
  'name',
  'customId',
  'id',
  'address.addressLine1',
  'address.addressLine2',
  'address.city',
  'address.state',
  'address.postalCode',
  'address.country',
]

export const USER_ATTRIBUTE_LIST = [
  'assignedUser.customId',
  'assignedUser.id',
  'assignedUser.jobTitle',
  'assignedUser.region.name',
]

export const POSITION_ATTRIBUTE_LIST = [
  'name',
  'customId',
  'id',
  'account.name',
]

export const DEFAULT_ATTRIBUTE_LIST = ['id', 'name']

export const DISPATCH_TASKS: Resource = 'dispatch-tasks'
export const ASSIGN: Action<typeof DISPATCH_TASKS> = 'assign'
