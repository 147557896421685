<template>
  <div v-if="relationResource">
    <v-switch
      v-model="isNull"
      hide-details
      color="ttPrimary"
      dense
      class="pa-6"
      :class="isNull ? 'pb-3' : 'pb-0'"
    >
      <template #label>
        <span class="overline pl-3 switch--label" v-text="isNullLabel" />
      </template>
    </v-switch>

    <v-expand-transition>
      <EntityMultipleSelectorField
        v-if="!isNull"
        v-model="selected"
        :query="relationQuery"
        :resource="relationResource"
        class="pt-0"
      />
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'

import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'

import { Attribute } from '@/tt-widget-factory'
import { RelationAttributeFilterOptions, Filter } from '@/tt-widget-components'
import { EntityMultipleSelectorFieldQuery } from '@/tt-entity-forms/components/EntityMultipleSelectorField.vue'
import { hasMustacheVariable } from '@/tt-entity-forms/components/utils/RelationField'

import BaseFilter from './BaseFilter'

const valueIsNotDynamic = <T extends { value?: any }>(item: T): boolean =>
  typeof item.value !== 'string' || !hasMustacheVariable(item.value)

export default BaseFilter.extend({
  name: 'FilterRelation',
  props: {
    attributeMeta: {
      type: Object as PropType<Attribute>,
      required: true,
    },
    attributeName: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<RelationAttributeFilterOptions>,
      default: (): RelationAttributeFilterOptions => ({}),
    },
    value: {
      type: Object as PropType<Filter | null>,
      default: null,
    },
  },
  computed: {
    selected: {
      get(): string[] {
        const stringValue = this.model?.value
        return stringValue ? stringValue.split(',') : []
      },
      set(newVal: string[] = []) {
        const value = (newVal || []).join(',')

        this.model = {
          attribute: this.attributeName,
          operator: FilterOperatorType.IN,
          value: value,
        }
      },
    },
    isNull: {
      get(): boolean {
        return this.model.operator === FilterOperatorType.ISNULL
      },
      set(isNull: boolean) {
        this.model = isNull
          ? {
              attribute: this.attributeName,
              operator: FilterOperatorType.ISNULL,
            }
          : {
              attribute: this.attributeName,
              operator: FilterOperatorType.IN,
              value: this.selected.join(','),
            }
      },
    },
    relationQuery(): EntityMultipleSelectorFieldQuery {
      const {
        customFilters = [],
        filters = [],
        scopes,
        whereQL,
        ...query
      } = this.attributeMeta.relation?.relationFilter ?? {}

      return {
        ...query,
        filters: [
          ...filters.filter(valueIsNotDynamic),
          ...(this.options.relationFilter?.filters || []),
        ],
        customFilters: [
          ...customFilters.filter(valueIsNotDynamic),
          ...(this.options.relationFilter?.customFilters || []),
        ],
        scope: scopes,
        ...(whereQL && !hasMustacheVariable(whereQL) ? { whereQL } : {}), // remove whereQL if it has a dynamic value
      }
    },
    relationResource(): string | undefined {
      return this.attributeMeta.relation?.resource
    },
    isNullLabel(): string {
      return this.$t('filter.toggle.is_null')
    },
  },
  methods: {
    onInput(input) {
      if (input && input.value) {
        this.selected = input.value.split(',')
      }
    },
    onInputNull() {
      this.selected = []
    },
  },
})
</script>

<style scoped>
.v-switch {
  min-width: 300px;
}

.switch--label {
  white-space: nowrap;
}
</style>
