<template>
  <app-window>
    <AppWindowAggregationClickSubscriber />
    <widget-container class="d-flex flex-column">
      <slot />
      <WidgetContextToolbar
        v-if="showContext"
        v-bind="contextProps"
        :hook="hook"
        :style="'z-index: 1'"
      >
        <template v-if="navigation" #icon>
          <v-app-bar-nav-icon
            :href="navigation.href"
            v-bind="navigation.ttcAttributes"
          >
            <v-icon v-text="'chevron_left'" />
          </v-app-bar-nav-icon>
        </template>

        <!-- We only support CSV export in TTC injected views for now -->
        <WidgetDownloadButton
          v-if="showDownloadButton"
          :hook="hook"
          :disabled="disabledCsvExport"
          show-csv
        />
      </WidgetContextToolbar>

      <WidgetFactory
        :key="JSON.stringify(widgetModel)"
        :widget="widgetModel"
        :props-data="{ container }"
        :skip-validation="skipValidation"
        @update:hook="hook = $event"
      />
    </widget-container>
  </app-window>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import {
  ContextFilter,
  Filter,
  SingleCounterWidgetModel,
  WidgetModels,
} from '@/tt-widget-components'
import {
  WidgetContainerInterface,
  WidgetContainerType,
  WidgetHookInterface,
} from '@/tt-widget-factory/types'
import { WidgetNavigation } from '@/types'
import WidgetDownloadButton from '@/tt-widget-components/components/WidgetDownloadButton.vue'

export default Vue.extend({
  name: 'WidgetPage',
  components: { WidgetDownloadButton },
  props: {
    showContext: Boolean,
    icon: String,
    iconColor: String,
    height: {
      type: Number,
      default: 800,
    },
    widget: {
      required: true,
      type: Object as PropType<WidgetModels>,
    },
    navigation: {
      type: Object as PropType<WidgetNavigation>,
      default: () => undefined,
    },
    skipValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hook: null as WidgetHookInterface | null,
    }
  },
  computed: {
    disabledCsvExport(): boolean {
      return !this.hook.hasDataSource
    },
    showDownloadButton(): boolean {
      return this.hook?.canDownload
    },
    container(): WidgetContainerInterface {
      return {
        type: WidgetContainerType.STANDALONE,
        height: this.height,
      }
    },
    contextProps(): { defaultFilterValues: ContextFilter[] } {
      if (this.widget.is === 'SingleCounterWidget') {
        return {
          defaultFilterValues: this.widget?.contextFilters?.defaults ?? [],
        }
      }
    },
    widgetModel(): WidgetModels {
      return {
        ...this.widget,
      }
    },
  },
})
</script>
