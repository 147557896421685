<template>
  <tt-attr-row-card>
    <WorkflowInstanceTimer />
    <tt-attr-row name="customId">
      <TChip
        attributeName="customId"
        chip-class="grey text--darken-4 lighten-3 text-uppercase"
      />
    </tt-attr-row>
    <tt-attr-row name="priority" />
    <TextAreaAttr
      name="taskInstructions"
      :no-value-placeholder="noInstructionsLabel"
      class="pt-3 pb-2"
    />
    <tt-attr-row name="reminderInMinutes" />
    <tt-attr-row name="workflowInstance.modifiedOn">
      <WorkflowStatusLastChanged />
    </tt-attr-row>
    <tt-attr-row name="workflowInstance.currentStatus" />
    <tt-attr-row name="location" />
    <tt-attr-row name="assignedUser">
      <template v-if="hasAssignedGroupResource" #title>
        {{ rowTitle }}
      </template>
      <DispatchTasksAssignmentAttr />
    </tt-attr-row>
    <tt-attr-row name="client" />
    <tt-attr-row name="taskType" />
    <DispatchTasksWorkflowAvailableEvents />
  </tt-attr-row-card>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import DispatchTasksWorkflowAvailableEvents from './DispatchTasksWorkflowAvailableEvents.vue'
import WorkflowInstanceTimer from './WorkflowInstanceTimer.vue'
import DispatchTasksAssignmentAttr from './DispatchTasksAssignmentAttr.vue'
import TextAreaAttr from '../TextAreaAttr.vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import TChip from '../TChip.vue'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksDetail',
  components: {
    DispatchTasksWorkflowAvailableEvents,
    WorkflowInstanceTimer,
    DispatchTasksAssignmentAttr,
    TextAreaAttr,
    TChip,
  },
  inject: ['getItemHook'],
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    hasAssignedUser(): boolean {
      return !!this.itemHook.getRawValue('assignedUser.id')
    },
    hasAssignedGroupResource(): boolean {
      return (
        !!this.itemHook.getRawValue('assignedGroupResource.type') &&
        !this.hasAssignedUser
      )
    },
    resourceType(): string {
      return this.itemHook.getRawValue('assignedGroupResource.type') || ''
    },
    rowTitle(): string {
      return `${this.$t('common.assigned')} ${this.$t(
        `res.${this.resourceType}.label`,
      )}`
    },
    noInstructionsLabel(): string {
      return this.$t(this.translationKey('no-instructions'))
    },
  },
  created() {
    this.getItemHook().addAttribute('assignedGroupResource')
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.form-fields.${key}`
    },
  },
})
</script>
