import { EventHandler, EventManagerInterface } from '@/tt-event-manager/types'

export class EventLifeCycleManager {
  manager: EventManagerInterface

  uninstall: any[] = []

  constructor(manager: EventManagerInterface) {
    this.manager = manager
  }

  /**
   *
   * @param eventName
   * @param handler
   * @param contextIds
   */
  subscribeTo(eventName: string, handler: EventHandler, contextIds?: string[]) {
    this.uninstall.push(
      this.manager.subscribeEvent(eventName, handler, contextIds),
    )
  }

  /**
   * Destroy
   */
  destroy() {
    this.uninstall.forEach((uninstall) => {
      uninstall()
    })
  }
}
