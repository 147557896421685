<template>
  <div>
    <!-- Jobs/Tasks & Client fields-->
    <span
      class="subtitle-2 pb-2"
      v-text="$t(translationKey('form-fields.job-type'))"
    />

    <json-field
      class="mt-2"
      name="taskType"
      :label="taskTypeLabel"
      :disabled="isEditForm"
    />

    <json-field name="client" :disabled="isEditForm" />

    <!-- Addresses/Coordinate tiles -->
    <div v-if="showMap && !isEditForm">
      <DispatchTasksFormLocationTile />

      <MapCoordinateSelector
        v-if="hasCoordinates"
        class="pt-2"
        hide-search-field
        prevent-click
        :default-coordinates="clientCoordinates"
      />
      <v-banner
        v-else
        class="my-2 pa-2 d-flex justify-center"
        dark
        outlined
        rounded
        color="warning"
        v-text="$t('common.address_not_geocoded')"
      />

      <!-- Change location button -->
      <DispatchTasksFormEditButton @click="openLocationTab" />
    </div>

    <!-- AlarmOrganization fields -->

    <json-field name="alarmOrganization" :disabled="isEditForm" />

    <!-- Instruction fields & taskSiteSettings instructions-->
    <DispatchTasksFormInstructionsField />

    <!-- Dispatch timing fields -->
    <v-row v-if="isEditForm" no-gutters>
      <v-col cols="12">
        <json-field name="startedOn" />
      </v-col>
      <v-col cols="6" class="pr-4">
        <json-field
          name="plannedDurationInMinutes"
          prepend-inner-icon="mdi-timer-outline"
        />
      </v-col>
      <v-col cols="6">
        <json-field
          name="reminderInMinutes"
          prepend-inner-icon="mdi-bell-outline"
        />
      </v-col>
    </v-row>
    <DispatchTasksFormTimingFields v-else />

    <!-- Billing field -->
    <div v-if="isBillable">
      <span
        class="subtitle-2 pb-2"
        v-text="$t(translationKey('form-fields.billing'))"
      />
      <json-field name="priceTier" />
    </div>

    <!-- User assignment field/button -->
    <div v-if="!isEditForm">
      <span
        class="subtitle-2 pb-2"
        v-text="$t(translationKey('form-fields.assignment'))"
      />
      <DispatchTasksFormAssignUser @back="$emit('back')" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import DispatchTasksFormTimingFields from './DispatchTasksFormTimingFields.vue'
import DispatchTasksFormInstructionsField from './DispatchTasksFormInstructionsField.vue'
import DispatchTasksFormAssignUser from './DispatchTasksFormAssignUser.vue'
import DispatchTasksFormLocationTile from './DispatchTasksFormLocationTile.vue'
import MapCoordinateSelector from '@/tt-widget-components/components/map/MapCoordinateSelector.vue'
import DispatchTasksFormEditButton from './DispatchTasksFormEditButton.vue'
import DispatchTasksFormLocationPage from './DispatchTasksFormLocationPage.vue'
import { Coordinates } from '@tracktik/tt-geo-proxy/lib/GeocodeSdk/types'
import { DispatchTaskFormServiceProvider } from './types'
import { ClientInterface } from '../types'
import { LayoutWindowEvent } from '@/tt-app-layout'

export default (
  Vue as VueConstructor<
    Vue &
      FormHookProvider &
      DispatchTaskFormServiceProvider & { namespace: string }
  >
).extend({
  name: 'DispatchTasksFormMainFields',
  components: {
    DispatchTasksFormTimingFields,
    DispatchTasksFormInstructionsField,
    DispatchTasksFormAssignUser,
    DispatchTasksFormLocationTile,
    MapCoordinateSelector,
    DispatchTasksFormEditButton,
  },
  inject: ['formHook', 'service', 'namespace'],
  computed: {
    isBillable(): boolean {
      return this.service.getIsBillable()
    },
    isEditForm(): boolean {
      return this.service.getIsEditForm()
    },
    taskTypeLabel(): string {
      return `${this.formHook().getFieldLabel('taskType')} *`
    },
    clientItems(): ClientInterface | null {
      return this.service.getClientItems()
    },
    isCustomLocation(): boolean {
      return this.service.getIsCustomLocation()
    },
    clientCoordinates(): Coordinates {
      if (this.isCustomLocation) {
        return {
          latitude: this.formHook().getPathValue('location.latitude'),
          longitude: this.formHook().getPathValue('location.longitude'),
        }
      } else {
        return {
          latitude: this.clientItems?.address?.latitude,
          longitude: this.clientItems?.address?.longitude,
        }
      }
    },
    hasCoordinates(): boolean {
      return (
        !!this.clientCoordinates.latitude && !!this.clientCoordinates.longitude
      )
    },
    showMap(): boolean {
      return !!this.clientItems || this.service.getIsCustomLocation()
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.${key}`
    },
    openLocationTab(): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: DispatchTasksFormLocationPage,
        title: this.translationKey('select-custom-location'),
        props: {
          formHook: this.formHook,
          service: this.service,
          namespace: this.namespace,
        },
      })
    },
  },
})
</script>
