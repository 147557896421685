<template>
  <div class="d-flex align-center">
    <tt-attr name="name" :class="cssClass" />
    <tt-if-attr name="isImported" :value="true">
      <v-icon :size="iconSize" class="ml-2" v-text="`$importIcon`" />
    </tt-if-attr>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'AssetPersonNameImportIconAttr',
  props: {
    cssClass: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 14,
    },
  },
})
</script>
