import { parseResourceDates } from '@/tt-widget-factory/helpers/parse-resource-attributes'
import {
  FieldTypes,
  WidgetHookDependencies,
  WidgetState,
} from '@/tt-widget-factory'

import CollectionWidgetHook, {
  CollectionWidgetState,
} from '../../base/CollectionWidgetHook'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import {
  CalendarWeekWidgetModel,
  CollectionQuery,
  Field,
} from '@/tt-widget-components/schemas-types'
import moment from 'moment-timezone'
import { computedGetter } from '@tracktik/tt-helpers/lib/functions/computedGetter'
import { reactive } from 'vue'
import { createDateTimeString } from '@/helpers/dates/createDateTimeString'
import {
  DATE_FORMAT,
  DATE_REFERENCE,
  DAY_END_TIME,
  DAY_START_TIME,
} from './constants'
import {
  CalendarWeekState,
  DayID,
  DayToDate,
  DayName,
  Date,
  ViewEvent,
  ApiEntity,
  WeekCalendarField,
} from './types'
import {
  convertDayIdToDayName,
  convertDayNameToDayId,
  getNextDayName,
} from '@/tt-widget-components/widgets/CalendarWeek/utils'
import { IS_DEV } from '@tracktik/tt-pusher/lib/src/constants'
import { convertTimeToSeconds } from '@/helpers/dates/convertTimeToSeconds'
import { createColorManager } from '@/tt-widget-components/widgets/Scheduler/helper'

export default class CalendarWeekWidgetHook extends CollectionWidgetHook<CalendarWeekWidgetModel> {
  state: WidgetState & CollectionWidgetState

  private calendarWeekState: CalendarWeekState

  constructor(deps: WidgetHookDependencies) {
    super(deps)

    this.calendarWeekState = reactive<CalendarWeekState>({
      // @TODO: change based on user preferences
      firstDayOfWeek: 'SUNDAY',
    })
  }

  setFirstDayOfWeek(day: DayName): void {
    this.calendarWeekState.firstDayOfWeek = day
  }

  /**
   * The ordered `DayID` to display in the week view, starting from the first day of the week defined.
   */
  getOrderedDayIDs: () => DayID[] = computedGetter(() => {
    const orderedWeekdayIndexes = []
    const firstDayID = convertDayNameToDayId(
      this.calendarWeekState.firstDayOfWeek,
    )

    for (let i = 0; i < 7; i++) {
      const dayIndex = (firstDayID + i) % 7
      orderedWeekdayIndexes.push(dayIndex)
    }

    return orderedWeekdayIndexes
  })

  private getOrderedDayNames: () => DayName[] = computedGetter(() =>
    this.getOrderedDayIDs().map(convertDayIdToDayName),
  )

  /**
   * The first date of the current week view, based on the first day of the week.
   */
  getFirstDate: () => Date = computedGetter(() => {
    const firstDay = this.getOrderedDayNames()[0]

    return this.getDaysToDatesMap()[firstDay]
  })

  /**
   * The last date of the current week view.
   */
  getLastDate: () => Date = computedGetter(() => {
    const firstDay = this.getOrderedDayNames()[6]

    return this.getDaysToDatesMap()[firstDay]
  })

  /**
   * The list of events to display in the week view.
   */
  getViewEvents: () => ViewEvent[] = computedGetter(() => {
    const colorManager = createColorManager(this.getColorAttribute())

    return this.entities.flatMap((entity: ApiEntity): ViewEvent[] => {
      const name = entity._name

      const timeStart = entity._timeStart
      // no end time means end of the day
      // @TODO: should come from the API, needs ticket
      const timeEnd = entity._timeEnd || DAY_END_TIME

      /**
       * If the event "end time" is BEFORE the "start time", it means the event ends the next week day.
       */
      const isEndNextDay =
        convertTimeToSeconds(timeEnd) < convertTimeToSeconds(timeStart)

      const dayStart = entity._dayStart
      const dayEnd = isEndNextDay ? getNextDayName(dayStart) : dayStart

      const dateStart = this.getDaysToDatesMap()[dayStart]
      const dateEnd = this.getDaysToDatesMap()[dayEnd]

      const start = createDateTimeString(dateStart, timeStart)
      const end = createDateTimeString(dateEnd, timeEnd)

      const color = colorManager.getEventColor(entity)

      if (!dateStart || !timeStart) {
        console.error(
          'Invalid date or time format. Entity cannot be rendered in the calendar.',
          entity,
        )

        return []
      }

      /**
       * If the event is crossing over the end of the week and the beginning of a new week,
       * (eg: with a starting week day set to SUNDAY and an event between SAT - SUN),
       * we split the event in two, so the end of the event is displayed at the beggining of the week view.
       */
      return moment(end).isBefore(start)
        ? [
            // 1st part of the event -- end of the week
            {
              id: entity.id,
              name,
              start,
              end: createDateTimeString(this.getLastDate(), DAY_END_TIME),
              color,
            },
            // 2nd part of the event -- beginning of the week
            {
              id: entity.id,
              name,
              start: createDateTimeString(this.getFirstDate(), DAY_START_TIME),
              end,
              color,
            },
          ]
        : [
            {
              id: entity.id,
              name,
              start: createDateTimeString(dateStart, timeStart),
              end: createDateTimeString(dateEnd, timeEnd),
              color,
            },
          ]
    })
  })

  setRequiredFields(attributes: string[]) {
    this.attributes = [...new Set([...attributes, 'id'])]
    this.isReadyToFetch = true
  }

  setup(): void {
    // Not ready to fetch until we get the required fields
    this.isReadyToFetch = false

    this.validateConfiguration()
  }

  protected get effectiveQuery(): CollectionQuery {
    // try to refactor this method to not duplicate code from parent method
    // https://tracktik.atlassian.net/browse/FE-1645
    this.queryManager.setFieldsAndExtensionsFromAttributes(this.getAttributes())

    const weekCalendarFields: Field[] = [
      {
        attribute: this.getWeekdayStartAttribute(),
        alias: '_dayStart',
      },
      {
        attribute: this.getTimeStartAttribute(),
        alias: '_timeStart',
      },
      {
        attribute: this.getTimeEndAttribute(),
        alias: '_timeEnd',
      },
      {
        attribute: this.getColorAttribute(),
        alias: '_colorGroup',
      },
      {
        attribute: this.widget.attributeMap.titleAttribute,
        alias: '_name',
      },
    ] satisfies WeekCalendarField[]

    const fields: Field[] = [
      ...this.queryManager.query.fields,
      ...weekCalendarFields,
    ].filter((field) => !!field.attribute)

    const returnCount =
      this.state.widget.toolbar?.displayCounts === false ? false : undefined

    return {
      ...this.queryManager.query,
      returnCount,
      fields,
      limit: TTC_API_MAX_LIMIT,
    }
  }

  // @TODO: move to CollectionWidgetHook ?
  protected parseEntity(item: Record<string, any>): Record<string, any> {
    /**
     * The API provides the values of Date attributes with a DateTime format
     * the moment: API-1715.
     * We must strip their time and timezone information out to prevent
     * timezone issues when the SyncFusion Schedule component parses the date
     * so it can alocate each event in their appropriate date slots.
     */
    return parseResourceDates(
      this.services.resourceMetaManager,
      this.resource,
      item,
    )
  }

  private getWeekdayStartAttribute(): string {
    return this.widget.attributeMap.weekdayStartAttribute
  }

  private getTimeStartAttribute(): string {
    return this.widget.attributeMap.timeStartAttribute
  }

  private getTimeEndAttribute(): string {
    return this.widget.attributeMap.timeEndAttribute
  }

  private getColorAttribute(): string | null {
    return this.widget.eventSettings?.colorByAttribute
  }

  /**
   * The starting `DayID` of the week, based on the first day of the week set.
   *
   * eg: if the first day of the week is set to 'WEDNESDAY', the first `DayID` of the week is 3.
   */
  private getFirtDayID: () => DayID = computedGetter(
    () => this.getOrderedDayIDs()[0],
  )

  private getDaysToDatesMap: () => DayToDate = computedGetter(() => {
    const firstDateMoment = moment(DATE_REFERENCE, DATE_FORMAT, true).day(
      this.getFirtDayID(),
    )

    // @ts-ignore
    const weekdaysToDatesMap: DayToDate = {}

    for (let i = 0; i < 7; i++) {
      const dayID = ((this.getFirtDayID() + i) % 7) as DayID
      const dayName = convertDayIdToDayName(dayID)

      weekdaysToDatesMap[dayName] = firstDateMoment
        .clone()
        .add(i, 'day')
        .format(DATE_FORMAT)
    }

    return weekdaysToDatesMap
  })

  private validateConfiguration(): void {
    if (IS_DEV) {
      const isTimeAttribute = (attr: string) => {
        const attributeMeta = this.services.resourceMetaManager.getAttribute(
          this.resource,
          attr,
        )

        return attributeMeta?.type === FieldTypes.Time
      }

      if (!isTimeAttribute(this.getTimeStartAttribute()))
        console.warn(
          'The start attribute is not of type "Time"',
          this.getTimeStartAttribute(),
        )

      if (!isTimeAttribute(this.getTimeEndAttribute()))
        console.warn(
          'The end attribute is not of type "Time"',
          this.getTimeEndAttribute(),
        )

      // @TODO: ask API to expose a type "day" or "weekday" to validate the weekday attributes
    }
  }
}
