<template>
  <v-card class="pa-4" flat>
    <ReportEditForm v-bind="{ reportId }" @submit:success="$emit('back')" />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import ReportEditForm from '@/tt-widget-views/reports/components/ReportEditForm.vue'

export default Vue.extend({
  name: 'DispatchTasksEditForm',
  components: {
    ReportEditForm,
  },
  props: {
    reportId: {
      type: Number,
      required: true,
    },
  },
})
</script>
