import {
  addPreviewTab,
  registerResourceForm,
  setIconAndColorResourcePreset,
} from '@/tt-widget-entity-flow/helper'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'
import { CollectionQuery } from '@/tt-widget-components'
import EntityRelationListCustomPreviewWrapper from '@/tt-widget-entity-flow/components/EntityRelationListCustomPreviewWrapper.vue'

import AssetsForm from './AssetsForm.vue'
import AssetsCheckOut from './AssetsCheckOut.vue'
import AssetsCheckIn from './AssetsCheckIn.vue'
import AssetsArchive from './AssetsArchive.vue'
import AssetsReservation from './AssetsReservation.vue'
import AssetsCheckOutCard from './AssetsCheckOutCard.vue'
import AssetsMetaCardCustomFields from './AssetsMetaCardCustomFields.vue'
import AddAssets from './AddAssets.vue'
import AssetsAndSitesTile from './AssetsAndSitesTile.vue'
import AssetsAssociateFileForm from './AssetsAssociateFileForm.vue'
import AssetsStatusAttr from './AssetsStatusAttr.vue'
import AssetsTranslationsTile from '@/tt-entity-design/src/components/assets/AssetsTranslationsTile.vue'
import TagInputViaRelationLists from './TagInputViaRelationLists.vue'

import { Resources } from '../../types'
import { AssetActions } from './types'
import AssetsTileWithActions from './AssetsTileWithActions.vue'

export default {
  install(Vue) {
    Vue.component('AssetsCheckOut', AssetsCheckOut)
    Vue.component('AssetsCheckIn', AssetsCheckIn)
    Vue.component('AssetsArchive', AssetsArchive)
    Vue.component('AssetsReservation', AssetsReservation)
    Vue.component('AssetsForm', AssetsForm)
    Vue.component('AssetsCheckOutCard', AssetsCheckOutCard)
    Vue.component('AssetsMetaCardCustomFields', AssetsMetaCardCustomFields)
    Vue.component(
      'EntityRelationListCustomPreviewWrapper',
      EntityRelationListCustomPreviewWrapper,
    )
    Vue.component('AssetsAssociateFileForm', AssetsAssociateFileForm)
    Vue.component('AddAssets', AddAssets)
    Vue.component('TagInputViaRelationLists', TagInputViaRelationLists)
    Vue.component('AssetsAndSitesTile', AssetsAndSitesTile)
    Vue.component('AssetsTileWithActions', AssetsTileWithActions)
    Vue.component('AssetsStatusAttr', AssetsStatusAttr)
    Vue.component('AssetsTranslationsTile', AssetsTranslationsTile)
    registerResourceForm('assets', 'AssetsCheckOut', AssetActions.CHECKOUT)
    registerResourceForm(
      'assets',
      'AssetsCheckOut',
      AssetActions.CHECK_OUT_SUBASSET,
    )
    registerResourceForm('assets', 'AssetsCheckIn', AssetActions.CHECKIN)
    registerResourceForm(
      'assets',
      'AssetsCheckIn',
      AssetActions.CHECK_IN_SUBASSET,
    )
    registerResourceForm('assets', 'AssetsArchive', AssetActions.ARCHIVE)
    registerResourceForm('assets', 'AssetsReservation', AssetActions.RESERVE)
    registerResourceForm(Resources.ASSETS, 'AssetsForm')
    registerResourceForm(
      Resources.ASSETS,
      'AssetsAssociateFileForm',
      AssetActions.ASSOCIATE_FILE,
    )

    registerResourceForm('assets', 'AddAssets', AssetActions.ADD_ASSETS)

    modularManager.addItem('assets.preview.details.top', {
      is: 'AssetsCheckOutCard',
    })

    modularManager.registerDynamicResourceJsonSchemaActions(Resources.ASSETS, [
      AssetActions.CHECKIN,
      AssetActions.CHECK_IN_SUBASSET,
      AssetActions.CHECKOUT,
      AssetActions.CHECK_OUT_SUBASSET,
    ])

    // Set the color
    setIconAndColorResourcePreset(Resources.ASSETS, 'mdi-barcode', 'purple')

    // Adding extra detail cards
    const customFieldsDetails: PresetItem = {
      title: 'Custom Fields',
      data: { is: 'AssetsMetaCardCustomFields', opened: true },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(
      Resources.ASSETS,
      PresetTypes.DETAILS_CARD,
      [customFieldsDetails],
    )

    // Add transactions
    addPreviewTab(Resources.ASSETS, {
      title: 'Logs',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'asset.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSET_TRANSACTION_ITEMS,
            sort: {
              attribute: 'id',
              direction: 'DESC',
            },
            contextFilters: {
              accountAttribute: false,
            },
            include: ['customAttributesDisplay'],
          } as CollectionQuery,
        },
      },
    })

    addPreviewTab(Resources.ASSETS, {
      title: 'Assets',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'bundle.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSETS,
            sort: {
              attribute: 'id',
              direction: 'DESC',
            },
            contextFilters: {
              accountAttribute: false,
            },
          },
          listItem: { is: 'AssetsTileWithActions' },
          disableResourcePreviewOnClick: true,
        },
      },
    })

    addPreviewTab(Resources.ASSETS, {
      title: 'Reservations',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'asset.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSET_RESERVATIONS,
          },
        },
      },
    })

    addPreviewTab(Resources.ASSETS, {
      title: 'Files',
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: 'files',
        showToolbar: false,
      },
    })
  },
}
