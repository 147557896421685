<template>
  <v-container class="pa-0 mobile-devices-map-container">
    <MapMarkerList
      v-if="mapManager"
      :map-manager="mapManager"
      :markers="formattedDestinationMarkers"
      icon="mdi-map-marker"
      color="red"
    >
      <template #default="{ markerManager, marker, icon, color }">
        <TMapMarker
          v-bind="{
            markerManager,
            marker,
            icon,
            color,
          }"
        />
      </template>
    </MapMarkerList>

    <MapMarkerList
      v-if="mapManager"
      :map-manager="mapManager"
      :markers="formattedMobileDevicesMarkers"
      icon="mdi-shield-account"
      color="ttPrimary"
    >
      <template #default="{ marker, icon, color }">
        <TMapMarker
          v-bind="{
            marker,
            icon,
            color,
          }"
        >
          <template #menu>
            <ProvideItemHook :provide="marker.itemHook">
              <MobileDevicesTileForDispatchAssign
                :on-select="onSelect"
                is-tile-map
                style="max-width: 395px"
              />
            </ProvideItemHook>
          </template>
        </TMapMarker>
      </template>
    </MapMarkerList>

    <MapBaseView
      class="mobile-devices-map"
      :params="baseMapParams"
      @loaded="saveMapManager($event)"
    />
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { DistanceMatrixProvider } from './types'
import { VueConstructor } from 'vue/types/umd'

import {
  MapBaseView,
  MapManager,
  MapMarkerList,
  Polygon,
} from '@tracktik/tt-maps'
import TMapMarker from '@/tt-ui/components/TMapMarker.vue'
import MobileDevicesTileForDispatchAssign from './MobileDevicesTileForDispatchAssign.vue'
import ListWidgetHook from '@/tt-widget-components/widgets/List/ListWidgetHook'
import { Marker, MapManagerParams, Position } from '@tracktik/tt-maps'
import '@tracktik/tt-maps/style'
import { OnSelectCallback } from '../types'
import { EntityRecord, ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { Resources } from '@/tt-entity-design/src/types'

interface MakerWithItemHook extends Marker {
  itemHook: EntityItemHook
}

const DEFAULT_ID = 1

export default (
  Vue as VueConstructor<Vue & DistanceMatrixProvider & ItemHookProvider>
).extend({
  name: 'MobileDevicesMap',
  components: {
    MapBaseView,
    MapMarkerList,
    MobileDevicesTileForDispatchAssign,
    TMapMarker,
  },
  inject: ['destinationCoordinates'],
  props: {
    hook: {
      type: Object as PropType<ListWidgetHook>,
      required: true,
    },
    onSelect: {
      type: Function as PropType<OnSelectCallback>,
      required: true,
    },
  },
  data() {
    return {
      mapManager: null as MapManager | null,
    }
  },
  computed: {
    formattedDestinationMarkers(): Marker[] {
      const position = this.destinationCoordinates.split(',')

      return [
        {
          id: DEFAULT_ID,
          position: [parseFloat(position[1]), parseFloat(position[0])],
        },
      ]
    },
    formattedMobileDevicesMarkers(): MakerWithItemHook[] {
      const markers = this.hook?.entities.map((entity) => ({
        id: entity.id,
        position: [entity.lastLongitude, entity.lastLatitude] as Position,
        itemHook: this.createItemHook(entity),
      })) as MakerWithItemHook[]

      return markers
    },
    baseMapParams(): MapManagerParams {
      return {
        development: false,
      }
    },
  },

  watch: {
    formattedMobileDevicesMarkers() {
      this.zoomToAllMarkers()
    },
  },

  methods: {
    saveMapManager(mapManager: MapManager) {
      this.mapManager = mapManager
      this.zoomToAllMarkers()
    },
    zoomToAllMarkers() {
      if (this.mapManager) {
        const polygon: Polygon = {
          type: 'Polygon',
          coordinates: [
            [this.formattedDestinationMarkers[0].position],
            this.formattedMobileDevicesMarkers.map((marker) => marker.position),
          ],
        }
        this.mapManager.zoomTo(polygon)
      }
    },
    createItemHook(entity: EntityRecord): EntityItemHook {
      return new EntityItemHook(this.$appContext, {
        entity,
        resourceName: Resources.MOBILE_DEVICES,
        entityId: entity.id,
      })
    },
  },
})
</script>

<style scoped>
.mobile-devices-map-container {
  display: flex;
  flex-direction: column;
  height: 750px !important;
  width: 100% !important;
}
.mobile-devices-map {
  height: 100%;
  width: 100%;
}
</style>
