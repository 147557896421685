<template>
  <v-app id="injected-backoffice-reports-view">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <BoundingBox v-slot="{ height }">
          <app-window :closable="false" :style="`height: ${height}px`">
            <div v-if="noVisibleTabs">
              <v-alert
                dense
                text
                color="error"
                icon="mdi-alert"
                class="d-flex justify-center align-center"
                height="200"
              >
                {{ $t('back-office-reports.missing-permissions') }}
              </v-alert>
            </div>
            <div v-else class="d-flex flex-row fill-height">
              <BackOfficeReportsLeftNavigationMenu
                :menu-tabs="menuTabs"
                :sub-menu-tabs="subMenuTabs"
                :active-tab="activeTab"
                :visible-tabs="visibleTabs"
                @menu-click="setActiveTab"
              />
              <ConfigurableTab :active-tab="activeTab" />
            </div>
          </app-window>
          <AppLayoutUtils :layout-configuration="layoutConfiguration" />
        </BoundingBox>
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { LayoutConfiguration } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import BoundingBox from '@/tt-widget-views/components/BoundingBox.vue'

import ConfigurableTab from '../components/ConfigurableTab.vue'
import BackOfficeReportsLeftNavigationMenu from '../components/BackOfficeReportsLeftNavigationMenu.vue'

import {
  BackOfficeReportsViews,
  BackOfficeReportsMenus,
  BackOfficeReportsSubMenus,
} from '../types'

/**
 * @TODO:Update Nomenclature to Reporting & Analytics (BOSS-4284)
 */
export default Vue.extend({
  name: 'BackOfficeReportsView',
  components: {
    ProvideLayoutManager,
    BoundingBox,
    ConfigurableTab,
    BackOfficeReportsLeftNavigationMenu,
  },
  props: {
    menuTabs: {
      type: Array as PropType<BackOfficeReportsMenus[]>,
      required: true,
    },
    subMenuTabs: {
      type: Array as PropType<BackOfficeReportsSubMenus[]>,
      required: true,
    },
    initialTab: {
      type: String as PropType<BackOfficeReportsViews>,
      required: true,
    },
    visibleTabs: {
      type: Array as PropType<BackOfficeReportsViews[]>,
      default: () => [],
    },
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
  },
  data() {
    return {
      manager: new LayoutManager(
        this.$eventManager,
        this.layoutConfiguration,
      ) as LayoutManager,
      activeTab: '' as BackOfficeReportsViews,
    }
  },
  computed: {
    noVisibleTabs(): boolean {
      return (
        this.initialTab === BackOfficeReportsViews.EMPTY ||
        this.visibleTabs.length === 0
      )
    },
  },
  mounted() {
    this.activeTab = this.initialTab
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
  methods: {
    setActiveTab(tab: BackOfficeReportsViews) {
      this.activeTab = tab
    },
  },
})
</script>
