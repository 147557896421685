import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import Vue from 'vue'

import TracktikApiError from 'tracktik-sdk/lib/errors'
import {
  CancelableFunction,
  EntityCollectionResponse,
} from 'tracktik-sdk/lib/common/entity-collection'

import ResourcePermissionAuditor from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import {
  BaseWidgetHook,
  CollectionQueryOptions,
  ResourceAndContextAttributeMap,
  WidgetHookDependencies,
  WidgetState,
} from '@/tt-widget-factory'
import { DevConsole } from '@/plugins/DevConsole'
import { EntityRecord } from '@/tt-widget-entity-flow/types'
import { EntityToolbarManager } from '@/tt-widget-entity-flow/EntityToolbarManager'
import { EventDispatcher } from '@/tt-event-manager'
import { EventLogByResource } from '@/tt-app-extensions/pusher/types'
import {
  getEntitiesActions,
  getEntitiesIds,
} from '@/tt-widget-entity-flow/intents/helpers'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { PusherEvents } from '@/tt-app-extensions/pusher/events'

import CollectionQueryManager from '../base/QueryManager'
import {
  CollectionQuery,
  FetchOptions,
  Sort,
  SortDirection,
  WidgetHookEvents,
  WidgetModels,
} from '../types'
import { ContextAttributeMap } from './contextAttributeMap'
import { computedGetter } from '@tracktik/tt-helpers/lib/functions/computedGetter'
import { TTC_API_MAX_LIMIT } from '@/tt-widget-components/constants'
import isObject from 'lodash/isObject'

type FetchingState = {
  promise: Promise<void> | null
  cancel: CancelableFunction | null
}

const CANCELLED_PROMISE = 'CANCELLED_PROMISE'

export type CollectionWidgetState = {
  /**
   * Set of entities fetched
   */
  entities: any[]

  /**
   * Total number of entities for this configuraiton on the API
   */
  totalEntities: number

  /**
   * Map of IDs -> list of actions
   */
  actions: Record<number, string[]>

  /**
   * After fetching a new batch of entities,
   * if this flag is `true`, the hook will fetch the available actions for each IDs
   */
  loadEntitiesActions: boolean

  /**
   * Returns `true` once the first request is fulfilled
   */
  hasFetched: boolean

  /**
   * Promise that resolves when the fetching is done. This is helpful to not fetch multiple times.
   * The promise is reset to `null` when the fetching is done.
   * The request can also be cancelled.
   */
  fetchingData: FetchingState

  /**
   * If the widget is currently fetching data
   */
  loading: boolean

  /**
   * Query data from the last successfully executed request
   */
  lastQuery?: CollectionQuery
}

/**
 * Collection widget
 */
export default abstract class CollectionWidgetHook<
  WidgetModel extends WidgetModels,
> extends BaseWidgetHook<WidgetModel> {
  isReadyToFetch = true
  queryManager: CollectionQueryManager
  toolbarManager: EntityToolbarManager<CollectionQuery>
  state: WidgetState & CollectionWidgetState

  protected attributes: string[] = []

  constructor(deps: WidgetHookDependencies) {
    super(deps)

    const collectionState: CollectionWidgetState = {
      entities: [],
      totalEntities: 0,
      actions: {},
      loadEntitiesActions: false,
      hasFetched: false,
      loading: true,
      fetchingData: {
        promise: null,
        cancel: null,
      },
    }

    this.state = Vue.observable({
      ...collectionState,
      ...this.state,
    })

    if (deps.widget.allowLiveUpdate) {
      this.on(PusherEvents.UPDATED_ENTITIES, (events: EventLogByResource) => {
        this.handlePusherEvents(events, 'UPDATE')
      })

      this.on(PusherEvents.CREATED_ENTITIES, (events: EventLogByResource) => {
        this.handlePusherEvents(events, 'CREATE')
      })
    }

    // Cancel any current request when the hook is destroyed
    this.unsubscribeFunctions.push(() => this.cancelCurrentDataFetching())
  }

  get loading() {
    return this.state.loading
  }

  set loading(value: boolean) {
    this.state.loading = value
  }

  private handlePusherEvents(
    events: EventLogByResource,
    type: 'CREATE' | 'UPDATE',
  ) {
    const resourceEvents = events[this.resource]
    if (!resourceEvents?.length) return

    DevConsole.log(
      `${type} events received from Pusher for resource '${this.resource}'`,
      resourceEvents,
    )

    this.update({ disableCache: true })
  }

  /**
   * List of entities actions if required
   */
  get actions(): Record<string, string[]> {
    return this.state.actions
  }

  /**
   * Get the effective query
   */
  protected get effectiveQuery(): CollectionQuery {
    this.queryManager.setFieldsAndExtensionsFromAttributes(this.getAttributes())

    /**
     * If we do not show the count, we do skip the API calculation of the total entities for performance reasons.
     */
    const returnCount =
      this.state.widget.toolbar?.displayCounts === false ? false : undefined

    return { ...this.queryManager.query, returnCount }
  }

  /**
   * List of entities that include the itemHook
   */
  get entities() {
    return this.state.entities
  }

  get hasDataSource(): boolean {
    return !isEmpty(this.entities)
  }

  get hasErrors(): boolean {
    return this.errors.length > 0
  }

  get loadEntitiesActions(): boolean {
    return this.state.loadEntitiesActions ?? false
  }

  set loadEntitiesActions(value: boolean) {
    this.state.loadEntitiesActions = value
  }

  get hasFetched(): boolean {
    return this.state.hasFetched
  }

  /**
   * Resource coming from the query
   */
  get resource(): string {
    return this.state.widget.query.resource
  }

  /**
   * Toolbar is showed by default
   */
  get showToolbar(): boolean {
    return this.state.widget?.toolbar?.show !== false
  }

  get areActionsAllowed(): boolean {
    return !!this.state.widget?.allowActions
  }

  get totalEntities(): number {
    return this.state.totalEntities
  }

  getAttributes(): string[] {
    return [...this.attributes]
  }

  /**
   * Return a context attribute map
   * It allows to change the context filters attributes
   */
  getContextAttributeMap(): ContextAttributeMap {
    return this.state.widget.query.contextFilters ?? {}
  }

  /**
   * Function to setup
   */
  abstract setup()

  /**
   * Save the hook fetching promise in the state.
   * Useful to know if a request is currently in progress.
   */
  private saveCurrentFetchingPromise({ promise, cancel }: FetchingState): void {
    DevConsole.log('Saving CollectionWidget promise...')
    this.state.fetchingData = { promise, cancel }
  }

  /**
   * If the hook was currently fetching, cancel the promise.
   */
  async cancelCurrentDataFetching(): Promise<void> {
    if (this.state.fetchingData.cancel) {
      DevConsole.log('Cancelling CollectionWidget promise...')
      this.state.fetchingData.cancel(CANCELLED_PROMISE)
      // making sure the promise is cancelled before continuing
      await this.state.fetchingData.promise
    }

    this.clearCurrentDataFetching()
  }

  /**
   * Clear the fetching promise
   */
  private clearCurrentDataFetching(): void {
    this.state.fetchingData = { promise: null, cancel: null }
  }

  /**
   * Update the data query
   */
  async update(fetchOptions?: FetchOptions): Promise<void> {
    if (!this.isReadyToFetch) return

    await this.cancelCurrentDataFetching()

    const { run, cancel } = this.prepareFetching(fetchOptions)

    const promise = run().catch((err) => DevConsole.warn(err))

    this.saveCurrentFetchingPromise({ promise, cancel })

    await promise

    this.clearCurrentDataFetching()
  }

  protected fetchData(fetchOptions?: FetchOptions): Promise<void> {
    this.queryManager.setOffset(0)

    return this.update(fetchOptions)
  }

  /**
   * Validate that the user has access to all resources
   */
  hasPermission(): boolean {
    const { authModule, resourceMetaManager } = this.services
    const resourceAndContext = this.getResourcesAndContext()

    return ResourcePermissionAuditor.canViewAllResources(
      { authModule, resourceMetaManager },
      resourceAndContext.map((item) => item.resource),
    )
  }

  /**
   * Resources
   */
  getResourcesAndContext(): ResourceAndContextAttributeMap[] {
    const resourcesAndContext = []
    const resource = this.state.widget?.query?.resource ?? null
    if (!resource) {
      return []
    }

    const resourceModel =
      this.services.resourceMetaManager.getResource(resource)
    resourcesAndContext.push({
      uid: this.state.widget.uid,
      resource,
      contextAttributeMap: {
        ...resourceModel.modelContext,
        ...(this.state.widget?.query?.contextFilters ?? {}),
      },
    })

    return resourcesAndContext
  }

  async initialize(): Promise<void> {
    // Only initialize when valid
    if (!this.isValid) {
      return
    }

    // Set the query Manager
    const { resourceDataManager, resourceMetaManager } = this.services
    this.queryManager = new CollectionQueryManager(
      this.state.widget.query,
      this.services.contextManager,
      {
        contextAttributeMap: this.getContextAttributeMap(),
        defaultFilterValues: this.state.widget.toolbar?.filterOptions?.filters,
        customFilterValues:
          this.state.widget.toolbar?.filterOptions?.customFilters?.filter(
            (filter) => isObject(filter),
          ),
        services: { resourceDataManager, resourceMetaManager },
      },
    )

    this.setup()

    this.toolbarManager = new EntityToolbarManager(
      this.state.widget?.toolbar,
      this.queryManager,
    )

    super.initialize()
    this.setInitiated()
    await this.update()
  }

  getEntity(id: string | number): unknown {
    return this.getEntitiesDictionary()[id]
  }

  /**
   * Dictionary of the entities by ID
   */
  private getEntitiesDictionary: () => Record<string, unknown> = computedGetter(
    () =>
      this.entities.reduce((acc, entity) => {
        acc[entity.id] = entity

        return acc
      }, {}),
  )

  /**
   * Reset the pagination if any filtering or sorting params changed
   * Disregard the sorting differences of `filters` and `scopes` when comparing
   */
  private resetPaginationOnQueryChange(): void {
    if (!this.state.lastQuery || !this.queryManager.query.offset) return

    const before = this.state.lastQuery
    const after = this.effectiveQuery

    const orderedIsEqual = (
      itemA: unknown[] | null | undefined,
      itemB: unknown[] | null | undefined,
      criteria?: string[],
    ): boolean => {
      const sortedA = orderBy(itemA ?? [], criteria)
      const sortedB = orderBy(itemB ?? [], criteria)

      return isEqual(sortedA, sortedB)
    }

    const filtersIsEqual = () =>
      orderedIsEqual(before.filters, after.filters, [
        'attribute',
        'operator',
        'value',
      ])

    const scopeIsEqual = () => orderedIsEqual(before.scope, after.scope)

    const restIsEqual = () => {
      const attributes = [
        'includeInactive',
        'limit',
        'search',
        'sort',
        'whereQL',
      ]
      const pickedBefore = pick(before, attributes)
      const pickedAfter = pick(after, attributes)

      return isEqual(pickedBefore, pickedAfter)
    }

    if (!restIsEqual() || !scopeIsEqual() || !filtersIsEqual()) {
      this.queryManager.setOffset(0)
    }
  }

  private prepareFetching(fetchOptions?: FetchOptions): {
    run: () => Promise<void>
    cancel: CancelableFunction
  } {
    this.resetPaginationOnQueryChange()

    const queryOptions: CollectionQueryOptions = {
      disableCache: fetchOptions?.disableCache,
      keepRelationList: this.state.widget.keepRelationList,
    }

    const query = { ...this.effectiveQuery }

    const { cancel, run: fetchCollection } =
      this.services.resourceDataManager.cancelableGetCollection(
        query,
        queryOptions,
      )

    const runFetchData = async () => {
      this.loading = true
      const errors = []

      const { items, total } = await fetchCollection()
        .catch((err: TracktikApiError) => {
          if (err.message === CANCELLED_PROMISE) throw err
          errors.push(err)

          return { total: 0, limit: 0, offset: 0, itemCount: 0, items: [] }
        })
        .finally(() => (this.loading = false))

      this.services.eventManager.dispatchEvent(
        WidgetHookEvents.COLLECTION_STATE_CHANGED,
        { items, total },
      )

      if (this.loadEntitiesActions && this.areActionsAllowed) {
        const ids = getEntitiesIds(items)
        this.state.actions = await this.fetchEntitiesActions(ids)
      } else {
        this.state.actions = {}
      }

      this.errors = errors
      this.state.totalEntities = total
      this.state.entities = items.map((item) => this.parseEntity(item))
      this.state.hasFetched = true
      this.state.lastQuery = query
    }

    return {
      run: () => runFetchData(),
      cancel,
    }
  }

  private async fetchEntitiesActions(
    ids: number[],
  ): Promise<Record<string, string[]>> {
    if (isEmpty(ids)) return {}

    const api = this.services.authModule.getApi()
    const resource = this.effectiveQuery.resource
    const entitiesActions = await getEntitiesActions(api, resource, ids)

    const formattedObject = (acc, entity) => {
      const { id, actions } = entity
      acc[id] = Object.keys(actions)

      return acc
    }

    return entitiesActions.reduce(formattedObject, {})
  }

  /**
   * Refresh a given list of entity IDs
   */
  private async refreshEntities(ids: (number | string)[]): Promise<void> {
    const filters = this.effectiveQuery.filters
      ? [...this.effectiveQuery.filters]
      : []

    filters.push({
      attribute: 'id',
      operator: 'IN',
      value: ids,
    })

    const query: CollectionQuery = {
      ...this.effectiveQuery,
      filters,
      offset: 0,
      limit: ids.length,
    }

    const errors = []

    const { items, total } = await this.services.resourceDataManager
      .getCollection(query, { disableCache: true })
      .catch((err: TracktikApiError) => {
        console.warn('Error while refetching entities', this.resource, ids, err)
        errors.push(err)

        return { total: 0, limit: 0, offset: 0, itemCount: 0, items: [] }
      })

    const newEntities = items.map((item) => this.parseEntity(item))

    const updateEntity = (currentEntity) =>
      newEntities.find((newEntity) => newEntity.id === currentEntity.id) ||
      currentEntity

    this.state.entities = this.state.entities.map(updateEntity)
  }

  protected parseEntity(item: Record<string, any>): Record<string, any> {
    return item
  }

  private getSort(): Sort[] {
    const sort = this.queryManager.initialQuery.sort

    return Array.isArray(sort) ? sort : [sort].filter(Boolean)
  }

  private getSortDirection(attribute: string): SortDirection | null {
    return (
      this.getSort().find((sort) => sort.attribute === attribute)?.direction ??
      null
    )
  }

  isSorted(attribute: string): boolean {
    return this.getSort().some((sort) => sort.attribute === attribute)
  }

  isSortedAsc(attribute: string): boolean {
    return this.getSortDirection(attribute) === 'ASC'
  }

  isSortedDesc(attribute: string): boolean {
    return this.getSortDirection(attribute) === 'DESC'
  }

  nextSort(attribute: string): void {
    const currentSort = this.getSort()

    const currDirection = this.getSortDirection(attribute) ?? null

    if (currDirection === null) {
      this.queryManager.setSort([
        {
          attribute,
          direction: 'ASC',
        },
      ])
    } else if (currDirection === 'ASC') {
      this.queryManager.setSort([
        {
          attribute,
          direction: 'DESC',
        },
      ])
    } else {
      const newSort = currentSort.filter((sort) => sort.attribute !== attribute)
      this.queryManager.setSort(newSort)
    }

    this.update()
  }

  /**
   * Returns an object that can be used to export the data to CSV.
   */
  exportCSV(): {
    cancel: CancelableFunction
    run: () => Promise<void>
  } {
    const collectionQuery: CollectionQuery = this.queryManager.getCsvQuery()

    return this.createCsvExporter(collectionQuery)
  }

  /**
   * Returns boolean if over the limit of data returned from API.
   */
  isOverAPILimit(): boolean {
    return this.state.totalEntities > TTC_API_MAX_LIMIT
  }
}

export const onFetchCollectionError =
  (eventManager: { dispatchEvent: EventDispatcher }) =>
  ({ message }): EntityCollectionResponse<EntityRecord> => {
    eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
      message,
      dismissTime: 600,
    })

    return { total: 0, limit: 0, offset: 0, itemCount: 0, items: [] }
  }
