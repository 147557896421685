import { PluginFunction } from 'vue'
const AssignedSiteVendorsActionsColumn = () =>
  import('./components/AssignedSiteVendorsActionsColumn.vue')

const ClientVendorsView = () => import('./views/ClientVendorsView.vue')

export { ClientVendorsView }

export const SiteVendorsPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('ClientVendorsView', ClientVendorsView)
  Vue.component(
    'AssignedSiteVendorsActionsColumn',
    AssignedSiteVendorsActionsColumn,
  )
}
