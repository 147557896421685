import { Action } from './../resource-action/types'

/**
 * This blacklist is used to store actions that we need to manually hide on the FE, while they still exist on the schema coming from the BE
 * The use case which made us resort to this blacklist is that there are actions that are on the schema and are intended to show for the mobile app.
 * but not supposed to show up for the FE. It turned out to be too complex for the BE to build a dynamic schema that shows actions for mobile and hides them for FE.
 */
export enum ResourceActionBlacklist {
  WRITE_BARCODE_WITH_DEVICE = 'write-barcode-with-device',
  WRITE_NFC_WITH_DEVICE = 'write-nfc-with-device',
  FILE_REPORT = 'file-report',
  REMOVE_ASSETS = 'remove-assets',
  DELETE_ASSET_TYPE_TRANSLATION = 'delete-asset-type-translation',
  DELETE_ASSET_CATEGORY_TRANSLATION = 'delete-asset-category-translation',
}

export function isResourceActionBlacklisted(resourceAction: Action): boolean {
  return Object.values(ResourceActionBlacklist).includes(
    // @ts-ignore -- Argument of type 'string' is not assignable to parameter of type 'ResourceActionBlacklist'
    resourceAction.actionName,
  )
}
