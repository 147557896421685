// Enter your custom installation here

import {
  addPreviewTab,
  registerResourceForm,
  setIconAndColorResourcePreset,
} from '@/tt-widget-entity-flow/helper'
import AssetTypesForm from '@/tt-entity-design/src/components/asset-types/AssetTypesForm.vue'
import AssetTypesAssociateReportForm from '@/tt-entity-design/src/components/asset-types/AssetTypesAssociateReportForm.vue'
import AssetTypesAssignCustomAttributeForm from '@/tt-entity-design/src/components/asset-types/AssetTypesAssignCustomAttributeForm.vue'
import AssetTypesIcon from '@/tt-entity-design/src/components/asset-types/AssetTypesIcon.vue'
import AssetTypesAssociateFileForm from './AssetTypesAssociateFileForm.vue'
import { Resources } from '../../types'
import EntityRelationListCustomPreviewWrapper from '@/tt-widget-entity-flow/components/EntityRelationListCustomPreviewWrapper.vue'
import { isRegion } from '@/apps/app.tracktik.assets/helpers'
import { AssetsTranslationsActions } from '../assets/types'

export default {
  install(Vue) {
    Vue.component('AssetTypesForm', AssetTypesForm)
    Vue.component('AssetTypesIcon', AssetTypesIcon)
    Vue.component('AssetTypesAssociateFileForm', AssetTypesAssociateFileForm)
    Vue.component(
      'AssetTypesAssociateReportForm',
      AssetTypesAssociateReportForm,
    )
    Vue.component(
      'AssetTypesAssignCustomAttributeForm',
      AssetTypesAssignCustomAttributeForm,
    )
    registerResourceForm(Resources.ASSET_TYPES, 'AssetTypesForm')
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssociateFileForm',
      'associate-file',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssociateReportForm',
      'assign-report-templates',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssociateReportForm',
      'unassign-report-templates',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssignCustomAttributeForm',
      'assign-custom-attribute',
    )
    registerResourceForm(
      Resources.ASSET_TYPES,
      'AssetTypesAssignCustomAttributeForm',
      'unassign-custom-attribute',
    )

    Vue.component(
      'EntityRelationListCustomPreviewWrapper',
      EntityRelationListCustomPreviewWrapper,
    )

    // Set the color
    setIconAndColorResourcePreset(Resources.ASSET_TYPES, 'mdi-tag', 'orange')

    // Add transactions
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Assets',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'type.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSETS,
          },
        },
      },
      condition: ({ itemHook }) => {
        const selectedAccountIsRegion = isRegion(itemHook.appContext)

        return !selectedAccountIsRegion
      },
    })

    // Add files list tab
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Files',
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: 'files',
        showToolbar: false,
      },
    })

    // Add incident reports tab
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Report Forms',
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: 'reportTemplates',
        showToolbar: false,
      },
      //@TODO: to remove when available in the API and validated by Securitas
      condition: ({ itemHook }) => {
        const selectedAccountIsRegion = isRegion(itemHook.appContext)
        // checks that the attribute exists in the API
        const isAvailableInAPI =
          !!itemHook.appContext.widgetServices.resourceMetaManager.getAttribute(
            Resources.ASSET_TYPES,
            'reportTemplates',
          )

        return isAvailableInAPI && !selectedAccountIsRegion
      },
    })

    // Add custom attributes tab
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Custom Fields',
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: 'customAttributes',
        showToolbar: false,
      },
      condition: ({ itemHook }) => {
        const selectedAccountIsRegion = isRegion(itemHook.appContext)

        return !selectedAccountIsRegion
      },
    })

    // Add translations
    addPreviewTab(Resources.ASSET_TYPES, {
      title: 'Translations',
      is: 'AssetsTranslationsTile',
      props: {
        resource: Resources.ASSET_TYPE_TRANSLATIONS,
        parentResource: Resources.ASSET_TYPES,
        deleteAction: AssetsTranslationsActions.DELETE_ASSET_TYPE_TRANSLATION,
      },
    })
  },
}
