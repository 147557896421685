import {
  ExploreRelationOptions,
  ExtensionAttribute,
  ExtensionDictionary,
  FieldTypes,
  ResourceMetaProviderInterface,
} from './types'
import {
  Dimension,
  DimensionModifier,
  DimensionModifierType,
  Measure,
  MeasuresOperation,
} from 'tracktik-sdk/lib/common/entity-collection'
import {
  Attribute,
  AttributeDictionary,
  Resource,
  ResourceModelContext,
  ResourceScopeDictionary,
  ResourcesMeta,
} from '@/tt-widget-factory/services/resource-meta/types'
import get from 'lodash/get'
import {
  JSONSchema7,
  OpenAPICompatibleSchema,
} from '@tracktik/tt-json-schema-form'
import metadataProvider, { MetaKey } from '../metadata-provider'
import {
  getExtensionLabelKey,
  isExtension,
} from '@/tt-widget-factory/services/resource-meta/Extensions'
import { FormLabelTypes } from '@tracktik/tt-json-schema-form'
import { isEmpty } from '@/helpers/isEmpty'
import { flatten } from 'flat'
import i18n from '@/plugins/i18n'
import { resourceNameCase } from './resource-meta-utils'
import { CollectionQuery } from '@/tt-widget-components'

const dimensionModifiers = Object.keys(DimensionModifierType)

const getSchemaPropertiesPaths = (
  schema: JSONSchema7,
  parentPath?: string,
): string[] => {
  const isObjectSchema = ({ properties, type }: JSONSchema7) =>
    type === 'object' && !!properties

  if (!isObjectSchema(schema)) return parentPath ? [parentPath] : []

  return Object.entries(schema.properties).reduce<string[]>(
    (collector, [attribute, subSchema]) => {
      const currentAttributePath = parentPath
        ? `${parentPath}.${attribute}`
        : attribute

      const getRefSchemaPaths = () => {
        const refPath = subSchema.$ref.split('/').slice(1).join('.')

        return getSchemaPropertiesPaths(
          get(schema, refPath),
          currentAttributePath,
        )
      }

      const attributes = subSchema.$ref
        ? getRefSchemaPaths()
        : isObjectSchema(subSchema)
        ? getSchemaPropertiesPaths(subSchema, currentAttributePath)
        : [currentAttributePath]

      return [...collector, ...attributes]
    },
    [],
  )
}

const getExtensionAttributesEntries = ([name, { attributes }]: [
  string,
  ExtensionAttribute,
]): [string, object] | [string] => {
  if (!attributes) return [name]

  const attributesEntries = Object.entries(attributes).map(
    getExtensionAttributesEntries,
  )

  return [name, Object.fromEntries(attributesEntries)]
}

/**
 * Resource meta provider
 */
export default class ResourceMetaProvider
  implements ResourceMetaProviderInterface
{
  /**
   * List of resource declared in the dictionnary
   */
  readonly resources: ResourcesMeta

  // Get all resources objects from the schema
  getAllResources(): ResourcesMeta {
    return metadataProvider.get(MetaKey.resources)
  }

  getRootResources(): ResourcesMeta {
    type Entry = [string, Resource]
    const resourcesEntries = Object.entries(this.getAllResources())
    const isRootResource = ([_, { isRootResource }]: Entry) => !!isRootResource

    const rootResourcesEntries = resourcesEntries.filter(isRootResource)
    return Object.fromEntries(rootResourcesEntries)
  }

  /**
   * Get the resource
   * @param resourceName
   */
  getResource(resourceName: string): Resource {
    if (!this.getAllResources()[resourceName])
      console.warn(`No ${resourceName} in meta.resources`)
    return this.getAllResources()[resourceName]
  }

  /**
   * Check if a resource exists in the schema
   */
  hasResource(resourceName: string): boolean {
    return Object.keys(this.getAllResources()).includes(resourceName)
  }

  /**
   * Gets resource model context
   * @param resourceName
   * @returns resource model context
   */
  getResourceModelContext(
    resourceName: string,
  ): ResourceModelContext | undefined {
    const resource = this.getResource(resourceName)
    return resource?.modelContext || undefined
  }

  /**
   * Gets resource scopes
   * @param resourceName
   * @returns resource scopes
   */
  getResourceScopes(resourceName: string): ResourceScopeDictionary | undefined {
    const resource = this.getResource(resourceName)
    return resource && resource.scopes
  }

  /**
   * Get the resource names
   */
  getResourceNames(): string[] {
    const { resourceNames } = this.cache

    if (resourceNames && resourceNames.length) {
      return resourceNames
    }

    this.cache.resourceNames = Object.keys(this.getAllResources()).sort()

    if (!this.cache.resourceNames.length)
      console.warn(`Nothing in meta.resources`)

    return this.cache.resourceNames
  }

  getRootResourceNames(): string[] {
    return Object.keys(this.getRootResources())
  }

  /**
   * Return the list of potential measures
   * @param resourceName
   * @param maxDepth
   */
  getResourceMeasures(resourceName: string, maxDepth = 3): Measure[] {
    const attributes = this.getAttributes(resourceName, maxDepth)

    if (!attributes) return []

    return Object.keys(attributes).reduce(
      (collector: Measure[], attributeName: string): Measure[] => {
        const attr = attributes![attributeName]

        if (attr.type == FieldTypes.RelationList) {
          return collector
        }

        // Remove the ID of relations
        const parts = attributeName.split('.')
        if (parts[parts.length - 1] == 'id' && parts.length > 1) {
          return collector
        }
        // No measures
        if (!attr.measure) {
          return collector
        }

        Object.keys(attr.measure).forEach((operation: string) => {
          if (!(<boolean>attr.measure![operation]) || operation === 'class') {
            return
          }
          collector.push({
            attribute: attributeName,
            operation: operation.toUpperCase() as MeasuresOperation,
          })
        })
        return collector
      },
      [] as Measure[],
    )
  }

  /**
   * Return the list of potential measures
   * @param resourceName
   * @param maxDepth
   */
  getResourceDimensions(resourceName: string, maxDepth = 3): Dimension[] {
    const attributes = this.getAttributes(resourceName, maxDepth)
    if (!attributes) {
      return []
    }

    return Object.keys(attributes).reduce(
      (collector: Dimension[], attributeName: string): Dimension[] => {
        const attr = attributes![attributeName]

        if (attr.type === FieldTypes.RelationList) {
          return collector
        }

        // Remove the ID of relations
        const parts = attributeName.split('.')
        if (parts[parts.length - 1] == 'id' && parts.length > 1) {
          return collector
        }
        // No measures
        if (!attr.dimension) {
          return collector
        }

        const dateTypes: FieldTypes[] = [
          FieldTypes.DateTime,
          FieldTypes.Date,
          FieldTypes.TimeStampDate,
          FieldTypes.TimeStampNumber,
        ]

        if (attr.type && dateTypes.includes(attr.type as FieldTypes)) {
          dimensionModifiers.forEach((modifier: DimensionModifier) => {
            collector.push({
              attribute: attributeName,
              modifier,
            })
          })
        } else {
          collector.push({ attribute: attributeName })
        }

        return collector
      },
      [] as Measure[],
    )
  }

  /**
   * Get the list of attributes
   * @param resourceName
   * @param maxDepth
   */
  getAttributes(
    resourceName: string,
    maxDepth = 0,
  ): AttributeDictionary | undefined {
    const resources = this.getResource(resourceName)

    if (!resources) return

    // No relation depth
    if (maxDepth === 0) {
      const out = {}
      Object.keys(resources.attributes).forEach((name: string) => {
        out[name] = {
          name,
          ...resources.attributes[name],
        }
      })
      return out
    }

    return this.collectRelations(resourceName, { maxDepth })
  }

  private cache = {
    resourceNames: [] as string[],
    attributeNamesAndRelations: {} as { [key: string]: string[] },
  }

  /**
   *
   * @param resourceName
   * @param options
   * @param prefix
   * @param depth
   */
  private collectRelations(
    resourceName: string,
    options: ExploreRelationOptions = {},
    prefix = '',
    depth = 0,
  ): { [k: string]: Attribute } {
    const { maxDepth = 2, namesToExclude = ['uri', 'resourceType'] } = options

    // Load the list of relations
    const attributes = this.getAttributes(resourceName)

    if (!attributes || depth > maxDepth) {
      return {}
    }

    // Get the names and remove the ones we don't want
    const attributesName = Object.keys(attributes).filter(
      (attributeName) => !namesToExclude.includes(attributeName),
    )

    const collector = (
      attributeMap: { [k: string]: Attribute },
      attributeName: string,
    ) => {
      const attribute = attributes[attributeName]
      const contextualName = prefix
        ? `${prefix}.${attributeName}`
        : attributeName

      const { relation, type } = attributes[attributeName]

      if (relation && type !== FieldTypes.RelationList) {
        // Add the top relation
        attributeMap[contextualName] = { ...attribute, name: contextualName }
        const related = this.collectRelations(
          relation.resource,
          options,
          contextualName,
          depth + 1,
        )
        return { ...attributeMap, ...related }
      }

      attributeMap[contextualName] = { ...attribute, name: contextualName }

      return attributeMap
    }
    // Reduce
    return attributesName.reduce(collector, {} as { [k: string]: Attribute })
  }

  getUpdatedRelationListAttribute(
    resourceName: string,
    fields: CollectionQuery['fields'],
  ): CollectionQuery['fields'] {
    const updatedFields =
      fields &&
      fields.map((field) => {
        if (isExtension(field.attribute)) {
          return field
        }

        const attribute = this.getAttribute(resourceName, field.attribute)
        if (attribute?.type === FieldTypes.RelationList) {
          return {
            attribute: field.attribute,
            alias: field.attribute,
            modifier: 'count',
          }
        }
        return field
      })

    return updatedFields
  }

  getExtensions(resourceName: string): ExtensionDictionary {
    return this.getResource(resourceName)?.extensions || {}
  }

  getExtensionPaths(resourceName: string, extensionName: string): string[] {
    // internally we create an object with the extension structure, that we flatten to get the paths
    const extension = this.getExtensions(resourceName)?.[extensionName]

    if (!extension) {
      console.warn(
        `Extension '${extensionName}' does not exist on resource '${resourceName}'`,
      )
      return ['']
    }

    const attributesEntries = Object.entries(extension.attributes).map(
      getExtensionAttributesEntries,
    )

    const extensionObject = {
      [extensionName]: Object.fromEntries(attributesEntries),
    }

    return Object.keys(flatten(extensionObject))
  }

  getAllExtensionsPaths(resourceName: string): string[] {
    const resourceExtensions = Object.keys(this.getExtensions(resourceName))

    return resourceExtensions.flatMap((name) =>
      this.getExtensionPaths(resourceName, name),
    )
  }

  /**
   * Get attribute
   * @param resourceName
   * @param attributeName
   */
  getAttribute(
    resourceName: string,
    attributeName: string,
  ): Attribute | undefined {
    if (attributeName.includes('.')) {
      const sections = attributeName.split('.')
      const [relationAttrName, ...subAttrSections] = sections

      const relationResourceName = this.getRelationResourceName(
        resourceName,
        relationAttrName,
      )
      if (!relationResourceName) return

      return this.getAttribute(relationResourceName, subAttrSections.join('.'))
    }

    const attrs = this.getAttributes(resourceName)

    if (!attrs || !attrs.hasOwnProperty(attributeName)) return

    return {
      ...attrs[attributeName],
      name: attributeName,
      resource: resourceName,
    }
  }

  getFormSchema(
    resourceName: string,
    schemaName: string,
  ): OpenAPICompatibleSchema | undefined {
    const schema: JSONSchema7 =
      this.getResource(resourceName)?.actions?.[schemaName]

    if (!schema?.properties) return

    if (!metadataProvider.get(MetaKey.openApiSchema).components)
      console.warn(`No property 'components' in meta.${MetaKey.openApiSchema}`)

    return {
      ...schema,
      components: metadataProvider.get(MetaKey.openApiSchema).components,
    }
  }

  /**
   * Return a form with only one
   *
   * @param resourceName
   * @param attributeName
   */
  getPatchAttributeFormSchema(
    resourceName: string,
    attributeName: string,
  ): OpenAPICompatibleSchema | undefined {
    const schema = this.getFormSchema(resourceName, 'put')

    const attributeSchema = schema?.properties?.[attributeName]

    if (!attributeSchema) {
      console.warn(
        `No PUT schema for attribute ${attributeName} in resource ${resourceName}`,
      )
      return
    }

    const isRequired = schema?.required?.includes(attributeName)

    return {
      ...schema,
      properties: { [attributeName]: attributeSchema },
      required: isRequired ? [attributeName] : [],
    }
  }

  getSchemaAttributes(resourceName: string, schemaName: string) {
    const schema = this.getFormSchema(resourceName, schemaName)

    if (!schema) return

    return getSchemaPropertiesPaths(schema)
  }

  /**
   *
   * @param resource
   * @param attributeName
   * @param type
   * @param enumValue
   */
  getAttributeLabelKey(
    resource: string,
    attributeName: string,
    type: FormLabelTypes | string,
    enumValue?: string,
  ) {
    if (isExtension(attributeName)) {
      return getExtensionLabelKey(resource, attributeName, type)
    }

    // Relation fields, we get the relation
    if (attributeName.includes('.')) {
      const parts = attributeName.split('.')
      attributeName = parts.pop()
      const relationAttribute = parts.join('.')
      const attribute = this.getAttribute(resource, relationAttribute)
      resource = attribute?.relation?.resource
    }

    // Return an enum
    // @todo: refactor to use `createI18nEnumKey()`, cannot import ResourceTranslator because of circular dependencies with this module
    if (!isEmpty(enumValue)) {
      const isValidKey = (key: string) => {
        const label = i18n.t(key)
        return typeof label === 'string' && label !== key
      }
      const deprecatedKey = `res.${resource}.attr.${attributeName}.list.${enumValue}`
      const newKey = `res.${resource}.attr.${attributeName}.list.${enumValue}.label`

      return isValidKey(newKey) ? newKey : deprecatedKey
    }

    return `res.${resource}.attr.${attributeName}.${type}`
  }

  /**
   * Get the relation resource anme
   * @param resource
   * @param attribute
   */
  private getRelationResourceName(resource: string, attribute: string) {
    return this.getAttribute(resource, attribute)?.relation?.resource
  }

  // converts: ('shifts', 'createdBy.region.timeZone')
  // to: ['res.shifts.attr.createdBy.label', 'res.employees.attr.region.label', 'res.region.attr.timeZone.label']
  // Which can be converted with i18n for ie :
  // CreatedBy > Region > TimeZone
  public getAttributePathLabelKeys(resource: string, attrPath: string) {
    const buildNodePath = (acc: string[], curr: string) => {
      const node = acc.slice(-1).concat(curr).join('.')

      return [...acc, node]
    }
    const getLabelKey = (path: string) =>
      this.getAttributeLabelKey(resource, path, FormLabelTypes.LABEL)

    return attrPath.split('.').reduce(buildNodePath, []).map(getLabelKey)
  }

  public getCustomFilters(resource: string) {
    // @todo: to remove after API-812 is fixed
    const isSchemaNotEmpty = ([_, { type, labels, relation, format }]) =>
      ![type, labels, relation, format].every((value) => value === null)

    const customFilters = this.getResource(resource)?.customFilters || {}
    const validEntries = Object.entries(customFilters).filter(isSchemaNotEmpty)
    return Object.fromEntries(validEntries)
  }

  public getCustomFilter(resource: string, customFilterName: string) {
    const customFilterMeta = this.getCustomFilters(resource)[customFilterName]

    if (!customFilterMeta)
      console.warn(
        `No custom filter meta information found (resource: ${resource}, customFilter: ${customFilterName})`,
      )

    return customFilterMeta
  }

  /**
   * Get a resource label
   * @param resourceName
   */
  public getResourceLabel(resourceName: string): string {
    return (
      this.getResource(resourceName)?.labels?.label ||
      resourceNameCase(resourceName)
    )
  }
}
