<template>
  <div>
    <span
      class="subtitle-2 pb-2"
      v-text="$t(translationKey('form-fields.instruction'))"
    />
    <div v-if="taskSiteSettingsItems">
      <v-card v-if="defaultInstructions || jobInstructions" class="pa-4 my-4">
        <div
          class="body-2 pb-2"
          v-text="$t(translationKey('form-fields.general'))"
        />
        <div
          v-if="defaultInstructions"
          class="caption pb-2"
          v-text="defaultInstructions"
        />
        <div
          v-if="jobInstructions"
          class="caption pb-2"
          v-text="jobInstructions"
        />
      </v-card>
    </div>
    <div
      v-else
      class="caption pb-2"
      v-text="$t(translationKey('form-fields.no-instructions'))"
    />

    <json-field name="taskInstructions" />
    <v-row>
      <v-col cols="6"><json-field name="priority" /></v-col>
      <v-col cols="6"><json-field name="customId" /></v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { DispatchTaskFormServiceProvider } from './types'
import { FormHookProvider } from '@/tt-widget-components'
import { TaskSiteSettingsInterface } from '../types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormInstructionsField',
  inject: ['formHook', 'service'],
  computed: {
    jobInstructions(): string {
      return this.service.getJobInstructions()
    },
    defaultInstructions(): string {
      return this.service.getDefaultInstructions()
    },
    taskSiteSettingsItems(): TaskSiteSettingsInterface {
      return this.service.getTaskSiteSettingsItems()
    },
  },
  methods: {
    translationKey(key: string): string {
      return `tt-entity-design.dispatch-tasks.${key}`
    },
  },
})
</script>
