<template>
  <div v-if="!!activeTab" class="fill-height w-100">
    <WidgetContainer
      v-if="metadatasReady && backofficeReportsWidget"
      :key="widgetUid"
      class="fill-height"
    >
      <MetadataLoadingGuard>
        <div class="fill-height">
          <WidgetFactory
            :widget="backofficeReportsWidget"
            :props-data="widgetPropsData"
            @set-has-data="hasData = $event"
            @update:hook="saveHook"
          />
        </div>
      </MetadataLoadingGuard>
    </WidgetContainer>
  </div>
  <div v-else class="fill-height d-flex flex-column w-100">
    <v-skeleton-loader type="text, table-row, table" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import MetadataLoadingGuard from '../../components/MetadataLoadingGuard.vue'
import { DataTableWidgetModel } from '@/tt-widget-components/types'
import DataTableWidgetHook from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import { VueWithLayoutManager } from '@/tt-widget-views/types'
import { BackOfficeReportsTab, BackOfficeReportsViews } from '../types'
import {
  EARNINGS_PREMIUM_TABS,
  EFFECTIVE_DATE_TABS,
  HUMAN_CAPITAL_MANAGEMENT_TABS,
  LEAVE_MANAGEMENT_TABS,
  SCHEDULING_TABS,
} from '../constants'
import { WidgetName } from '@/tt-widget-components/lib/names'

export default (Vue as VueWithLayoutManager).extend({
  name: 'ConfigurableTab',
  components: {
    MetadataLoadingGuard,
  },
  inject: { layoutManager: {}, isInjectedDashboardTab: { default: false } },
  props: {
    activeTab: {
      type: String as PropType<BackOfficeReportsViews>,
      required: true,
    },
  },
  data() {
    return {
      hasData: false,
      hook: null as DataTableWidgetHook,
    }
  },
  computed: {
    tabs(): BackOfficeReportsTab[] {
      /**
       * EFFECTIVE DATE TABS
       * - Positions Pay Rate
       * - Positions Bill Rate
       * - Employee Profile Pay Rate
       * - Site Specific Employee Pay Rate
       * - Price Tier
       *
       * SCHEDULING TABS
       * - Schedule Changes
       * - Scheduling Reports
       *
       * EARNINGS PREMIUM TABS
       * - Positions Earnings Premium
       * - Employee Profile Earnings Premium
       *
       * HUMAN CAPITAL MANAGEMENT TABS
       * - Completed Leave Requests
       * - Conflicts
       * - Certifications
       *
       * LEAVE MANAGEMENT TABS
       * - Completed Leave Requests
       */
      return [
        ...EFFECTIVE_DATE_TABS,
        ...SCHEDULING_TABS,
        ...EARNINGS_PREMIUM_TABS,
        ...HUMAN_CAPITAL_MANAGEMENT_TABS,
        ...LEAVE_MANAGEMENT_TABS,
      ]
    },
    backofficeReportsWidget(): DataTableWidgetModel {
      const componentConfig = this.tabs.find(
        (tab) => tab.key === this.activeTab,
      )

      return {
        is: WidgetName.DATA_TABLE_WIDGET,
        title: this.$t(componentConfig.title),
        query: {
          resource: componentConfig.query.resource,
          filters: componentConfig.query.filters,
        },
        component: {
          columns: componentConfig.columns,
          // columns must include attributeName to download successfully
          downloadColumns:
            componentConfig.downloadColumns ?? componentConfig.columns,
        },
        toolbar: {
          displayCounts: true,
          filterOptions: {
            filters: componentConfig.filters ?? [],
            allowSearch: true,
          },
        },
      }
    },
    widgetPropsData(): object {
      const componentConfig = this.tabs.find(
        (tab) => tab.key === this.activeTab,
      )

      return {
        downloadColumns: componentConfig?.downloadColumns,
        ...componentConfig?.propsData,
      }
    },
    widgetUid(): string {
      return this.backofficeReportsWidget?.uid
    },
    metadatasReady(): boolean {
      return this.$appContext.isReady()
    },
  },
  methods: {
    saveHook(hook: DataTableWidgetHook) {
      this.hook = hook
    },
  },
})
</script>
