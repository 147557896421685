<template>
  <v-card v-if="jsonSchema" flat class="fill-height">
    <PageTitleBar v-if="title" :title="title">
      <template #before>
        <slot
          name="title-bar-before"
          :on="{
            click: () => {
              $emit('close')
            },
          }"
        >
          <v-btn
            v-if="closable"
            icon
            class="transparent opacity-blended"
            @click="$emit('close')"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </slot>
      </template>
    </PageTitleBar>

    <v-divider v-if="!hideTitleDivider" />

    <FormUpdatedBanner class="mt-2" v-bind="{ resourceName, entityId }" />

    <v-container class="pa-6">
      <ProvideCompoundFormManager
        :initial-value="initialModel"
        @errors="errors = $event"
        @input="setValue"
        @ready="setFormManager"
        @valid="errors = null"
      >
        <json-form
          v-if="formManager"
          :schema="jsonSchema"
          :form-options="effectiveFormOptions"
          :name="rootName"
          :user-context="userContext"
          :value="formManager.getFormValue(formId)"
          @debouncing="debouncing = $event"
          @input="formManager.setFormValue(formId, $event)"
          @errors="formManager.setFormErrors(formId, $event)"
          @valid="formManager.setFormErrors(formId, {})"
        />
      </ProvideCompoundFormManager>
    </v-container>

    <v-divider />

    <v-toolbar flat text>
      <json-valid
        v-if="needsJsonValidComponent"
        :loading="debouncing"
        :show-text="false"
        :valid="isValid"
        :errors="errors"
        :valid-text="$t('common.form.valid')"
        :invalid-text="$t('common.form.invalid')"
      />
      <v-spacer />
      <v-btn
        v-if="cancellable"
        class="ma-3"
        color="grey"
        outlined
        raised
        @click="$emit('cancel')"
      >
        <span v-text="$t(cancelBtnText)" />
      </v-btn>
      <slot
        name="save-btn"
        :disabled="!isValid || debouncing || loading || isReadOnly"
        :loading="loading"
        :on="{ click: submitForm }"
      >
        <v-btn
          :disabled="!isValid || debouncing || loading || isReadOnly"
          raised
          color="success"
          class="ma-3"
          :loading="loading"
          @click="submitForm"
        >
          <span v-text="$t(saveBtnText)" />
        </v-btn>
      </slot>
    </v-toolbar>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ErrorObject } from 'ajv'

import {
  EmptyValueRule,
  FormOptions,
  JSONSchema7,
  JsonValid,
} from '@tracktik/tt-json-schema-form'

import PageTitleBar from './PageTitleBar.vue'
import ProvideCompoundFormManager from './ProvideCompoundFormManager.vue'
import FormUpdatedBanner from './FormUpdatedBanner.vue'
import { CompoundFormManager } from '../types'
import isEqual from 'lodash/isEqual'

export interface GenericFormError<Data = Record<string, any>, Error = any> {
  data: Data
  error: Error
}

export interface GenericFormSuccess<
  Data = Record<string, any>,
  Response = any,
> {
  data: Data
  response: Response
}

export default Vue.extend({
  name: 'GenericForm',
  components: {
    ProvideCompoundFormManager,
    JsonValid,
    PageTitleBar,
    FormUpdatedBanner,
  },
  props: {
    /**
     * Cancel button text
     */
    cancelBtnText: { type: String, default: 'common.cancel.btn' },
    /**
     * Show cancel button
     */
    cancellable: { type: Boolean, default: true },
    /**
     * Show close button
     */
    closable: { type: Boolean, default: true },
    /**
     * tt-json-schema-form's form options
     */
    formOptions: { type: Object as PropType<FormOptions>, default: () => ({}) },
    /**
     * Hides the divider that comes after the title toolbar
     */
    hideTitleDivider: { type: Boolean, default: false },
    /**
     * Form's initial value
     */
    initialModel: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    /**
     * tt-json-schema-form's JSON Schema
     */
    jsonSchema: { type: Object as PropType<JSONSchema7>, required: true },
    /**
     * tt-json-schema-form's root field name
     */
    rootName: { type: String, default: undefined },
    /**
     * Save button text
     */
    saveBtnText: { type: String, default: 'common.save.btn' },
    /**
     * Submit handler
     */
    submit: {
      type: Function as PropType<(data: Record<string, any>) => Promise<any>>,
      default: (data) => Promise.resolve(data),
    },
    /**
     * Form's title
     */
    title: { type: String, default: undefined },
    /**
     * tt-json-schema-form's user context
     */
    userContext: {
      type: Object as PropType<Record<string, any>>,
      default: undefined,
    },
  },
  data() {
    return {
      debouncing: false,
      errors: null as { [key: string]: ErrorObject[] } | null,
      formManager: null as CompoundFormManager | null,
      loading: false,
    }
  },
  computed: {
    effectiveFormOptions(): FormOptions {
      /**
       * Passing the desctructured object directly in template force a prop
       * update on each render
       */
      return {
        emptyValues: EmptyValueRule.APPLY_DEFAULTS,
        locale: this.$i18n.locale,
        ...this.formOptions,
      }
    },
    formId(): string {
      return this.$options.name
    },
    needsJsonValidComponent(): boolean {
      return process.env.NODE_ENV !== 'production'
    },
    isReadOnly(): boolean {
      return this.effectiveFormOptions.readOnly || false
    },
    isValid(): boolean {
      return !this.errors
    },
    entityId(): string {
      const entityId = this.userContext?.itemHook?.getEntityId()

      return entityId ? `${entityId}` : ''
    },
    resourceName(): string {
      return this.userContext?.resourceName || ''
    },
  },
  methods: {
    setValue(value: Record<string, unknown>): void {
      this.$emit('input', value)

      const dirty = !isEqual(this.initialModel, value)
      this.$emit('dirty', dirty)
    },
    setFormManager(formManager: CompoundFormManager) {
      this.formManager = formManager
    },
    submitForm(): void {
      if (!this.isValid || !this.formManager) {
        return
      }

      const model = this.formManager.getCompoundValue()

      this.$emit('submit', model)
      this.loading = true

      // @ts-ignore -- VueJS wrongly types default for function props
      this.submit(model)
        .then((response) => {
          const event: GenericFormSuccess = { data: model, response }
          this.$emit('submit:success', event)
        })
        .catch((error) => {
          this.$crash.captureException(error)

          const event: GenericFormError = { data: model, error }
          this.$emit('submit:error', event)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
