<template>
  <div>
    <div class="d-flex align-baseline mb-6">
      <span class="mr-2" v-text="buttonLabel" />
      <TButtonToggleMultiple
        v-model="instructionsToggle"
        :items="instructionsToggleItems"
      />
    </div>
    <json-field name="client" />
    <json-field name="region" />
    <json-field name="isAvailableInSubRegion" />
    <json-field name="ticketType" />
    <json-field name="description" as="TextAreaField" />
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { LOCALIZE_PATH, PATH } from './constants'
import { InstructionsToggleItems } from './types'
import { Resources } from '../../types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'SystemExceptionTicketInstructionsCreateForm',
  inject: ['formHook'],
  computed: {
    instructionsToggle: {
      get(): string {
        return `${this.formHook().getSelectedSubSchema(PATH)}`
      },
      set(option: string) {
        this.formHook().setSelectedSubSchema(PATH, Number(option))
      },
    },
    buttonLabel(): string {
      return this.$t(`${LOCALIZE_PATH}.toggle-btn`)
    },
    instructionsToggleItems(): InstructionsToggleItems[] {
      return [
        {
          text: this.$t(
            `res.${Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS}.attr.region.label`,
          ),
          value: '0',
        },
        {
          text: this.$t(
            `res.${Resources.SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS}.attr.client.label`,
          ),
          value: '1',
        },
      ]
    },
  },
})
</script>
