import isEmpty from 'lodash/isEmpty'

import i18n from '@/plugins/i18n'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { ActionFormTypes, modularManager } from '@/tt-app-modular'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { DialogFormInterface, DialogInterface } from '@/tt-app-layout'
import { FormSchemaTypes } from '@/tt-widget-factory/services/resource-meta/types'
import { getResourceDefinitions } from '@/tt-entity-forms'
import { LayoutWindowEvent } from '@/tt-app-layout'

import { BaseEntityIntent } from './BaseEntityIntent'
import {
  displayErrorMessages,
  getCreateFormName,
  parseErrorMessages,
} from './helpers'
import { EntityCreateIntentInterface, EntityIntentTypes } from './types'
import {
  getResourceFormDefinition,
  getResourceFormDialogOptions,
  getResourceFormWidth,
  getResourceStandaloneForm,
  isResourceFormStandaloneComponent,
} from '../helper'
import { ResourceTranslator } from '../ResourceTranslator'

type CreateFormType = ActionFormTypes.CREATE | ActionFormTypes.FULL_ENTITY
const { CREATE, FULL_ENTITY } = ActionFormTypes

export class EntityCreateIntent extends BaseEntityIntent {
  /**
   * Return the event name
   */
  getEventName(): string {
    return EntityIntentTypes.CREATE
  }

  async getDialogFormBuilder({
    resourceName,
    title = 'intents.entity.create',
    initialModel = {},
    formOptions,
    buildDefinitions = (definitions) => definitions,
    onSuccess = (newEntity) => {},
    itemHook = null,
  }: EntityCreateIntentInterface): Promise<DialogFormBuilder> {
    const { eventManager, entityServices, widgetServices } = this.appContext
    const rootName = getCreateFormName(resourceName)

    const getDefaultConfig = (): Partial<Action> => {
      const schema = widgetServices.resourceMetaManager.getFormSchema(
        resourceName,
        FormSchemaTypes.CREATE,
      )
      return { schema }
    }

    const getDynamicConfig = async (): Promise<Partial<Action>> => {
      const actions =
        await widgetServices.resourceActionManager.getBatchActions(
          resourceName,
          { includeOperations: true, jsonFormSchema: true },
        )
      const config = actions.find(({ actionName }) => actionName === CREATE)
      if (!config) {
        throw new Error(
          "Failed to build form: 'create' schema is not in the list of available actions",
        )
      }

      // @ts-ignore -- json schema type is missing components
      const { components } = getDefaultConfig().schema ?? {}

      const schema = { components, ...config.schema }
      return { ...config, schema }
    }

    const hasDynamicSchema = modularManager.hasDynamicResourceJsonSchemaAction(
      resourceName,
      CREATE,
    )

    const formConfig = hasDynamicSchema
      ? await getDynamicConfig()
      : getDefaultConfig()

    const { definitions: dynamicDefinitions, ...dynamicFormOptions } =
      formConfig?.formOptions ?? {}

    const resourcesDefinitions = getResourceDefinitions(this.appContext)

    const customCreateForm = getResourceFormDefinition(
      rootName,
      resourceName,
      CREATE,
    )

    const customFullEntityForm = getResourceFormDefinition(
      rootName,
      resourceName,
      FULL_ENTITY,
    )

    // If CREATE form is specifically register, use it
    // if not, fallback to the FULL_ENTITY form
    // If nothing is registered, will use the default layout
    const customForm = !isEmpty(customCreateForm)
      ? customCreateForm
      : customFullEntityForm

    const defaultDefinitions = {
      ...resourcesDefinitions,
      ...customForm,
      ...dynamicDefinitions,
    }
    const definitions = buildDefinitions(defaultDefinitions, rootName)

    const dialogWidth = getResourceFormWidth(resourceName)
    const dialogOptions = getResourceFormDialogOptions(resourceName)

    const submitFn: DialogFormInterface['submit'] = (entity) =>
      entityServices.persister.addEntity(resourceName, entity)

    const successFn: DialogFormInterface['success'] = (_, newEntity) => {
      eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: i18n.t('common.entity_created_successful'),
      })

      eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
        resource: resourceName,
      })

      onSuccess(newEntity)
    }

    const errorFn: DialogFormInterface['error'] = (error) => {
      const messages = parseErrorMessages({ error, resourceName })
      displayErrorMessages(messages, eventManager)
    }

    return new DialogFormBuilder(title, rootName, { eventManager, i18n })
      .setJsonSchema(formConfig.schema)
      .addToFormOptions({ ...dynamicFormOptions, ...formOptions })
      .addToInitialModel(initialModel)
      .addToContext({ resourceName, ...(itemHook ? { itemHook } : {}) })
      .setTranslateFunction(ResourceTranslator.getFormCallback(resourceName))
      .addToDefinitions(definitions)
      .onSubmit(submitFn)
      .onSuccess(successFn)
      .onError(errorFn)
      .setWidth(dialogWidth)
      .mergeWithDialogOptions(dialogOptions)
  }

  async run(payload: EntityCreateIntentInterface): Promise<void> {
    const formRootName = getCreateFormName(payload.resourceName)
    const builder = await this.getDialogFormBuilder(payload)
    const dialogForm = builder.getState()

    const hasStandaloneComponentRegistered = (type: CreateFormType) =>
      isResourceFormStandaloneComponent(
        formRootName,
        payload.resourceName,
        type,
      )

    const dispatchStandaloneComponent = (type: CreateFormType) => {
      const dialog: DialogInterface = {
        is: getResourceStandaloneForm(formRootName, payload.resourceName, type),
        title: dialogForm.title,
        props: {
          payload: dialogForm,
          persistent: true,
        },
      }
      // Display Dialog with standalone component
      this.appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG,
        dialog,
      )
    }

    if (hasStandaloneComponentRegistered(CREATE)) {
      dispatchStandaloneComponent(CREATE)
    } else if (hasStandaloneComponentRegistered(FULL_ENTITY)) {
      dispatchStandaloneComponent(FULL_ENTITY)
    } else {
      // display DialogForm with JSONForm component
      builder.displayDialog()
    }
  }
}
