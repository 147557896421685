<template>
  <v-btn
    color="ttPrimary"
    :style="{ color: 'white' }"
    :loading="loading"
    :disabled="!isReady"
    @click="openAssignView"
  >
    <template #loader>
      <TLoadingBar class="h-100" />
    </template>
    <v-icon small v-text="'mdi-map-marker-radius'" />
    <span
      class="px-2"
      v-text="$t('tt-entity-design.dispatch-tasks.form-button.select-user')"
    />
  </v-btn>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { FormHookProvider } from '@/tt-widget-components'
import { updateDOM } from '@tracktik/tt-helpers/lib/browser/updateDOM'
import {
  AssignManagerInterface,
  createAssignManager,
} from '../assign/dispatch-assign-manager'
import {
  AccountLocationInterface,
  AssignViewProps,
  ClientInterface,
  SelectPayload,
  TaskInterface,
} from '../types'
import { DispatchTaskFormServiceProvider } from './types'

export default (
  Vue as VueConstructor<
    Vue & FormHookProvider & DispatchTaskFormServiceProvider
  >
).extend({
  name: 'DispatchTasksFormAssignButton',
  inject: ['formHook', 'service'],

  data() {
    return {
      loading: false,
    }
  },
  computed: {
    clientItems(): ClientInterface {
      return this.service.getClientItems()
    },
    taskData(): TaskInterface {
      const location = {
        type: this.formHook().getPathValue('location.type'),
        regionId: this.formHook().getPathValue('location.region'),
        latitude: this.formHook().getPathValue('location.latitude'),
        longitude: this.formHook().getPathValue('location.longitude'),
        city: this.formHook().getPathValue('location.city'),
        state: this.formHook().getPathValue('location.state'),
      }

      const accountLocation = {
        addressId: this.clientItems?.address?.id || '',
        latitude: this.clientItems?.address?.latitude || '',
        longitude: this.clientItems?.address?.longitude || '',
        city: this.clientItems?.address?.city || '',
        state: this.clientItems?.address?.state || '',
      } as AccountLocationInterface

      return {
        clientId: this.formHook().getPathValue('client'),
        clientRegionId: this.clientItems?.region?.id || null,
        taskTypeId: this.formHook().getPathValue('taskType'),
        locationType: this.formHook().getPathValue('locationType'),
        location,
        accountLocation,
      }
    },
    assignViewProps(): AssignViewProps {
      return {
        coordinates: this.assignManager.getCoordinates(),
        regionId: this.assignManager.getRegionId(),
        cityName: this.assignManager.getCity(),
        stateName: this.assignManager.getState(),
        taskTypeId: this.assignManager.getTaskTypeId(),
        clientId: this.assignManager.getClientId(),
        onSelect: this.onSelect,
      }
    },
    isReady(): boolean {
      return this.assignManager.isReady() || false
    },
    assignManager(): AssignManagerInterface {
      return createAssignManager(this.taskData)
    },
  },
  methods: {
    async openAssignView(): Promise<void> {
      this.loading = true

      await updateDOM()
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'DispatchAssignView',
        title: 'res.dispatch-tasks.actions.assign.labels.label',
        props: { ...this.assignViewProps },
      })

      this.loading = false
    },
    async onSelect(payload: SelectPayload): Promise<void> {
      this.loading = true

      await updateDOM()

      this.$emit('input', payload)

      this.loading = false
    },
  },
})
</script>

<style scoped>
.assigned--btn {
  color: 'white';
}
</style>
