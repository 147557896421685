<template>
  <json-field v-slot="{ viewComponent: { props, on } }" :name="valueFieldKey">
    <json-form
      v-if="subFormSchema"
      v-model="subFormValue"
      :form-options="subFormOptions"
      :schema="subFormSchema"
      @debouncing="formHook().setIsDebouncing($event)"
    >
      <json-field :name="valueFieldKey" v-bind="props" v-on="on" />
    </json-form>
    <v-skeleton-loader v-else-if="loading" tile type="card-heading" />
    <v-text-field
      v-else
      v-bind="{ ...vuetifyDefaults, ...props, value: '******' }"
      disabled
      error
      :error-messages="errorMessage"
      prepend-inner-icon="mdi-lock"
      style="width: 100%"
      v-on="on"
    />
  </json-field>
</template>

<script lang="ts">
import merge from 'lodash/merge'
import Vue, { VueConstructor } from 'vue'

import { EntityRequestOptions, Field } from 'tracktik-sdk/lib/common/entity'
import {
  FormOptions,
  JSONSchema7,
  vuetifyDefaults,
} from '@tracktik/tt-json-schema-form'

import {
  displayErrorMessages,
  parseErrorMessages,
} from '@/tt-widget-entity-flow/intents/helpers'
import { FormHookProvider } from '@/tt-widget-components'

import { ReportTemplateFieldType } from '../report-template-fields/types'
import { ReportTemplatesJsonFormSchemaExtensionResponse } from '../report-templates/types'
import { Resources } from '../../types'

type ConditionSetData = { id: number; reportTemplate?: number }

type ReportTemplateData = {
  id: number
  jsonFormSchema: ReportTemplatesJsonFormSchemaExtensionResponse
  reportFields: ReportTemplateFieldData[]
}

type ReportTemplateFieldData = { id: number; type: string }

type VueWithInjections = VueConstructor<Vue & FormHookProvider>

/**
 * Remove '*' from label if it's required
 */
const cleanFieldLabel = (label: string): string => label.replace(/\s*\*$/, '')

/**
 * Dynamic form field that adapts to the type of the selected trigger field
 *
 * @example: if the trigger field is a list field options A and B, the value
 * field will also be a list field with options A and B.
 */
export default (Vue as VueWithInjections).extend({
  name: 'ReportConditionsValueField',
  inject: ['formHook'],
  data() {
    return {
      loading: false,
      reportFields: [] as ReportTemplateFieldData[],
      reportTemplateForm:
        null as ReportTemplatesJsonFormSchemaExtensionResponse | null,
    }
  },
  computed: {
    conditionSetFieldKey(): string {
      return 'conditionSet'
    },
    conditionSetFieldLabel(): string {
      const label = this.formHook().getFieldLabel(this.conditionSetFieldKey)

      return cleanFieldLabel(label)
    },
    conditionSetFieldValue(): number | null {
      return this.formHook().getPathValue(this.conditionSetFieldKey) ?? null
    },
    errorMessage(): string | null {
      const translationKey =
        'tt-entity-design.report-conditions.form.value.errors.dependency'

      if (!this.conditionSetFieldValue) {
        const fieldLabel = this.conditionSetFieldLabel

        return this.$t(translationKey, { fieldLabel })
      }

      if (!this.triggerFieldValue) {
        const fieldLabel = this.triggerFieldLabel

        return this.$t(translationKey, { fieldLabel })
      }

      if (!this.subFormSchema) {
        return this.$t('common.error_message')
      }

      return null
    },
    reportFieldsSchema(): JSONSchema7 | null {
      return this.reportTemplateForm?.schema?.definitions?.reportFields ?? null
    },
    selectedField(): ReportTemplateFieldData | null {
      if (!this.triggerFieldValue) return null

      return (
        this.reportFields.find(({ id }) => id === this.triggerFieldValue) ??
        null
      )
    },
    selectedFieldIsNumber(): boolean {
      return this.selectedFieldType === ReportTemplateFieldType.TextNumber
    },
    selectedFieldIsListMultiple(): boolean {
      return this.selectedFieldType === ReportTemplateFieldType.ListMultiple
    },
    selectedFieldSchema(): JSONSchema7 | null {
      if (!this.selectedFieldSchemaKey) return null

      return (
        this.reportFieldsSchema?.properties?.[this.selectedFieldSchemaKey] ??
        null
      )
    },
    selectedFieldSchemaKey(): string | null {
      if (!this.reportFieldsSchema || !this.triggerFieldValue) return null

      const matchesTriggerFieldValue = (key: string) =>
        key.endsWith(this.triggerFieldValue.toString())

      const keys = Object.keys(this.reportFieldsSchema.properties ?? {})

      return keys.find(matchesTriggerFieldValue) ?? null
    },
    selectedFieldType(): ReportTemplateFieldType | null {
      if (!this.selectedField?.type) return null

      return this.selectedField.type as ReportTemplateFieldType
    },
    subFormOptions(): FormOptions {
      if (!this.reportTemplateForm) return {}

      return merge(
        {},
        this.formHook().formOptions,
        this.reportTemplateForm.formOptions,
      )
    },
    subFormSchema(): JSONSchema7 | null {
      if (!this.reportTemplateForm || !this.selectedFieldSchema) {
        return null
      }

      const isValueRequired =
        this.formHook().initialSchema.required?.includes(this.valueFieldKey) ??
        false

      return {
        type: 'object',
        properties: {
          value: this.selectedFieldSchema,
        },
        required: isValueRequired ? [this.valueFieldKey] : undefined,
        definitions: this.reportTemplateForm.schema?.definitions ?? {},
      }
    },
    subFormValue: {
      get(): Record<string, unknown> {
        const formValue = this.formHook().value

        /**
         * The value of the value field is always a string in the main form.
         * The type of the value field in the subform depends on the type of
         * the selected field.
         */
        if (this.selectedFieldIsNumber && formValue.value) {
          return { ...formValue, value: Number(formValue.value) }
        }

        /**
         * If it's a select multiple field, the value that comes from the main form
         * will be a string with the selected options separated by commas.
         */
        if (this.selectedFieldIsListMultiple) {
          return { ...formValue, value: formValue.value?.split(',') ?? [] }
        }

        return formValue
      },
      set(formValue: Record<string, unknown>) {
        const oldVal = this.subFormValue.value ?? null
        const newVal = formValue.value ?? null

        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          /**
           * Convert the value back to a string before setting it in the main
           * form.
           */
          const newValAsString: string | null = newVal?.toString()

          this.formHook().setObjectValue(this.valueFieldKey, newValAsString)
        }
      },
    },
    triggerFieldKey(): string {
      return 'triggerField'
    },
    triggerFieldLabel(): string {
      const label = this.formHook().getFieldLabel(this.triggerFieldKey)

      return cleanFieldLabel(label)
    },
    triggerFieldValue(): number | null {
      return this.formHook().getPathValue(this.triggerFieldKey) ?? null
    },
    valueFieldKey(): string {
      return 'value'
    },
    valueFieldValue(): string {
      return this.formHook().getPathValue(this.valueFieldKey) ?? null
    },
    vuetifyDefaults(): typeof vuetifyDefaults {
      return vuetifyDefaults
    },
  },
  watch: {
    conditionSetFieldValue: {
      immediate: true,
      handler(value?: number) {
        if (value) {
          this.loadReportTemplateForConditionSet(value)
        } else {
          this.reportFields = []
          this.reportTemplateForm = null
        }
      },
    },
    triggerFieldValue() {
      if (this.valueFieldValue != null) {
        this.formHook().setObjectValue(this.valueFieldKey, null)
      }
    },
  },
  methods: {
    fetchConditionSetData(
      conditionSetFieldValue: number,
    ): Promise<ConditionSetData | never> {
      const resource = Resources.REPORT_CONDITION_SETS
      const fields: Field[] = [{ attribute: 'reportTemplate' }]
      const options: EntityRequestOptions = { fields }

      return this.$appContext.authModule
        .getApi()
        .get<number, ConditionSetData>(
          resource,
          conditionSetFieldValue,
          options,
        )
        .catch((error) => this.handleResourceError(resource, error))
    },
    fetchReportTemplateData(
      reportTemplateId: number,
    ): Promise<ReportTemplateData | never> {
      /**
       * Only Report Templates support conditions. When we start supporting
       * conditions for other kinds of templates, we'll need to check the type
       * of the template and use the appropriate resource.
       */
      const resource = Resources.REPORT_TEMPLATES

      const options: EntityRequestOptions = {
        extension: ['jsonFormSchema'],
        include: ['reportFields'],
      }

      return this.$appContext.authModule
        .getApi()
        .get<number, ReportTemplateData>(resource, reportTemplateId, options)
        .catch((error) => this.handleResourceError(resource, error))
    },
    handleResourceError(resourceName: string, error: any): Promise<never> {
      this.$crash.captureException(error)

      const messages = parseErrorMessages({ error, resourceName })
      displayErrorMessages(messages, this.$appContext.eventManager)

      return Promise.reject(error)
    },
    loadReportTemplateForConditionSet(
      conditionSetFieldValue: number,
    ): Promise<void> {
      this.loading = true

      return this.fetchConditionSetData(conditionSetFieldValue)
        .then(({ reportTemplate }) =>
          this.fetchReportTemplateData(reportTemplate),
        )
        .then(({ jsonFormSchema, reportFields }) => {
          this.reportFields = [...reportFields]
          this.reportTemplateForm = { ...jsonFormSchema }
        })
        .catch(() => {
          this.reportFields = []
          this.reportTemplateForm = null
        })
        .finally(() => (this.loading = false))
    },
  },
})
</script>
