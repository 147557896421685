<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <tt-tile class="tile">
          <tt-attr-img slot="avatar" name="employee.avatar" />
          <tt-attr slot="title" name="employee.name" class="font-weight-bold" />
          <template #line3>
            <div v-if="isLoading">
              <v-skeleton-loader type="text" />
            </div>
            <div v-else class="font-weight-medium d-flex">
              <tt-attr name="leaveType.name" translate-output />
              <span class="pr-1">:</span>
              <div class="d-flex">
                <tt-attr name="startedOn" format="asDate" />
                <span class="px-1">-</span>
                <tt-attr name="endedOn" format="asDate" />
                <v-tooltip
                  v-if="showPastDateWarning"
                  bottom
                  :open-delay="tooltipOpenDelay"
                >
                  <template #activator="{ on }">
                    <v-icon
                      small
                      class="warning-color pl-2 align-self-baseline"
                      v-on="on"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  <span v-text="$t(tooltipText)" />
                </v-tooltip>
              </div>
            </div>
          </template>
          <tt-attr
            v-if="showActions"
            slot="line2"
            name="employee.jobTitle"
            prepend-icon="mdi-account-circle"
            prepend-icon-color="green"
          />
        </tt-tile>
      </v-col>
    </v-row>
    <ApplyActionProvider v-if="showActions">
      <v-row>
        <LeaveRequestsBtnGroupAction
          :actions="[
            'approve',
            'decline',
            'cancel',
            'approve-cancellation',
            'decline-cancellation',
            'request-cancellation',
          ]"
        />
      </v-row>
    </ApplyActionProvider>
  </v-container>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'
import { isDateInPast } from '@/helpers/dates/isDateInPast'
import { LeaveRequestsStatus } from '@/tt-widget-views/leave-management/types'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'LeaveRequestsTile',
  inject: ['getItemHook'],
  props: {
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isLoading(): boolean {
      return this.getItemHook().loading
    },
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
    requestStatus(): LeaveRequestsStatus {
      return this.getItemHook().getRawValue('status')
    },
    requestStartDate(): string {
      return this.getItemHook().getRawValue('startedOn')
    },
    statusIsPending(): boolean {
      return (
        this.requestStatus === LeaveRequestsStatus.PENDING ||
        this.requestStatus === LeaveRequestsStatus.PENDING_CANCELLATION
      )
    },
    showPastDateWarning(): boolean {
      return (
        isDateInPast(this.requestStartDate, null, 'day') && this.statusIsPending
      )
    },
    tooltipText(): string {
      return 'tt-entity-design.leave-management.leave-requests.past-date'
    },
  },
  created() {
    this.getItemHook().addAttribute('employee.jobTitle')
    this.getItemHook().addAttribute('status')
  },
})
</script>

<style scoped>
.warning-color {
  color: var(--v-ttWarning-base);
}
.tile >>> .v-avatar {
  align-self: flex-start;
  margin-top: 0 !important;
}
</style>
