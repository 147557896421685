import {
  EventHandler,
  EventManagerInterface,
  EventPayload,
  GlobalEventHandler,
} from '@/tt-event-manager/types'
import { ContextManagerBaseInterface } from '../types'

/**
 * Context manager base
 */
export default class ContextManagerBase implements ContextManagerBaseInterface {
  eventManager: EventManagerInterface

  constructor(eventManager: EventManagerInterface) {
    this.eventManager = eventManager
  }

  subscribeGlobal(handler: GlobalEventHandler): void {
    this.eventManager.subscribeGlobal(handler)
  }

  dispatchEvent<E extends keyof EventPayload, P extends EventPayload[E]>(
    eventName: E,
    payload: P,
    contextId?: string | undefined,
  ): void {
    this.eventManager.dispatchEvent(eventName, payload, contextId)
  }

  subscribeEvent(
    eventName: string,
    handler: EventHandler,
    contextIds?: string[] | undefined,
  ) {
    return this.eventManager.subscribeEvent(eventName, handler, contextIds)
  }
}
