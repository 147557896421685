import {
  Extension,
  ExtensionProperty,
  Attribute,
  Resource,
  ResourceSchema,
} from '../../schema-types'
import { ComponentProps } from '@tracktik/tt-helpers/lib/types'
import DispatchAssignView from './assign/DispatchAssignView.vue'
import { ActionSchema } from '@/tt-entity-design/src/schema-types'
import { Resources } from '../../types'

export type DISPATCH_TASK = 'dispatch-tasks'

export type DispatchTaskAttribute = Attribute<DISPATCH_TASK>

export type DispatchTaskExtension = Extension<DISPATCH_TASK>

export type TransitionsExtensionProperty = ExtensionProperty<
  DISPATCH_TASK,
  'availableTransitions'
>

export interface AddressInterface {
  id?: number
  name?: string
  addressLine1: string
  addressLine2?: string
  city: string
  state?: string
  postalCode: string
  country?: string
  latitude?: number
  longitude?: number
}

export interface LocationInterface {
  type: LocationType
  regionId: number
  latitude: string | number
  longitude: string | number
  city: string
  state: string
}

export interface AccountLocationInterface {
  latitude: string | number
  longitude: string | number
  city: string
  state: string
  addressId: number
}

export interface TaskInterface {
  clientId: number
  clientRegionId: number
  taskTypeId: number
  locationType: LocationType
  location: LocationInterface
  accountLocation: AccountLocationInterface
}

export interface TaskInstructionInterface {
  taskType: number
  site: number
  jobInstructions: string
  generalInstructions: string
  checkPointTour: number
  priceTier: number
  dispatchSla: number
  generalDispatchSla: string
  id: number
}

export interface ClientInterface {
  name?: string
  company?: string
  region: { name: string; id: number }
  customId: string
  address: AddressInterface
}

export interface NewClientInterface {
  address: AddressInterface
  company: string
  region: number
  type: string
  firstName: string
  lastName: string
  email: string
  primaryPhone: string
}

export interface TaskSiteSettingsInterface {
  site: number
  defaultInstructions: string
  dispatchSlaCategory: number
  alarmOrganization: boolean
  dispatchBanned: boolean
  taskInstructions: TaskInstructionInterface[]
  id: number
}

export enum TaskStatusEnum {
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
}

export enum LocationType {
  COORDINATES = 'COORDINATES',
  ADDRESS = 'ADDRESS',
  ACCOUNT_ADDRESS = 'ACCOUNT_ADDRESS',
}

enum TaskTypePriority {
  LOW = 'LOW',
  IMPORTANT = 'IMPORTANT',
  URGENT = 'URGENT',
}

export interface TaskTypeItems {
  billable: boolean
  reportTemplate: number
  priority: TaskTypePriority
}

export type ReportValues = {
  account: string
  reportTemplate: number
  reportFields: Record<string, unknown>
}

export type AssignViewProps = ComponentProps<typeof DispatchAssignView>

export type GroupResource = keyof ActionSchema<
  'dispatch-tasks',
  'assign'
>['oneOf'][0]['properties']['group']['properties']['resource']['x-ttc-labels']['list']

export type PayloadUser = {
  notify?: boolean
  user: number

  group?: null
}

export type PayloadGroup = {
  group: {
    resource: GroupResource
    id: string
  }

  notify?: false | null
  user?: null
}

export type ApiActionPayload = PayloadUser | PayloadGroup

export type PayloadAssignment = {
  group: {
    resource: GroupResource
    id: number
  } | null

  user: {
    resource: typeof Resources.USERS
    id: number
  } | null
}

export type AssignType = Extract<
  Resource,
  'users' | 'positions' | 'vendors' | 'zones' | 'clients'
>

export type SelectPayload = {
  resource: AssignType
  id: number
}

export type OnSelectCallback = (payload: SelectPayload) => void

export enum DispatchTasksForm {
  DISPATCH_TASKS_PUT = 'DispatchTasksPut',
  DISPATCH_TASKS_POST = 'DispatchTasksPost',
}

export enum AssignedGroupResourceTypeEnums {
  EMPLOYEE = 'employees',
  VENDOR = 'vendors',
  ACCOUNT = 'accounts',
  CLIENT = 'clients',
  POSITION = 'positions',
  ZONE = 'zones',
  DEPARTMENT = 'departments',
}

export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}
export type Priority =
  keyof ResourceSchema<'dispatch-tasks'>['attributes']['priority']['labels']['list']
