import hook from './MapWidgetHook'
import template from './MapWidgetWrapper.vue'
import MapWidgetEditor from './MapWidgetEditor.vue'
import Vue from 'vue'
import schema from './schema'
import { WidgetTypeDefinition } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

Vue.component('MapWidgetEditor', MapWidgetEditor)

export interface CurrentMapInfo {
  lat: number
  lng: number
  zoom: number
}

const MapWidget: WidgetTypeDefinition = {
  name: WidgetName.MAP_WIDGET,
  schema,
  component: {
    template,
    hook,
  },
  editorDefinitions: {
    MapWidget: {
      view: {
        is: 'MapWidgetEditor',
      },
    },
  },
  config: {
    icon: 'mdi-map',
    print: false,
    color: '#ff5d49',
    thumbnail: require('./maps.png'),
  },
}

export default MapWidget
