import AssetPersonsForm from '@/tt-entity-design/src/components/asset-persons/AssetPersonsForm.vue'
import AssetPersonsReference from '@/tt-entity-design/src/components/asset-persons/AssetPersonsReference.vue'
import {
  addPreviewTab,
  registerResourceForm,
  registerResourceFormStandaloneComponent,
  setIconAndColorResourcePreset,
} from '@/tt-widget-entity-flow/helper'
import { CollectionQuery } from '@/tt-widget-components'
import AssetPersonCreate from '@/apps/app.tracktik.assets/components/AssetPersonCreate.vue'
import { DialogFormInterface } from '@/tt-app-layout'
import { ActionFormTypes } from '@/tt-app-modular'
import AssetPersonNameImportIconAttr from './AssetPersonNameImportIconAttr.vue'

import { Resources } from '../../types'

export default {
  install(Vue) {
    Vue.component('AssetPersonsForm', AssetPersonsForm)
    Vue.component('AssetPersonsReference', AssetPersonsReference)
    Vue.component('AssetPersonCreate', AssetPersonCreate)
    Vue.component(
      'AssetPersonNameImportIconAttr',
      AssetPersonNameImportIconAttr,
    )

    const dialogOptions: Partial<DialogFormInterface> = {
      width: 750,
    }

    registerResourceForm(
      Resources.ASSET_PERSONS,
      'AssetPersonsForm',
      ActionFormTypes.EDIT,
    )

    registerResourceFormStandaloneComponent(
      Resources.ASSET_PERSONS,
      'AssetPersonCreate',
      ActionFormTypes.CREATE,
      dialogOptions,
    )

    setIconAndColorResourcePreset(Resources.ASSET_PERSONS, 'person', 'pink')

    // Add transactions
    addPreviewTab(Resources.ASSET_PERSONS, {
      title: 'Assets',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'checkoutTransactionItem.person.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSETS,
            filters: [
              {
                attribute: 'checkoutStatus',
                operator: 'EQUAL',
                value: 'CHECKED_OUT',
              },
            ],
          },
        },
      },
    })

    // Add transactions
    addPreviewTab(Resources.ASSET_PERSONS, {
      title: 'Logs',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'person.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSET_TRANSACTION_ITEMS,
            sort: {
              attribute: 'id',
              direction: 'DESC',
            },
          } as CollectionQuery,
        },
      },
    })

    addPreviewTab(Resources.ASSET_PERSONS, {
      title: 'Reservations',
      is: 'EntityItemWidget',
      props: {
        filterAttribute: 'person.id',
        widget: {
          title: '',
          is: 'ListWidget',
          query: {
            resource: Resources.ASSET_RESERVATIONS,
          },
        },
      },
    })
  },
}
