<template>
  <v-app>
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <app-window ref="window">
          <widget-page v-bind="$props" />
        </app-window>

        <AppLayoutUtils
          v-if="layoutUtils"
          :layout-configuration="layoutConfiguration"
        />
        <AppExtensionSlotFactory container-type="div" slot-name="ANYWHERE" />
        <AppLayoutSideSheet v-if="sideSheet" />
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { WidgetModels } from '@/tt-widget-components'
import { EventLifeCycleManager } from '@/tt-widget-entity-flow/EventLifeCycleManager'
import {
  EntityAggregationDetailIntent,
  EntityAggregationIntentInterface,
  EntityIntentTypes,
} from '@/tt-widget-entity-flow'
import { ActiveWindow, LayoutConfiguration } from '@/tt-app-layout'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import { WidgetNavigation } from '@/types'

export default Vue.extend({
  name: 'WidgetPageStandAlone',
  components: {
    ProvideLayoutManager,
  },
  props: {
    layoutUtils: {
      type: Boolean,
      default: false,
    },
    sideSheet: {
      type: Boolean,
      default: true,
    },
    showContext: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 800,
    },
    widget: {
      required: true,
      type: Object as PropType<WidgetModels>,
    },
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
    navigation: {
      type: Object as PropType<WidgetNavigation>,
      default: () => undefined,
    },
  },
  data() {
    return {
      eventManager: null as null | EventLifeCycleManager,
      manager: new LayoutManager(
        this.$eventManager,
        this.layoutConfiguration,
      ) as LayoutManager,
    }
  },
  created() {
    this.eventManager = new EventLifeCycleManager(this.$eventManager)
    this.eventManager.subscribeTo(
      EntityIntentTypes.AGGREGATION_DETAILS,
      (payload: EntityAggregationIntentInterface) => {
        const window = this.$refs.window as unknown as ActiveWindow
        window.next(EntityAggregationDetailIntent.getPage(payload))
      },
    )
  },
  beforeDestroy() {
    if (this.eventManager) {
      this.eventManager.destroy()
    }
    if (this.manager) {
      this.manager.destroy()
    }
  },
})
</script>
