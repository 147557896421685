import isEmpty from 'lodash/isEmpty'

import {
  BatchFile,
  BatchFileActions,
  BatchFileOnFailureOptions,
  BatchFileOperation,
} from '@/types'
import { DialogFormInterface } from '@/tt-app-layout'
import { FormOptions, JSONSchema7 } from '@tracktik/tt-json-schema-form'
import { DayOfWeek, rangeTimeKeys } from '../types'

import {
  DayData,
  ExceptionType,
  EXTRA_ITEMS,
  REAL_DAYS,
  SchedulingData,
  SchedulingEntityKeyNames,
  SchedulingEntityKeys,
  SchedulingEntityResource,
  SchedulingGroupBatch,
  weekDays,
  weekEnds,
} from '../types'

const doNotPerformDefinition: JSONSchema7 = {
  type: 'boolean',
  default: false,
}

export const getHolidayKey = (holidayType: ExceptionType['id']) =>
  `holiday-${holidayType}`

export const parseHolidayKey = (key: string): ExceptionType['id'] =>
  parseInt(key.replace('holiday-', ''))

export default class SchemaHelper {
  private resourceName: SchedulingEntityResource
  private entityKeys: Record<SchedulingEntityKeyNames, string>

  constructor(resourceName: SchedulingEntityResource) {
    this.resourceName = resourceName
    this.entityKeys = SchedulingEntityKeys[this.resourceName]
  }

  private expandTemporaryKeyInDays(
    days: readonly DayOfWeek[],
    data: SchedulingData,
  ): Record<string, DayData> {
    return days.reduce(
      (daysObject, day) => ({
        ...daysObject,
        [day]: {
          [this.entityKeys.dayOfWeekStart]: day,
          ...data,
        },
      }),
      {},
    )
  }

  private sortDayData(a: DayData, b: DayData): number {
    return (
      REAL_DAYS.indexOf(a[this.entityKeys.dayOfWeekStart]) -
      REAL_DAYS.indexOf(b[this.entityKeys.dayOfWeekStart])
    )
  }

  public getDaysData(days: Record<string, SchedulingData>): DayData[] {
    const realData = REAL_DAYS.reduce(
      (daysData, day) => ({
        ...daysData,
        ...(!isEmpty(days[day])
          ? { [day]: { [this.entityKeys.dayOfWeekStart]: day, ...days[day] } }
          : {}),
      }),
      {},
    )

    //Use js language features of expansion to override the days data if they are defined in a group
    const allData = {
      ...(!isEmpty(days.EVERYDAY)
        ? this.expandTemporaryKeyInDays(REAL_DAYS, days.EVERYDAY)
        : {}),
      ...(!isEmpty(days.WEEKDAYS)
        ? this.expandTemporaryKeyInDays(weekDays, days.WEEKDAYS)
        : {}),
      ...(!isEmpty(days.WEEKENDS)
        ? this.expandTemporaryKeyInDays(weekEnds, days.WEEKENDS)
        : {}),
      ...realData,
    }

    //Compress back to an array, now that we have processed the days
    return Object.keys(allData)
      .map((key) => allData[key])
      .sort(this.sortDayData.bind(this))
  }

  private getExtraSchemaProperties(
    rangeStartTimeDefinition: JSONSchema7,
    rangeEndTimeDefinition: JSONSchema7,
    items: readonly string[],
  ): { [key: string]: JSONSchema7 } {
    //Add extra items to the form schema for each day and option
    return items.reduce((extraSchemeProperties, dayName) => {
      return {
        ...extraSchemeProperties,
        [dayName]: {
          type: 'object',
          properties: {
            [rangeTimeKeys.START_TIME_KEY]: rangeStartTimeDefinition,
            [rangeTimeKeys.END_TIME_KEY]: rangeEndTimeDefinition,
          },
          required: [rangeTimeKeys.START_TIME_KEY, rangeTimeKeys.END_TIME_KEY],
        },
      }
    }, {} as Record<string, JSONSchema7>)
  }

  private getExtraHolidaySchemaProperties(
    rangeStartTimeDefinition: JSONSchema7,
    rangeEndTimeDefinition: JSONSchema7,
    holidays: ExceptionType[],
  ): { [key: string]: JSONSchema7 } {
    const holidayDefinition: JSONSchema7 = {
      type: 'object',
      properties: {
        doNotPerform: doNotPerformDefinition,
        [rangeTimeKeys.START_TIME_KEY]: rangeStartTimeDefinition,
        [rangeTimeKeys.END_TIME_KEY]: rangeEndTimeDefinition,
      },
      if: {
        properties: {
          doNotPerform: {
            const: false,
          },
        },
      },
      then: {
        required: [rangeTimeKeys.START_TIME_KEY, rangeTimeKeys.END_TIME_KEY],
      },
    }

    return {
      holidays: holidayDefinition,
      ...holidays.reduce((extraHolidayProps, holiday) => {
        const childDefinitions = Object.fromEntries(
          holiday.children?.map((child) => [
            this.getHolidayKey(child.id),
            { ...holidayDefinition, title: child.label },
          ]) || [],
        )

        return {
          ...extraHolidayProps,
          ...childDefinitions,
          [this.getHolidayKey(holiday.id)]: {
            ...holidayDefinition,
            title: holiday.label,
          },
        }
      }, {}),
    }
  }

  getHolidayKey(holidayType: ExceptionType['id']) {
    return getHolidayKey(holidayType)
  }

  parseHolidayKey(key: string): ExceptionType['id'] {
    return parseHolidayKey(key)
  }
  /**
   * Adds an extra property that contains the days of the week and holidays
   * so that we can show them in the form
   */
  modifyGroupSchemaForCustomForm(
    state: DialogFormInterface,
    holidays: ExceptionType[] = [],
  ): {
    formOptions: FormOptions
    name: string
    schema: JSONSchema7
    userContext: any
  } {
    const {
      [rangeTimeKeys.START_TIME_KEY]: rangeStartTimeDefinition,
      [rangeTimeKeys.END_TIME_KEY]: rangeEndTimeDefinition,
      ...properties
    } = state.jsonSchema.properties

    const extraSchemaProps = this.getExtraSchemaProperties(
      rangeStartTimeDefinition,
      rangeEndTimeDefinition,
      EXTRA_ITEMS,
    )

    const holidayProps = this.getExtraHolidaySchemaProperties(
      rangeStartTimeDefinition,
      rangeEndTimeDefinition,
      holidays,
    )

    const schema: JSONSchema7 = {
      ...state.jsonSchema,
      properties: {
        ...properties,
        //Add a property to the schema that will have the days
        days: {
          type: 'object',
          properties: extraSchemaProps,
        },
        holidays: {
          type: 'object',
          properties: holidayProps,
        },
      },
      //Remove the dayOfWeek from the required properties, since we will have it in each of the days
      required:
        state.jsonSchema.required?.filter(
          (property) =>
            ![
              this.entityKeys.dayOfWeekStart,
              rangeTimeKeys.START_TIME_KEY,
              rangeTimeKeys.END_TIME_KEY,
            ].includes(property),
        ) || [],
    }

    return {
      formOptions: state.formOptions,
      name: state.rootName,
      schema: schema,
      userContext: state.userContext,
    }
  }
  // Creates the final batch request that will be sent to the backend
  createBatchRequest(
    batchData: SchedulingGroupBatch,
    removedItemIds: number[] = [],
  ): BatchFile {
    const { days, holidays: _, ...generalData } = batchData

    const replaceOperations: BatchFileOperation[] = this.getDaysData(days).map(
      (day) => ({
        resource: this.resourceName,
        data: { ...day, ...generalData },
        /**
         * If any existing item match the lookup criteria, update it.
         * Otherwise, create a new one.
         */
        action: BatchFileActions.REPLACE,
        lookup: {
          [this.entityKeys.dayOfWeekStart]: day[this.entityKeys.dayOfWeekStart],
          [this.entityKeys.groupName]: generalData[this.entityKeys.groupName],
        },
      }),
    )

    const archiveOperations: BatchFileOperation[] = removedItemIds.map(
      (id) => ({
        resource: this.resourceName,
        data: {},
        action: BatchFileActions.EXECUTE,
        actionName: 'archive',
        lookup: id.toString(),
      }),
    )

    return {
      onFailure: BatchFileOnFailureOptions.ROLLBACK,
      operations: [...replaceOperations, ...archiveOperations],
    }
  }
}
