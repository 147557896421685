<template>
  <v-card>
    <!-- loading -->
    <v-skeleton-loader
      v-if="isFetching"
      type="card-heading, list-item-two-line"
    />
    <!-- / loading -->

    <!-- languages list -->
    <v-list v-else>
      <!-- no translations message -->
      <template v-if="noTranslations">
        <v-list-item>
          <v-list-item-content>
            <t-alert :text="noTranslationsMessage" />
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- / no translations message -->

      <template v-for="(translations, languageCode, index) in items" v-else>
        <!-- language name -->
        <v-subheader :key="languageCode" class="font-weight-medium">
          <v-icon small class="mr-2"> mdi-translate </v-icon>
          {{ getLanguageName(languageCode) }}

          <!-- delete translations for this language -->
          <v-btn
            icon
            color="error"
            class="ml-auto"
            @click="confirmDeleteTranslation(languageCode)"
          >
            <v-icon small> mdi-minus-circle-outline </v-icon>
          </v-btn>
          <!-- / delete translations for this language -->
        </v-subheader>
        <!-- / language name -->

        <!-- translations list -->
        <v-list-item v-for="item in translations" :key="JSON.stringify(item)">
          <v-list-item-content>
            <v-list-item-subtitle>{{ item.fieldName }}</v-list-item-subtitle>
            <v-list-item-title>{{ item.translation }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- / translations list -->

        <!-- divider -->
        <v-divider
          v-if="index < Object.keys(items).length - 1"
          :key="`divider-${index}`"
        />
        <!-- / divider -->
      </template>
    </v-list>
    <!-- / languages list -->
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType, VueConstructor } from 'vue'
import { Api } from '@tracktik/tt-authentication'
import { FilterOperatorType } from 'tracktik-sdk/lib/common/entity-filters'
import { Filter } from 'tracktik-sdk/lib/common/entity-filters'

import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import { Resources } from '@/tt-entity-design/src/types'
import { EntityCollectionRequestOptions } from 'tracktik-sdk/lib/common/entity-collection'
import { getValidLocale } from '@/locales/utils'
import { LANGUAGE_OPTIONS } from '@/locales/language-options'
import { LayoutWindowEvent } from '@/tt-app-layout'

import {
  AssetsTranslationsTileGroupedItems,
  AssetsTranslationsActions,
} from './types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'AssetsTranslationsTile',
  inject: ['getItemHook'],
  props: {
    resource: {
      type: String as PropType<Resources>,
      required: true,
    },
    parentResource: {
      type: String as PropType<Resources>,
      required: true,
    },
    deleteAction: {
      type: String as PropType<AssetsTranslationsActions>,
      required: true,
    },
  },
  data() {
    return {
      isFetching: false,
      items: {} as AssetsTranslationsTileGroupedItems,
    }
  },
  computed: {
    id(): string {
      return this.getItemHook().getRawValue('id')
    },
    api(): Api {
      return this.$appContext.authModule.getApi()
    },
    filters(): Filter[] {
      return [
        {
          attribute: 'modelId',
          operator: FilterOperatorType.EQUAL,
          value: this.id,
        },
      ]
    },
    noTranslations(): boolean {
      return Object.keys(this.items).length === 0
    },
    noTranslationsMessage(): string {
      return this.$t(
        'tt-entity-design.asset-hub.asset-translations.no-translations',
      )
    },
  },
  created() {
    this.fetchTranslations()
  },
  methods: {
    groupByLanguage(items: Record<string, any>[]): Record<string, any> {
      return items.reduce((acc, item) => {
        // Get the frontend locale for the language. They differ in the backend
        const { language } = item

        // Initialize array for this language if it doesn't exist
        if (!acc[language]) {
          acc[language] = []
        }

        // Push the full item to the respective language array
        acc[language].push(item)

        return acc
      }, {})
    },
    async fetchTranslations(): Promise<void> {
      // prevents fetching translations if the id is not set
      if (!this.id || !this.resource) return

      // set fetching state
      this.isFetching = true

      // define the query options
      const options: EntityCollectionRequestOptions = {
        filters: this.filters,
        fields: [
          { attribute: 'id' },
          { attribute: 'translation' },
          { attribute: 'fieldName' },
          { attribute: 'language' },
        ],
      }

      return this.api
        .getAll(this.resource, options)
        .then((response) => {
          const { items } = response
          const groupedItems: AssetsTranslationsTileGroupedItems =
            this.groupByLanguage(items)

          this.items = groupedItems
        })
        .catch((err) => {
          this.$crash.captureException(err)
        })
        .finally(() => (this.isFetching = false))
    },
    getLanguageName(languageCode: string): string {
      // Get the frontend locale for the language. They differ in the backend
      const { frontendLocale } = getValidLocale(languageCode)
      // Get a friendly name for the language
      const languageOption = LANGUAGE_OPTIONS.find(
        (option) => option.value === frontendLocale,
      )

      if (languageOption) {
        // Remove the locale code from the language name. e.g. 'English [en]' -> 'English'
        return languageOption.text.replace(/\s*\[.*?\]$/, '')
      }

      return languageCode
    },
    confirmDeleteTranslation(languageCode: string): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
        message: this.$t(
          `tt-entity-design.asset-hub.asset-translations.remove-prompt`,
          { language: this.getLanguageName(languageCode) },
        ),
        accept: () => this.deleteTranslation(languageCode),
      })
    },
    deleteTranslation(languageCode: string): void {
      const { eventManager } = this.$appContext

      this.$auth
        .getApi()
        .doAction(this.parentResource, this.id, this.deleteAction, {
          language: languageCode,
        })
        .then(() => {
          eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
            message: this.$t(
              `tt-entity-design.asset-hub.asset-translations.remove-success`,
            ),
          })
          eventManager.dispatchEvent(EntityIntentTypes.RESOURCE_UPDATED, {
            resource: this.parentResource,
          })
        })
        .catch((error) => {
          eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
            message:
              error ||
              this.$t(
                'tt-entity-design.asset-hub.asset-translations.remove-error',
              ),
          })
        })
    },
  },
})
</script>

<style scoped></style>
