<template>
  <div class="bounding-box" :style="{ height: height && `${height}px` }">
    <div ref="bindingReference" class="bounding-box--reference" />
    <slot v-bind="{ ...boundingBoxData, initialized }" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export interface BoundingBoxData {
  height: number
  left: number
  top: number
  width: number
}

export default Vue.extend({
  name: 'BoundingBox',
  props: {
    inheritParentHeight: { type: Boolean, default: false },
  },
  data() {
    return {
      left: undefined,
      top: undefined,
      resizeObserver: undefined,
    }
  },
  computed: {
    boundingBoxData(): BoundingBoxData {
      return {
        height: this.height,
        left: this.left,
        top: this.top,
        width: this.width,
      }
    },
    height(): number {
      if (!this.initialized) {
        return undefined
      }

      if (this.inheritParentHeight) {
        return this.parentHeight
      }

      return window.innerHeight - this.top
    },
    parentHeight(): number {
      return this.$parent?.$el.clientHeight
    },
    initialized(): boolean {
      return this.top != null && this.left != null
    },
    width(): number {
      return this.initialized ? window.innerWidth - this.left : undefined
    },
  },
  created() {
    this.resizeObserver = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => {
        if (entries.length) {
          this.refreshBoundingBox()
        }
      },
    )
  },
  mounted() {
    window.addEventListener('resize', this.refreshBoundingBox)
    this.$nextTick(() => this.refreshBoundingBox())

    this.resizeObserver?.observe(this.$parent?.$el)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.refreshBoundingBox)
    this.resizeObserver?.disconnect()
  },
  methods: {
    refreshBoundingBox() {
      const topReference = this.$refs.bindingReference as
        | HTMLDivElement
        | undefined
      const rect: DOMRect | undefined = topReference?.getBoundingClientRect()

      if (rect) {
        this.left = rect.left
        this.top = rect.top
      }

      if (!this.initialized) {
        this.initialized = true
      }

      this.$emit('change', this.boundingBoxData)
    },
  },
})
</script>
