<template>
  <v-menu open-on-hover bottom offset-y>
    <template #activator="{ on }">
      <div v-on="on">
        <tt-attr name="client" />
      </div>
    </template>
    <DispatchTasksAddressCard
      v-bind="{ address, clientName, clientCustomId, iconAddress }"
    />
  </v-menu>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import DispatchTasksAddressCard from './cards/DispatchTasksAddressCard.vue'
import { LocationType } from './types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import { CLIENT_LOCATION_ATTRIBUTE_LIST } from './constants'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksClientLocationAttr',
  components: { DispatchTasksAddressCard },
  inject: ['getItemHook'],
  computed: {
    itemHook(): EntityItemHook {
      return this.getItemHook()
    },
    locationType(): LocationType {
      return this.itemHook.getRawValue('locationType')
    },
    addressLine1(): string {
      return this.locationType === LocationType.ADDRESS
        ? this.itemHook.getRawValue('location.addressLine1')
        : this.itemHook.getRawValue('client.address.addressLine1')
    },
    addressLine2(): string {
      return this.locationType === LocationType.ADDRESS
        ? this.itemHook.getRawValue('location.addressLine2')
        : this.itemHook.getRawValue('client.address.addressLine2')
    },
    cityState(): string {
      const value =
        this.locationType === LocationType.ADDRESS
          ? [
              this.itemHook.getRawValue('location.city'),
              this.itemHook.getRawValue('location.state'),
            ]
          : [
              this.itemHook.getRawValue('client.address.city'),
              this.itemHook.getRawValue('client.address.state'),
            ]

      return value.filter(Boolean).join(', ')
    },
    postalCode(): string {
      return this.locationType === LocationType.ADDRESS
        ? this.itemHook.getRawValue('location.postalCode')
        : this.itemHook.getRawValue('client.address.postalCode')
    },
    country(): string {
      return this.locationType === LocationType.ADDRESS
        ? this.itemHook.getRawValue('location.country')
        : this.itemHook.getRawValue('client.address.country')
    },
    address(): string[] {
      const address = [
        this.addressLine1,
        this.addressLine2,
        this.cityState,
        this.postalCode,
        this.country,
      ].filter(Boolean)

      const coordinates = [
        `Lat: ${this.itemHook.getRawValue('location.latitude')}`,
        `Lont: ${this.itemHook.getRawValue('location.longitude')}`,
      ]

      return this.locationType === LocationType.COORDINATES
        ? coordinates
        : address
    },
    clientName(): string {
      return this.itemHook.getRawValue('client.name')
    },
    clientCustomId(): string {
      return (
        this.itemHook.getRawValue('client.customId') ||
        this.itemHook.getRawValue('client.id')
      )
    },
    iconAddress(): string {
      return this.locationType === LocationType.COORDINATES
        ? 'mdi-crosshairs-gps'
        : 'mdi-map-marker-outline'
    },
  },
  created() {
    CLIENT_LOCATION_ATTRIBUTE_LIST.forEach((attr) => {
      this.itemHook.addAttribute(attr)
    })
  },
})
</script>
