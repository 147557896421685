<template>
  <div class="tree--container">
    <span
      v-if="canShowSwitch"
      class="d-flex px-6 py-3"
      style="align-items: center"
    >
      <v-switch
        v-model="showInactive"
        color="#4E69E9"
        hide-details
        class="pa-0 ma-0"
      />
      <span
        class="pl-3"
        v-text="$t(`components.region_filter_show.inactive`)"
      />
    </span>
    <AttributeTreeFilter
      :filter="filter"
      :items="treeItems"
      @apply="$emit('apply', $event)"
      @clear="$emit('clear')"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { Attribute } from '@/tt-widget-factory/services/resource-meta/types'

import { listToTree, UserRegion } from '../treeview/utils'
import { TreeviewItem } from '../treeview/types'
import { FilterWithValue } from '../../types'
import AttributeTreeFilter from '../AttributeTreeFilter.vue'
import { getUniqueRegions, isActive } from '../../../tt-region-manager/utils'

export default Vue.extend({
  name: 'RegionTreeFilter',
  components: { AttributeTreeFilter },
  props: {
    filter: {
      type: Object as PropType<FilterWithValue>,
      required: true,
    },
    userRegions: {
      type: Array as PropType<UserRegion[]>,
      default: (): UserRegion[] => [],
    },
  },
  data() {
    return {
      showInactive: true,
    }
  },
  computed: {
    /**
     * Retro-compatible feature, to only show the switch for inactive regions
     * if we have the `status` attribute in the regions from the API user sessions (LAB-1272)
     */
    canShowSwitch(): boolean {
      return this.userRegions.some((region) => !!region.status)
    },
    regionsToDisplay(): UserRegion[] {
      return this.showInactive
        ? this.userRegions
        : this.userRegions.filter(isActive)
    },
    treeItems(): TreeviewItem[] {
      const itemToTreeFn = ({
        id,
        name,
        status,
      }: UserRegion): TreeviewItem => ({
        id,
        name,
        disabled: !this.$appContext.regionManager.hasAccessToRegionId(id),
        // @ts-ignore
        status,
      })

      const contextRegion =
        this.$appContext.regionManager.getMainContextRegion()

      const missingRegions =
        this.$appContext.regionManager.getMissingParentRegions() // build a tree from the regionManager with the missing stuff automatically populated properly

      const allRegions = getUniqueRegions([
        ...(contextRegion ? [contextRegion] : []),
        ...this.regionsToDisplay,
        ...missingRegions,
      ])

      return listToTree(
        allRegions,
        itemToTreeFn,
        'parentId',
        'id',
        contextRegion?.id,
      )
    },
  },
})
</script>

<style scoped>
.tree--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
</style>
