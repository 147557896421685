<template>
  <v-card>
    <v-list class="py-2">
      <v-list-item class="tt-tile py-3">
        <v-list-item-action class="my-0 mr-4">
          <v-icon size="50" color="ttPrimary" v-text="'mdi-city'" />
        </v-list-item-action>
        <v-list-item-content class="py-0">
          <template v-if="isLoading">
            <v-skeleton-loader type="list-item-three-line" />
          </template>
          <template v-else>
            <v-list-item-title class="pb-1">
              <span v-if="name" v-text="name" />
            </v-list-item-title>
            <v-list-item-subtitle class="pb-1">
              <span
                v-if="address"
                style="color: var(--v-ttPrimary-base)"
                v-text="address"
              />
            </v-list-item-subtitle>
            <v-list-item-subtitle class="pb-1">
              <span v-if="customId" v-text="`UID: ${customId}`" />
            </v-list-item-subtitle>
            <v-list-item-subtitle class="pb-1">
              <span
                v-if="region"
                v-text="`${$t('common.label.region')}: ${region}`"
              />
            </v-list-item-subtitle>
          </template>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'DispatchTasksFormClientAddressTile',
  props: {
    clientItems: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isLoading(): boolean {
      return Object.keys(this.clientItems || {}).length === 0
    },
    address(): string | null {
      if (!this.clientItems?.address) {
        return null
      }

      const {
        latitude: _,
        longitude: __,
        id: ___,
        ...address
      } = this.clientItems.address

      return Object.values(address).filter(Boolean).join(', ')
    },
    name(): string {
      return this.clientItems?.name || ''
    },
    customId(): string {
      return this.clientItems?.customId || ''
    },
    region(): string {
      return this.clientItems?.region.name || ''
    },
  },
})
</script>
