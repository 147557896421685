<template>
  <span>
    <slot :actions="actions" :can-edit="canEdit" :loading="loading" />
  </span>
</template>

<script lang="ts">
import flow from 'lodash/flow'
import Vue, { PropType, VueConstructor } from 'vue'

import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { AllowEntityOperationsProvider } from '@/types'
import { isResourceActionBlacklisted } from '@/tt-widget-factory/services/metadata-provider/resource-action-blacklist'
import { Resource } from '@/tt-entity-design/src/schema-types'
import { SecurityRuleOperation } from '@/tt-widget-factory'

import {
  allowsEntityAction,
  allowsEntityOperation,
  isGeneralAllowEntityOption,
} from '../helper'
import { ENTITY_ACTION_REMOVE } from '../constants'
import { EntityIntentTypes, ResourceUpdatedInterface } from '../intents'
import { EventLifeCycleManager } from '../EventLifeCycleManager'
import { ResourceTranslator } from '../ResourceTranslator'

type ViewWithInjections = VueConstructor<
  Vue & Pick<AllowEntityOperationsProvider, 'allowEntityActions'>
>

/**
 * Fetches a list of actions from the API that are available for the entity of
 * the provided ID and resource type and that are allowed by the app and
 * provides the information to the component's slot.
 */
export default (Vue as ViewWithInjections).extend({
  name: 'EntityActions',
  inject: {
    // if not provided by a parent view, permissions fallback to the appContext setting
    allowEntityActions: {
      default() {
        return this.$appContext.allowEntityActions
      },
    },
  },
  props: {
    /**
     * Entity ID
     */
    entityId: { type: Number, default: null },
    /**
     * Resource name
     */
    resourceName: {
      type: String as PropType<Resource>,
      required: true,
    },
  },
  data() {
    return {
      actions: [] as Action[],
      canEdit: false,
      lifeCycleManager: null as EventLifeCycleManager,
      loading: false,
    }
  },
  computed: {
    appAllowsAllActions(): boolean {
      return (
        isGeneralAllowEntityOption(this.allowEntityActions) &&
        allowsEntityOperation(this.allowEntityActions, {
          resourceName: this.resourceName,
        })
      )
    },
    appDisallowsAllActions(): boolean {
      return (
        isGeneralAllowEntityOption(this.allowEntityActions) &&
        !allowsEntityOperation(this.allowEntityActions, {
          resourceName: this.resourceName,
        })
      )
    },
  },
  created() {
    const { eventManager } = this.$appContext
    this.lifeCycleManager = new EventLifeCycleManager(eventManager)

    this.lifeCycleManager.subscribeTo(
      EntityIntentTypes.RESOURCE_UPDATED,
      (payload: ResourceUpdatedInterface) => {
        // Don't fetch the actions for the item if it's removed, this will cause console errors
        if (
          payload.resource === this.resourceName &&
          payload.operation !== SecurityRuleOperation.DELETE &&
          payload.actionName !== ENTITY_ACTION_REMOVE
        ) {
          this.updateActions()
        }
      },
    )

    this.updateActions()
  },
  beforeDestroy() {
    if (this.lifeCycleManager) this.lifeCycleManager.destroy()
  },
  methods: {
    async fetchActions(): Promise<Action[] | void> {
      const { resourceActionManager } = this.$appContext.widgetServices || {}
      if (!resourceActionManager) return

      this.setLoading(true)
      try {
        return this.entityId
          ? await resourceActionManager.getEntityActions(
              this.resourceName,
              this.entityId,
              { includeOperations: true },
            )
          : await resourceActionManager.getBatchActions(this.resourceName)
      } catch (err) {
        this.$crash.captureException(err)
      } finally {
        this.setLoading(false)
      }
    },
    setActions(actions: Action[]): void {
      this.actions = actions

      /**
       * @event change
       * @type {Action[]}
       */
      this.$emit('change', actions)
    },
    setLoading(loading: boolean): void {
      this.loading = loading

      /**
       * @event loading
       * @type {boolean}
       */
      this.$emit('loading', loading)
    },
    async updateActions(): Promise<void> {
      const apiActions = await this.fetchActions()

      if (!apiActions) {
        this.canEdit = false
        this.setActions([])
        return
      }

      const isEdit = (action: Action): boolean =>
        action.actionName === SecurityRuleOperation.EDIT

      this.canEdit = apiActions.some(isEdit)

      if (this.appDisallowsAllActions) {
        this.setActions([])
        return
      }

      const isNotEdit = (action: Action): boolean => !isEdit(action)

      const isNotBlacklisted = (action: Action): boolean =>
        !isResourceActionBlacklisted(action)

      const appAllowsAction = (action: Action): boolean =>
        allowsEntityAction(
          this.allowEntityActions,
          this.resourceName,
          action.actionName,
        )

      const translateLabels = (action: Action): Action => {
        const labels = ResourceTranslator.translateActionLabels(
          this.resourceName,
          action.actionName,
        )
        return { ...action, ...labels }
      }

      const getValidActions = (actions: Action[]): Action[] =>
        actions.filter(isNotEdit).filter(isNotBlacklisted)

      const getAppAllowedActions = (actions: Action[]): Action[] =>
        this.appAllowsAllActions ? actions : actions.filter(appAllowsAction)

      const translateActionsLabels = (actions: Action[]): Action[] =>
        actions.map(translateLabels)

      const effectiveActions = flow(
        getValidActions,
        getAppAllowedActions,
        translateActionsLabels,
      )(apiActions)

      this.setActions(effectiveActions)
    },
  },
})
</script>
