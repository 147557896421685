<template>
  <StepsWizard class="site-task-schedules-create-wizard" :tabs="tabs">
    <template #toolbar-before>
      <StepsBreadcrumbs
        :items="breadcrumbs"
        :style="{ whiteSpace: 'nowrap' }"
      />
      <v-divider
        class="mx-11 my-auto"
        vertical
        :style="{ height: '75%', minHeight: 'unset' }"
      />
    </template>

    <template #[tabSlots.CREATE]="{ selectTab }">
      <div class="tab-content fill-height py-11">
        <v-container>
          <SiteTaskSchedulesForm
            :client-id="clientId"
            :entity-id="entityId"
            @cancel="close"
            @submit:success="onCreateSuccess($event, selectTab)"
          />
        </v-container>
      </div>
    </template>

    <template v-if="showExtraTab" #[tabSlots.EXCEPTIONS]>
      <div class="tab-content fill-height py-11">
        <v-container :style="{ maxWidth: '1030px' }">
          <template v-if="entityId">
            <!-- new UI -- show days setup -->
            <template v-if="showNewUI">
              <!-- if "recurrent" task, show days selector, exceptions are handled inside -->
              <SchedulingGroupDaysSetup
                v-if="isRecurring"
                resource-name="site-task-schedule-timings"
                :group="group"
                :account-id="clientId"
                :exception-enabled="showExceptions"
                @submit:success="close"
              />
              <!--  if "one-time" or "adhoc" task, show only start/end time pickers -->
              <div v-else>
                <SiteTaskScheduleTimingsNonRecurrent
                  :group-id="group.id"
                  @submit:success="close"
                />
              </div>
            </template>

            <!-- old UI -- show exceptions on recurrent only -->
            <SiteTaskSchedulesExceptions
              v-else
              :entity-id="entityId"
              @close="close"
            />
          </template>

          <TLoadingWave v-else />
        </v-container>
      </div>
    </template>
  </StepsWizard>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { GenericFormSuccess } from '@/tt-app-layout/components/GenericForm.vue'

import SiteTaskSchedulesForm from './SiteTaskSchedulesForm.vue'
import StepsBreadcrumbs from '../../components/StepsBreadcrumbs.vue'
import StepsWizard from '../../components/StepsWizard.vue'
import { StepsBreadcrumbsItem, StepsWizardTab } from '../../types'
import SchedulingGroupDaysSetup from '@/tt-widget-views/scheduling-groups/components/SchedulingGroupDaysSetup.vue'
import { SchedulingGroupEntity } from '@/tt-widget-views/scheduling-groups/types'
import {
  siteTaskFeatureFlags,
  SiteTaskFeatureFlags,
} from '@/tt-entity-design/src/components/site-task-schedules/types'
import { AttributeEnum } from '@/tt-entity-design/src/schema-types'
import SiteTaskScheduleTimingsNonRecurrent from './SiteTaskScheduleTimingsNonRecurrent.vue'
import SiteTaskSchedulesExceptions from './SiteTaskSchedulesExceptions.vue'

const tabKeys = {
  CREATE: 'create',
  EXCEPTIONS: 'exceptions',
} as const

type VueWithInjections = VueConstructor<Vue & SiteTaskFeatureFlags>

export default (Vue as VueWithInjections).extend({
  name: 'SiteTaskSchedulesCreateWizard',
  components: {
    StepsBreadcrumbs,
    StepsWizard,
    SiteTaskSchedulesForm,
    SchedulingGroupDaysSetup,
    SiteTaskScheduleTimingsNonRecurrent,
    SiteTaskSchedulesExceptions,
  },
  inject: {
    siteTaskFeatureFlags,
  },
  props: {
    /**
     * Id of the site the entity will belong to.
     */
    clientId: { type: Number, required: true },

    /**
     * Id of the entity to edit
     */
    editingEntityId: { type: Number, default: null },

    /**
     * Description of the entity to edit
     */
    entityDescription: { type: String, default: null },
  },
  data() {
    return {
      savedEntityId: null as number,
      siteTaskType: null as AttributeEnum<'site-task-schedules', 'type'>,
      siteTaskDescription: this.entityDescription,
    }
  },
  computed: {
    showNewUI(): boolean {
      return this.siteTaskFeatureFlags.enableSiteTaskEnhancement
    },
    showExceptions(): boolean {
      return this.siteTaskFeatureFlags.enableCalendarExceptions
    },
    showExtraTab(): boolean {
      return this.showNewUI || this.showExceptions
    },
    isRecurring(): boolean {
      return this.siteTaskType === 'RECURRING'
    },
    entityId(): number {
      return this.editingEntityId ?? this.savedEntityId
    },
    entityName(): string {
      return (
        this.siteTaskDescription ??
        (this.$t(`${this.translationScope}.entity_default_name`) as string)
      )
    },
    group(): SchedulingGroupEntity {
      return {
        id: this.entityId,
        name: this.entityName,
      }
    },
    breadCrumbEditTitle(): string {
      return this.group.name ? `: ${this.group.name}` : ''
    },
    breadcrumbs(): StepsBreadcrumbsItem[] {
      const currentPageTitle = this.editingEntityId
        ? `${this.$t('tt-site-tasks.schedules-form.edit.title')}${
            this.breadCrumbEditTitle
          }`
        : 'tt-site-tasks.schedules-form.create.title'

      return [
        {
          text: 'tt-site-tasks.title',
          onClick: () => this.close(),
        },
        { text: currentPageTitle },
      ]
    },
    tabSlots(): Record<keyof typeof tabKeys, string> {
      const toSlotEntry = ([key, value]) => [key, `tab-${value}`]
      const entries = Object.entries(tabKeys).map(toSlotEntry)

      return Object.fromEntries(entries)
    },
    tabs(): StepsWizardTab[] {
      const extraTab: StepsWizardTab = {
        key: tabKeys.EXCEPTIONS,
        label: this.showNewUI
          ? `${this.translationScope}.tabs.days-setup`
          : `${this.translationScope}.tabs.exceptions`,
        tooltip: `${this.translationScope}.tabs.exceptionsTooltip`,
        icon: 'mdi-clock-outline',
        disabled: () => !this.entityId,
      }

      return [
        {
          key: tabKeys.CREATE,
          label: `${this.translationScope}.tabs.entity-form`,
        },
        ...(this.showExtraTab ? [extraTab] : []),
      ]
    },
    translationScope(): string {
      return 'tt-site-tasks.schedules-wizard'
    },
  },
  async created() {
    if (this.editingEntityId)
      this.siteTaskType = await this.$auth
        .getApi()
        .get('site-task-schedules', this.editingEntityId, {
          fields: [{ attribute: 'type' }],
        })
        // @ts-ignore
        .then(({ type }) => type)
  },
  methods: {
    close(): void {
      /**
       * @event close
       * @type {void}
       */
      this.$emit('close')
    },
    onCreateSuccess(
      { response }: GenericFormSuccess,
      selectTab: (tabKey: string) => void,
    ): void {
      if (this.showNewUI) {
        this.savedEntityId = response.id
        this.siteTaskType = response.type
        this.siteTaskDescription = response.description
        selectTab(tabKeys.EXCEPTIONS)
      } else if (this.showExceptions && response.type === 'RECURRING') {
        // old behaviour
        this.savedEntityId = response.id
        this.siteTaskType = response.type
        this.siteTaskDescription = response.description
        selectTab(tabKeys.EXCEPTIONS)
      } else {
        this.close()
      }
    },
  },
})
</script>

<style scoped>
.site-task-schedules-create-wizard >>> .v-btn {
  text-transform: none;
}

.tab-content {
  background-color: var(--v-toolbar3-base);
  overflow: auto;
}
</style>
