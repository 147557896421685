<template>
  <div>
    <v-menu open-on-hover bottom offset-y>
      <template #activator="{ on }">
        <div v-on="on">
          <tt-attr v-show="hasAssignedUser" name="assignedUser" />
        </div>
      </template>
      <DispatchTasksUserCard />
    </v-menu>

    <DispatchTasksAssignmentGroupResourceAttr
      v-show="!hasAssignedUser"
      :class="{ 'd-flex': !hasAssignedUser }"
    />
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { ItemHookProvider } from '@/tt-widget-entity-flow/types'
import DispatchTasksAssignmentGroupResourceAttr from './DispatchTasksAssignmentGroupResourceAttr.vue'
import DispatchTasksUserCard from './cards/DispatchTasksUserCard.vue'
import { USER_ATTRIBUTE_LIST } from './constants'

export default (Vue as VueConstructor<Vue & ItemHookProvider>).extend({
  name: 'DispatchTasksAssignmentAttr',
  components: {
    DispatchTasksAssignmentGroupResourceAttr,
    DispatchTasksUserCard,
  },
  inject: ['getItemHook'],
  computed: {
    hasAssignedUser(): boolean {
      return !!this.getItemHook().getRawValue('assignedUser.id')
    },
  },
  created() {
    USER_ATTRIBUTE_LIST.forEach((attribute) => {
      this.getItemHook().addAttribute(attribute)
    })
  },
})
</script>
