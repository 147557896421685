<template>
  <json-field
    :name="name"
    :disabled="disabled"
    :class="{ 'hide-spin-buttons': hideInputArrows }"
    :placeholder="placeholder"
  />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { ErrorObject } from 'ajv'
import { CustomRangeErrorTranslation, LeaveManagementApiError } from './types'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'LeavePolicyRangeField',
  inject: ['formHook'],
  props: {
    name: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    responseErrors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideInputArrows: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    fieldValue(): string {
      return this.formHook().getPathValue(this.name)
    },
    isDebouncing(): boolean {
      return this.formHook().state.isDebouncing
    },
    outOfRangeTranslationKey() {
      return 'tt-entity-design.leave-management.range-error'
    },
  },
  watch: {
    async fieldValue(val: number): Promise<void> {
      if (this.isReadyToValidate() && !this.disabled) {
        if (this.isOutRange(val)) {
          return this.registerOutOfRangeError()
        }

        this.clearFieldError()
      }
    },
    responseErrors(errors: LeaveManagementApiError) {
      if (errors) {
        Object.keys(errors)
          .filter((errorKey) => this.name == errorKey)
          .forEach((errorKey) => {
            this.formHook().setCustomError(
              errorKey,
              this.createError(
                errorKey,
                {
                  key: null,
                  param: null,
                },
                errors[errorKey][0],
              ),
            )
          })
      }
    },
  },
  methods: {
    clearFieldError(): void {
      this.formHook().setCustomError(this.name, null)
    },
    createError(
      name: string,
      { key, param }: CustomRangeErrorTranslation,
      apiErrorMessage?: string,
    ): ErrorObject[] {
      return [
        {
          dataPath: `.${name}`,
          keyword: 'required',
          message: apiErrorMessage || this.$t(key, param),
          params: Object,
          schemaPath: '#/required',
        },
      ]
    },
    isOutRange(val: number): boolean {
      return val > this.max || val < this.min
    },
    isReadyToValidate(): boolean {
      return !this.isDebouncing
    },
    registerOutOfRangeError(): void {
      return this.formHook().setCustomError(
        this.name,
        this.createError(this.name, {
          key: this.outOfRangeTranslationKey,
          param: { min: this.min, max: this.max },
        }),
      )
    },
  },
})
</script>
<style scoped>
.hide-spin-buttons >>> input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-spin-buttons >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-spin-buttons >>> input[type='number'] {
  -moz-appearance: textfield;
}
</style>
