<template>
  <div>
    <MapCoordinateSelector
      class="py-2"
      prevent-click
      :default-coordinates="defaultPosition"
      @input="$emit('input', $event)"
    />

    <json-field name="location.name" />
    <json-field name="location.latitude" />
    <json-field name="location.longitude" />
    <json-field name="location.addressLine1" />
    <json-field name="location.addressLine2" />
    <v-row no-gutters>
      <v-col cols="6" class="pr-4">
        <json-field name="location.city" />
      </v-col>
      <v-col cols="6">
        <json-field name="location.state" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6" class="pr-4">
        <json-field name="location.postalCode" />
      </v-col>
      <v-col cols="6">
        <json-field name="location.country" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor, PropType } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'
import { LocationServiceProvider } from './location-form-service'
import { Coordinates } from '@tracktik/tt-geo-proxy'
import MapCoordinateSelector from '@/tt-widget-components/components/map/MapCoordinateSelector.vue'

export default (
  Vue as VueConstructor<Vue & FormHookProvider & LocationServiceProvider>
).extend({
  name: 'AlarmsFormLocationFields',
  components: {
    MapCoordinateSelector,
  },
  inject: ['formHook', 'locationService'],
  props: {
    defaultPosition: {
      type: Object as PropType<Coordinates>,
      default: null,
    },
  },
})
</script>
