<template>
  <v-dialog
    :value="layoutManager.state.alertDialog.state"
    width="400"
    persistent
    @input="close"
  >
    <v-card v-if="payload">
      <div class="pa-6">{{ payload.message }}</div>

      <template v-if="isAlert">
        <v-card-actions>
          <v-spacer />
          <v-btn small text @click="close">
            <span v-text="$t('common.close.btn')"></span>
          </v-btn>
        </v-card-actions>
      </template>

      <template v-if="!isAlert">
        <v-card-actions>
          <v-spacer />
          <v-btn small text color="grey" @click="cancel">
            <span v-text="$t('common.cancel.btn')"></span>
          </v-btn>
          <v-btn small text @click="accept">
            <span v-text="$t('common.ok.btn')"></span>
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import BaseAppLayoutComponent from '@/tt-app-layout/components/BaseAppLayoutComponent'
import { AlertInterface, ConfirmInterface } from '@/tt-app-layout'

export default BaseAppLayoutComponent.extend({
  name: 'AlertLauncher',
  computed: {
    isAlert(): boolean {
      // @ts-ignore -- 'cancel' & 'accept' doesnt exist on AlertInterface
      return !this.payload?.cancel && !this.payload?.accept
    },
    payload(): AlertInterface | ConfirmInterface {
      return this.layoutManager.state.alertDialog.payload
    },
  },
  methods: {
    cancel() {
      // @ts-ignore -- 'cancel' & 'accept' doesnt exist on AlertInterface
      this.payload?.cancel && this.payload.cancel()
      this.close()
    },
    accept() {
      // @ts-ignore -- 'cancel' & 'accept' doesnt exist on AlertInterface
      this.payload?.accept && this.payload.accept()
      this.close()
    },
    close() {
      this.layoutManager.state.alertDialog.state = false
      this.layoutManager.state.alertDialog.payload = null
    },
  },
})
</script>
