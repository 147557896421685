import uniq from 'lodash/uniq'
import Vue from 'vue'

import { ActionMenuItem, ActionMenuName } from '@/tt-app-layout'
import { AppContext } from '@/tt-app-context'

import {
  ActionBarPreset,
  ExtensionInterface,
  ModularItemsInterface,
  ModularManagerInterface,
  ModularManagerState,
  PresetItem,
  PresetTypes,
} from './types'
import { WidgetCategoryModel, WidgetStoreModel } from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'
import { AuthModule } from '@tracktik/tt-authentication'

export * from './types'

const createNewState = (): ModularManagerState =>
  Vue.observable({
    slots: {},
    extensions: [],
    modularItems: [],
    menus: {},
    presets: {},
    jsonSchemaActions: {},
    resourceActionBar: {},
  })

/**
 * Get the resource preset key
 */
const getResourcePresetKey = (resource: Resources, preset: string): string => {
  return `${resource}.presets.${preset}`
}

/**
 * Extension manager
 */
const createModularManager = (): ModularManagerInterface => {
  const state = createNewState()

  /**
   * Add an item to a menu in the modular system
   */
  const addActionMenuItem = (menuName, payload) => {
    if (!state.menus[menuName]) {
      state.menus[menuName] = []
    }
    state.menus[menuName].push(payload)
  }

  const onSuccess = (actions = {}) => {
    return Object.keys(actions)
  }

  const onError = (error) => {
    console.error('Catergory Action Error', error)

    return []
  }

  const getAvailableActions = (
    authModule: AuthModule,
    resource: Resources,
    id: number | string,
  ): Promise<string[]> => {
    return authModule
      .getApi()
      .getActions(resource, id, { includeOperations: true })
      .then(onSuccess, onError)
  }

  return {
    hasItems(name: string): boolean {
      return state.modularItems.some((item) => item.name === name)
    },
    /**
     * If component was installed in slot
     */
    hasExtensions(slotName: string): boolean {
      return !!(state.slots[slotName] ?? false)
    },

    /**
     * Add an item to the modular system
     */
    addItem(name: string, payload: any) {
      state.modularItems.push({ name, ...payload })
    },

    /**
     * Get a preset for a resource
     */
    getResourcePreset(
      resource: Resources,
      presetType: PresetTypes,
      tag?: string,
    ): PresetItem | null {
      const presetKey = getResourcePresetKey(resource, presetType)

      return this.getPreset(presetKey, tag)
    },

    /**
     * Get preset list for resources for a resource
     */
    getResourcePresets(
      resource: Resources,
      presetType: PresetTypes,
      tag?: string,
    ): PresetItem[] | null {
      const presetKey = getResourcePresetKey(resource, presetType)

      return this.getPresets(presetKey, tag)
    },

    /**
     * Get a preset for an attribute
     */
    getResourceAttributePreset(
      resource: Resources,
      presetType: PresetTypes,
      attributeName: string,
      tag?: string,
    ): PresetItem | null {
      const presetKey = this.getResourceAttributePresetKey(
        resource,
        presetType,
        attributeName,
      )

      return this.getPreset(presetKey, tag)
    },
    /**
     * Get a preset, ordered by LEVEL
     */
    getPresets(presetKey: string, tag?: string): PresetItem[] | null {
      if (!this.hasPreset(presetKey, tag)) {
        return null
      }

      const filtered = state.presets[presetKey]
        .sort((item1: PresetItem, item2: PresetItem) => {
          return item1.level > item2.level ? -1 : 1
        })
        .filter((item: PresetItem) => {
          return tag ? tag === item.tag : true
        })

      return filtered && filtered.length ? filtered : null
    },
    /**
     * Get a preset, ordered by LEVEL
     */
    getPreset(presetKey: string, tag?: string): PresetItem | null {
      const presets = this.getPresets(presetKey, tag)

      return presets && presets.length ? presets[0] : null
    },
    /**
     * Add a resource preset
     */
    addResourcePreset(
      resource: Resources,
      preset: PresetTypes,
      payload: PresetItem,
    ) {
      const presetKey = getResourcePresetKey(resource, preset)
      this.addPreset(presetKey, payload)
    },
    /**
     * Add a preset
     */
    addPreset(presetKey: string, payload: PresetItem) {
      if (!state.presets[presetKey]) {
        state.presets[presetKey] = []
      }
      state.presets[presetKey].push({ preset: presetKey, ...payload })
    },
    /**
     * Get the resource attribute preset key
     */
    getResourceAttributePresetKey(
      resource: Resources,
      preset: string,
      attributeName: string,
    ) {
      return `${resource}.presets.${preset}.${attributeName}`
    },
    hasResourcePreset(
      resource: Resources,
      preset: string,
      tag: string = null,
    ): boolean {
      const presetKey = getResourcePresetKey(resource, preset)

      return this.hasPreset(presetKey, tag)
    },
    hasPreset(presetKey: string, tag: string = null): boolean {
      if (!state.presets[presetKey]) {
        return false
      }
      if (!tag) {
        return true
      }

      return (
        state.presets[presetKey].filter((preset: PresetItem) => {
          return preset.tag === tag
        }).length > 0
      )
    },
    /**
     * Add a an action menu item callback for the widgets
     */
    addWidgetActionMenuItem(payload) {
      return addActionMenuItem('WidgetStore.actions', payload)
    },
    /**
     * Add a an action menu item callback for the categories
     */
    addCategoryActionMenuItem(payload) {
      return addActionMenuItem('Categories.actions', payload)
    },
    /**
     * @todo: refactor to not handle DataLab-specific logic. FE-1056
     */
    async getMenuItems(
      appContext: AppContext,
      menuName: ActionMenuName,
      modelContext: WidgetStoreModel | WidgetCategoryModel = null,
      resource: Resources = null,
    ): Promise<ActionMenuItem[]> {
      if (!state.menus[menuName]) {
        return []
      }
      let items = []
      let availableActions = []

      const asyncItems = state.menus[menuName].map(async (item) => {
        if (typeof item === 'function') {
          const response = await item(modelContext)
          if (Array.isArray(response)) {
            items = [...items, ...response]
          } else {
            items.push(response)
          }
        } else {
          items.push(item)
        }
      })
      await Promise.all(asyncItems)

      if (resource) {
        availableActions = modelContext?.meta?.id
          ? await getAvailableActions(
              appContext.authModule,
              resource,
              modelContext.meta.id,
            )
          : []
      }

      return items.filter((item: ActionMenuItem) => {
        // Filter out permissions
        if (item.acl) {
          if (!appContext.authModule.hasPermission(item.acl)) {
            return false
          }
        }
        // Check for asset
        if (item.assert === false) {
          return false
        }
        if (typeof item.assert === 'function') {
          return item.assert({ availableActions })
        }

        return true
      })
    },
    /**
     * Return the modular items
     */
    getItems(name: string): ModularItemsInterface[] {
      return state.modularItems.filter((item) => {
        const isValidName = item.name === name

        const canShow = (): boolean => {
          // Check for asset
          if (item.assert === false) {
            return false
          }
          if (typeof item.assert === 'function') {
            return item.assert()
          }

          return true
        }

        return isValidName && canShow()
      })
    },

    /**
     * Register a extension
     */
    registerExtension(extension: ExtensionInterface) {
      // Extensions
      state.extensions.push(extension)

      // If a slot is defined, we display it.
      if (extension.slots && extension.slots.length) {
        extension.slots.forEach((slot) => {
          if (!state.slots[slot]) {
            state.slots[slot] = []
          }
          state.slots[slot].push(extension)
        })
      }
    },

    /**
     * Set resources preset
     */
    setResourcePresets(
      resource: Resources,
      preset: PresetTypes,
      payload: PresetItem[],
    ) {
      const presetKey = getResourcePresetKey(resource, preset)
      state.presets[presetKey] = [...payload]
    },

    getDynamicResourceJsonSchemaActions(resource: Resources): string[] {
      return state.jsonSchemaActions[resource] ?? []
    },

    hasDynamicResourceJsonSchemaAction(
      resource: Resources,
      action: string,
    ): boolean {
      return state.jsonSchemaActions[resource]?.includes(action) ?? false
    },

    registerDynamicResourceJsonSchemaActions(
      resource: Resources,
      actions: string[],
    ) {
      state.jsonSchemaActions[resource] = uniq([
        ...(state.jsonSchemaActions[resource] || []),
        ...actions,
      ])
    },

    getSlot(slotName) {
      return state.slots[slotName] || []
    },
    // @ts-ignore
    getState() {
      return state
    },
    registerActionBar(
      resource: Resources,
      actionBarPreset: ActionBarPreset,
    ): void {
      state.resourceActionBar[resource] = actionBarPreset
    },
    hasActionBar(resource: Resources): boolean {
      return !!state.resourceActionBar[resource]
    },
    getActionBar(resource: Resources): ActionBarPreset | null {
      return state.resourceActionBar[resource] || null
    },
  }
}

export const modularManager = createModularManager()
