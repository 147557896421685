
import { Resource } from './schema-types'

export type Resources = Resource

/**
 * Contains valid resources + white listed resources
 */
export const Resources = {

  ACCOUNT_KPIS: 'account-kpis',
  ACCOUNTS: 'accounts',
  ADDRESSES: 'addresses',
  ALARM_CONNECTIONS: 'alarm-connections',
  ALARM_ORGANIZATIONS: 'alarm-organizations',
  ALARM_TYPES: 'alarm-types',
  ALARMS: 'alarms',
  APP_LICENSES: 'app-licenses',
  ASSET_CATEGORIES: 'asset-categories',
  ASSET_CATEGORY_TRANSLATIONS: 'asset-category-translations',
  ASSET_CONTACTS: 'asset-contacts',
  ASSET_CUSTOM_ATTRIBUTES: 'asset-custom-attributes',
  ASSET_CUSTOM_FIELDS: 'asset-custom-fields',
  ASSET_FIELDSET_FIELDS: 'asset-fieldset-fields',
  ASSET_FIELDSETS: 'asset-fieldsets',
  ASSET_FILES: 'asset-files',
  ASSET_MANUFACTURERS: 'asset-manufacturers',
  ASSET_PERSONS: 'asset-persons',
  ASSET_RESERVATIONS: 'asset-reservations',
  ASSET_SUPPLIERS: 'asset-suppliers',
  ASSET_TRANSACTION_ITEMS: 'asset-transaction-items',
  ASSET_TRANSACTIONS: 'asset-transactions',
  ASSET_TYPE_CUSTOM_ATTRIBUTES: 'asset-type-custom-attributes',
  ASSET_TYPE_FILES: 'asset-type-files',
  ASSET_TYPE_TO_REPORT_TEMPLATE_ASSIGNMENT: 'asset-type-to-report-template-assignment',
  ASSET_TYPE_TRANSLATIONS: 'asset-type-translations',
  ASSET_TYPES: 'asset-types',
  ASSETS: 'assets',
  AUDIT_LOGS: 'audit-logs',
  BACK_OFFICE_PAY_SETTINGS: 'back-office-pay-settings',
  BACK_OFFICE_SETTINGS: 'back-office-settings',
  BILL_ITEMS: 'bill-items',
  BILLABLE_ITEMS: 'billable-items',
  BILLING_ADDRESSES: 'billing-addresses',
  BILLING_ADHOC_ITEMS: 'billing-adhoc-items',
  BREAK_RULE_COMPLIANCE_LOGS: 'break-rule-compliance-logs',
  BREAK_RULE_CONDITIONS: 'break-rule-conditions',
  BREAK_RULE_EXCEPTIONS: 'break-rule-exceptions',
  BREAK_RULE_RELATIONS: 'break-rule-relations',
  BREAK_RULES: 'break-rules',
  BREAK_SESSIONS: 'break-sessions',
  CALENDAR_EXCEPTION_GROUP_DATES: 'calendar-exception-group-dates',
  CALENDAR_EXCEPTIONS: 'calendar-exceptions',
  CHECKPOINT_QUESTION_ANSWERS: 'checkpoint-question-answers',
  CHECKPOINT_SCANS: 'checkpoint-scans',
  CHECKPOINT_TOUR_ASSIGNMENTS: 'checkpoint-tour-assignments',
  CHECKPOINT_TOUR_SCHEDULE_OCCURRENCES: 'checkpoint-tour-schedule-occurrences',
  CHECKPOINT_TOUR_SCHEDULES: 'checkpoint-tour-schedules',
  CHECKPOINT_TOUR_SESSION_COMMENTS: 'checkpoint-tour-session-comments',
  CHECKPOINT_TOUR_SESSIONS: 'checkpoint-tour-sessions',
  CHECKPOINT_TOURS: 'checkpoint-tours',
  CHECKPOINTS: 'checkpoints',
  CITIES: 'cities',
  CLIENT_BILLING_SETTINGS: 'client-billing-settings',
  CLIENT_INVOICE_SPLIT_SETTINGS: 'client-invoice-split-settings',
  CLIENT_USERS: 'client-users',
  CLIENTS: 'clients',
  COMMENTS: 'comments',
  CONFLICTS: 'conflicts',
  CONTACTS: 'contacts',
  CONTRACT_BILLING_CALENDAR_DATES: 'contract-billing-calendar-dates',
  CONTRACT_BILLING_CALENDARS: 'contract-billing-calendars',
  CONTRACT_OCCURRENCE_LOGS: 'contract-occurrence-logs',
  CONTRACT_OCCURRENCES: 'contract-occurrences',
  CONTRACT_RECURRENT_FIXED_SERVICES: 'contract-recurrent-fixed-services',
  CONTRACT_SERVICE_MODELS: 'contract-service-models',
  CONTRACTS: 'contracts',
  CREDIT_MEMOS: 'credit-memos',
  DATA_VIEW_CATEGORIES: 'data-view-categories',
  DATA_VIEW_MODULAR_HOOKS: 'data-view-modular-hooks',
  DATA_VIEWS: 'data-views',
  DEPARTMENTS: 'departments',
  DISPATCH_CUSTOM_LOCATIONS: 'dispatch-custom-locations',
  DISPATCH_ETA_LOGS: 'dispatch-eta-logs',
  DISPATCH_SLA_TIMER_SETTINGS: 'dispatch-sla-timer-settings',
  DISPATCH_SLAS: 'dispatch-slas',
  DISPATCH_TASK_LOGS: 'dispatch-task-logs',
  DISPATCH_TASKS: 'dispatch-tasks',
  EMERGENCY_CONTACT_ACCOUNT_ASSIGNMENTS: 'emergency-contact-account-assignments',
  EMERGENCY_CONTACTS: 'emergency-contacts',
  EMPLOYEE_ACCOUNT_ASSIGNMENT_PAY_SETTINGS: 'employee-account-assignment-pay-settings',
  EMPLOYEE_ACCOUNT_ASSIGNMENTS: 'employee-account-assignments',
  EMPLOYEE_ACCOUNT_BANS: 'employee-account-bans',
  EMPLOYEE_AVAILABILITIES: 'employee-availabilities',
  EMPLOYEE_BILL_PAY_MATRIX_RATE_SETTINGS: 'employee-bill-pay-matrix-rate-settings',
  EMPLOYEE_CLASS_PAY_SETTINGS: 'employee-class-pay-settings',
  EMPLOYEE_CLASSES: 'employee-classes',
  EMPLOYEE_EXTERNAL_ID_FIELDS: 'employee-external-id-fields',
  EMPLOYEE_EXTERNAL_IDS: 'employee-external-ids',
  EMPLOYEE_EXTRA_REGIONS: 'employee-extra-regions',
  EMPLOYEE_PAY_SETTINGS: 'employee-pay-settings',
  EMPLOYEE_PREMIUM_ASSIGNMENTS: 'employee-premium-assignments',
  EMPLOYEE_ROLES: 'employee-roles',
  EMPLOYEE_SENIORITIES: 'employee-seniorities',
  EMPLOYEE_SKILLS: 'employee-skills',
  EMPLOYEES: 'employees',
  EMPLOYMENT_PROFILES: 'employment-profiles',
  EMPLOYMENT_TERMINATION_REASONS: 'employment-termination-reasons',
  ENTITY_LABEL_AVAILABILITY_TYPES: 'entity-label-availability-types',
  ENTITY_LABELS: 'entity-labels',
  EVENT_ACTIVITIES: 'event-activities',
  EVENTS_SUBSCRIPTION_LOGS: 'events-subscription-logs',
  EVENTS_SUBSCRIPTIONS: 'events-subscriptions',
  EXCEPTION_TYPE_DATES: 'exception-type-dates',
  EXCEPTION_TYPE_GROUP_ITEMS: 'exception-type-group-items',
  EXCEPTION_TYPE_GROUPS: 'exception-type-groups',
  EXCEPTION_TYPES: 'exception-types',
  FILES: 'files',
  FILTER_RULE_SET_ASSETS: 'filter-rule-set-assets',
  FILTER_RULE_SET_REPORTS: 'filter-rule-set-reports',
  FILTER_RULE_SETS: 'filter-rule-sets',
  GEO_COUNTRIES: 'geo-countries',
  GEO_COUNTRY_SUBDIVISIONS: 'geo-country-subdivisions',
  GEO_FENCES: 'geo-fences',
  GEO_LOCATIONS: 'geo-locations',
  GUID_RESOURCES: 'guid-resources',
  HAZARD_ALERTS: 'hazard-alerts',
  HOLIDAY_DATES: 'holiday-dates',
  HOLIDAY_GROUPS: 'holiday-groups',
  HOLIDAYS: 'holidays',
  INVOICE_BATCHES: 'invoice-batches',
  INVOICE_LINE_TAXES: 'invoice-line-taxes',
  INVOICE_LINES: 'invoice-lines',
  INVOICES: 'invoices',
  LEAVE_ACCRUALS: 'leave-accruals',
  LEAVE_BALANCES: 'leave-balances',
  LEAVE_MANAGEMENT_ADJUSTMENTS: 'leave-management-adjustments',
  LEAVE_POLICIES: 'leave-policies',
  LEAVE_POLICY_BALANCES: 'leave-policy-balances',
  LEAVE_POLICY_CARRY_OVER_LOGS: 'leave-policy-carry-over-logs',
  LEAVE_POLICY_ITEMS: 'leave-policy-items',
  LEAVE_REQUEST_LOGS: 'leave-request-logs',
  LEAVE_REQUEST_VACATED_SHIFTS: 'leave-request-vacated-shifts',
  LEAVE_REQUESTS: 'leave-requests',
  LEAVE_TYPES: 'leave-types',
  LONE_WORKER_CHECK_IN_LOGS: 'lone-worker-check-in-logs',
  LONE_WORKER_CHECK_IN_TICKETS: 'lone-worker-check-in-tickets',
  LONE_WORKER_CHECK_INS: 'lone-worker-check-ins',
  LONE_WORKER_PHONE_CALLS: 'lone-worker-phone-calls',
  LONE_WORKER_PHONES: 'lone-worker-phones',
  LONE_WORKER_SCHEDULE_CHECK_INS: 'lone-worker-schedule-check-ins',
  LONE_WORKER_TIME_KEEPING_SETTINGS: 'lone-worker-time-keeping-settings',
  MASTER_SERVICE_TYPES: 'master-service-types',
  ME: 'me',
  MESSAGE_BOARD_POST_ACKNOWLEDGEMENTS: 'message-board-post-acknowledgements',
  MESSAGE_BOARD_POST_CATEGORIES: 'message-board-post-categories',
  MESSAGE_BOARD_POST_RECIPIENTS: 'message-board-post-recipients',
  MESSAGE_BOARD_POSTS: 'message-board-posts',
  MOBILE_DEVICE_GEO_LOCATIONS: 'mobile-device-geo-locations',
  MOBILE_DEVICES: 'mobile-devices',
  MOBILE_RUNSHEET_EXCEPTION_TYPES: 'mobile-runsheet-exception-types',
  MOBILE_RUNSHEET_GROUPS: 'mobile-runsheet-groups',
  MOBILE_RUNSHEET_OCCURRENCES: 'mobile-runsheet-occurrences',
  MOBILE_RUNSHEET_SESSIONS: 'mobile-runsheet-sessions',
  MOBILE_RUNSHEET_SESSIONS_LOGS: 'mobile-runsheet-sessions-logs',
  MOBILE_RUNSHEETS: 'mobile-runsheets',
  MOBILE_SCHEDULE_EXCEPTION_TYPES: 'mobile-schedule-exception-types',
  MOBILE_SCHEDULE_GROUPS: 'mobile-schedule-groups',
  MOBILE_SCHEDULE_OCCURRENCE_LOGS: 'mobile-schedule-occurrence-logs',
  MOBILE_SCHEDULE_OCCURRENCE_REASSIGNMENTS: 'mobile-schedule-occurrence-reassignments',
  MOBILE_SCHEDULE_OCCURRENCES: 'mobile-schedule-occurrences',
  MOBILE_SCHEDULES: 'mobile-schedules',
  MODEL_TRANSLATIONS: 'model-translations',
  NOTES: 'notes',
  OAUTH2_ACCESS_TOKENS: 'oauth2-access-tokens',
  OAUTH2_AUTH_CODES: 'oauth2-auth-codes',
  OAUTH2_CLIENTS: 'oauth2-clients',
  OAUTH2_REFRESH_TOKENS: 'oauth2-refresh-tokens',
  OPEN_SHIFT_REQUESTS: 'open-shift-requests',
  OPERATION_CENTER_ESCALATION_POLICIES: 'operation-center-escalation-policies',
  OPERATION_CENTER_ESCALATION_TICKETS: 'operation-center-escalation-tickets',
  OPERATION_CENTER_OPERATORS: 'operation-center-operators',
  OPERATION_CENTER_REGIONS: 'operation-center-regions',
  OPERATION_CENTERS: 'operation-centers',
  OVERTIME_RULE_ITEMS: 'overtime-rule-items',
  OVERTIME_RULES: 'overtime-rules',
  PATROL_RECORDINGS: 'patrol-recordings',
  PAY_AND_BILL_TRANSACTIONS: 'pay-and-bill-transactions',
  PAYMENT_METHODS: 'payment-methods',
  PAYMENTS: 'payments',
  PAYROLL_ADHOCS: 'payroll-adhocs',
  PAYROLL_CODES: 'payroll-codes',
  PAYROLL_DEDUCTIONS: 'payroll-deductions',
  PAYROLL_PAYRUN_ITEMS: 'payroll-payrun-items',
  PAYROLL_PAYRUNS: 'payroll-payruns',
  PAYROLL_SCHEDULE_OCCURRENCES: 'payroll-schedule-occurrences',
  PAYROLL_SCHEDULES: 'payroll-schedules',
  PHONE_CALL_LOGS: 'phone-call-logs',
  POSITION_BILL_SETTINGS: 'position-bill-settings',
  POSITION_BILLING_SETTINGS: 'position-billing-settings',
  POSITION_EXPENSE_REIMBURSEMENTS: 'position-expense-reimbursements',
  POSITION_PAY_SETTINGS: 'position-pay-settings',
  POSITION_PAYROLL_SETTINGS: 'position-payroll-settings',
  POSITION_PREMIUM_ASSIGNMENTS: 'position-premium-assignments',
  POSITION_PREMIUMS: 'position-premiums',
  POSITION_PRICE_MATRIX_RULES: 'position-price-matrix-rules',
  POSITION_SKILLS: 'position-skills',
  POSITIONS: 'positions',
  POST_ORDER_ACKNOWLEDGEMENTS: 'post-order-acknowledgements',
  POST_ORDER_SUBJECTS: 'post-order-subjects',
  POST_ORDERS: 'post-orders',
  PRICE_TIERS: 'price-tiers',
  PRIVACY_POLICIES: 'privacy-policies',
  PROCESSED_PAYROLL_PAYRUNS: 'processed-payroll-payruns',
  REGIONS: 'regions',
  REPORT_CONDITION_SET_ACTIONS: 'report-condition-set-actions',
  REPORT_CONDITION_SETS: 'report-condition-sets',
  REPORT_CONDITIONS: 'report-conditions',
  REPORT_FIELDS: 'report-fields',
  REPORT_FLAG_CATEGORIES: 'report-flag-categories',
  REPORT_FLAG_GROUP_ASSIGNMENTS: 'report-flag-group-assignments',
  REPORT_FLAG_GROUPS: 'report-flag-groups',
  REPORT_FLAG_TEMPLATE_FIELD_GROUP_FIELDS: 'report-flag-template-field-group-fields',
  REPORT_FLAG_TEMPLATE_FIELD_GROUPS: 'report-flag-template-field-groups',
  REPORT_FLAG_TEMPLATE_FIELDS: 'report-flag-template-fields',
  REPORT_FLAG_TEMPLATES: 'report-flag-templates',
  REPORT_FLAGS: 'report-flags',
  REPORT_LIBRARIES: 'report-libraries',
  REPORT_LIBRARY_FIELDS: 'report-library-fields',
  REPORT_PRE_DEFINED_FIELD_TEMPLATES: 'report-pre-defined-field-templates',
  REPORT_TEMPLATE_FIELD_GROUP_FIELDS: 'report-template-field-group-fields',
  REPORT_TEMPLATE_FIELD_GROUPS: 'report-template-field-groups',
  REPORT_TEMPLATE_FIELDS: 'report-template-fields',
  REPORT_TEMPLATES: 'report-templates',
  REPORTS: 'reports',
  REVENUE_PROCESSOR_OCCURRENCES: 'revenue-processor-occurrences',
  ROLE_ACCESS_POLICIES: 'role-access-policies',
  ROLE_PERMISSIONS: 'role-permissions',
  ROLES: 'roles',
  SCHEDULE_OFF_PERIODS: 'schedule-off-periods',
  SCHEDULERS: 'schedulers',
  SHIFT_LOGS: 'shift-logs',
  SHIFT_NOTES: 'shift-notes',
  SHIFT_PERIOD_SUMMARIES: 'shift-period-summaries',
  SHIFT_PROPOSAL_QUEUE_ITEMS: 'shift-proposal-queue-items',
  SHIFT_SLICES: 'shift-slices',
  SHIFT_SUMMARIES: 'shift-summaries',
  SHIFT_TEMPLATE_SETTINGS: 'shift-template-settings',
  SHIFT_TEMPLATES: 'shift-templates',
  SHIFTS: 'shifts',
  SITE_LOCATIONS: 'site-locations',
  SITE_TASK_OCCURRENCE_SESSION_LOGS: 'site-task-occurrence-session-logs',
  SITE_TASK_OCCURRENCE_SESSIONS: 'site-task-occurrence-sessions',
  SITE_TASK_OCCURRENCES: 'site-task-occurrences',
  SITE_TASK_SCHEDULE_EXCEPTION_TYPES: 'site-task-schedule-exception-types',
  SITE_TASK_SCHEDULE_TIMINGS: 'site-task-schedule-timings',
  SITE_TASK_SCHEDULES: 'site-task-schedules',
  SKILL_CATEGORIES: 'skill-categories',
  SKILLS: 'skills',
  SMS_LOGS: 'sms-logs',
  SYSTEM_EXCEPTION_TICKET_INSTRUCTIONS: 'system-exception-ticket-instructions',
  SYSTEM_EXCEPTION_TICKETS: 'system-exception-tickets',
  SYSTEM_SETTINGS: 'system-settings',
  SYSTEM_USAGE_LOGS: 'system-usage-logs',
  TASK_PRICE_TIER_BILL_SETTINGS: 'task-price-tier-bill-settings',
  TASK_PRICE_TIERS: 'task-price-tiers',
  TASK_SITE_INSTRUCTIONS: 'task-site-instructions',
  TASK_SITE_SETTINGS: 'task-site-settings',
  TASK_TYPE_SKILLS: 'task-type-skills',
  TASK_TYPES: 'task-types',
  TAX_CLASS_ITEMS: 'tax-class-items',
  TAX_CLASSES: 'tax-classes',
  TAX_LOCATION_OVERRIDE_GROUPS: 'tax-location-override-groups',
  TAX_LOCATION_OVERRIDE_MAPPINGS: 'tax-location-override-mappings',
  TAX_LOCATIONS: 'tax-locations',
  TENANTS: 'tenants',
  TWILIO_ACCOUNTS: 'twilio-accounts',
  UNITS: 'units',
  USER_LOGIN_LOGS: 'user-login-logs',
  USER_NOTIFICATION_SETTINGS: 'user-notification-settings',
  USER_ROLES: 'user-roles',
  USERS: 'users',
  VEHICLES: 'vehicles',
  VENDORS: 'vendors',
  WHITELISTED_IPS: 'whitelisted-ips',
  WORK_SESSIONS: 'work-sessions',
  WORKFLOW_INSTANCE_LOGS: 'workflow-instance-logs',
  WORKFLOW_INSTANCE_TIMER_LOGS: 'workflow-instance-timer-logs',
  WORKFLOW_INSTANCE_TIMERS: 'workflow-instance-timers',
  WORKFLOW_INSTANCES: 'workflow-instances',
  WORKFLOW_STATUS_ACTIONS: 'workflow-status-actions',
  WORKFLOW_STATUS_TRANSITION_FORMS: 'workflow-status-transition-forms',
  WORKFLOW_STATUS_TRANSITIONS: 'workflow-status-transitions',
  WORKFLOW_STATUSES: 'workflow-statuses',
  WORKFLOW_TIMERS: 'workflow-timers',
  WORKFLOWS: 'workflows',
  ZONE_CLIENTS: 'zone-clients',
  ZONES: 'zones',
} as const

