<template>
  <v-card>
    <PageTitleBar :title="carryOverLabel">
      <template #default>
        <v-btn small icon @click="$emit('back')">
          <v-icon small>arrow_back</v-icon>
        </v-btn>
      </template>
    </PageTitleBar>

    <v-toolbar v-if="!loading" class="text-capitalize py-0 px-6" flat short>
      <v-tabs v-model="selectedTab" slider-color="ttPrimary">
        <v-tab
          v-for="tab in tabs"
          :key="tab.resource"
          class="text-capitalize px-2 mr-6"
          active-class="active-icon"
        >
          <span class="pr-1" v-text="tab.title" />
          <v-chip small link pill color="ttIndigo" text-color="#405CE7">
            <span v-text="tab.amount" />
          </v-chip>
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-if="!loading" v-model="selectedTab">
      <v-tab-item v-for="tab in tabs" :key="tab.resource" eager>
        <WidgetContainer style="height: 400px">
          <WidgetFactory
            v-if="isReady"
            :widget="tab.widget"
            dark
            @records-loaded="updateRecordCounter($event, tab.resource)"
          />
        </WidgetContainer>
      </v-tab-item>
    </v-tabs-items>

    <v-skeleton-loader
      v-if="loading"
      type="table-heading, list-item-two-line, image"
    >
    </v-skeleton-loader>

    <v-alert
      v-if="hasPendingLeaveRequests"
      class="ma-2"
      color="bg-orange1"
      :style="`color: ${warningColor.text}`"
    >
      <div class="d-flex">
        <v-icon :color="warningColor.text" class="mr-4"> mdi-alert </v-icon>
        <span v-text="$t(carryOverAlert)" />
      </div>
    </v-alert>

    <v-divider></v-divider>

    <v-card-actions class="d-flex">
      <v-spacer />
      <v-btn text color="ttPrimary" @click="closeDialog">
        <span v-text="cancelLabel" />
      </v-btn>
      <v-btn
        :disabled="hasPendingLeaveRequests"
        text
        color="ttPrimary"
        @click="submitCarryover"
      >
        <span v-text="carryOverLabel" />
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Resources } from '@/tt-entity-design/src/types'
import {
  DataTableWidgetModel,
  Filter,
  ListWidgetModel,
  RelationListResource,
} from '@/tt-widget-components'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import {
  UiChipColor,
  ServiceLevelImpactColors,
} from '@/tt-widget-views/leave-management/types'
import { Filter as APIFilter } from 'tracktik-sdk/lib/common/entity-collection'

import LeavePolicyItemsCarryoverColumns from './LeavePolicyItemsCarryoverColumns'

import Vue, { PropType } from 'vue'
import { UnsubscribeFunction } from '@/tt-event-manager'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'

type CarryoverDialogTab = {
  title: string
  amount: number
  resource: Resources
  widget: ListWidgetModel | DataTableWidgetModel
  skipTab?: boolean
}

export default Vue.extend({
  name: 'LeavePoliciesCarryoverDialog',
  props: {
    action: {
      type: String,
      required: true,
    },
    leavePolicy: {
      type: Number,
      required: true,
    },
    itemHook: {
      type: Object as PropType<EntityItemHook>,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: 0,
      pendingLeaveRequestsCounter: 0,
      policyItemsCounter: 0,
      loading: true,
      assignedEmployees: [],
      unsubscribeFn: null as UnsubscribeFunction,
    }
  },
  computed: {
    carryOverLabel(): string {
      return this.$t(
        'tt-entity-design.leave-management.leave-policies.carryover',
      )
    },
    carryOverAlert(): string {
      return `tt-entity-design.leave-management.leave-policies.carryover-warning`
    },
    cancelLabel(): string {
      return this.$t('common.cancel.btn')
    },
    hasPendingLeaveRequests(): boolean {
      return this.pendingLeaveRequestsCounter > 0
    },
    isReady(): boolean {
      return this.$appContext.isReady()
    },
    assignedEmployeesFilter(): Filter & APIFilter {
      return {
        attribute: 'employee',
        operator: 'IN',
        value: this.assignedEmployees.join(','),
      }
    },
    pendingStatusFilter(): Filter & APIFilter {
      return {
        attribute: 'status',
        operator: 'IN',
        value: 'PENDING',
      }
    },
    previousPeriodFilter(): Filter & APIFilter {
      return {
        attribute: 'endedOn',
        operator: 'BEFORE',
        value: this.itemHook.getRawValue('startPeriodDate'),
      }
    },
    carryOverEnabledFilter(): Filter & APIFilter {
      return {
        attribute: 'carryOver',
        operator: 'EQUAL',
        value: true,
      }
    },
    pendingLeaveRequestsWidget(): ListWidgetModel {
      return {
        is: 'ListWidget',
        title: '',
        query: {
          resource: Resources.LEAVE_REQUESTS,
          filters: [
            this.pendingStatusFilter,
            this.previousPeriodFilter,
            this.assignedEmployeesFilter,
          ],
        },
        allowActions: true,
        listItem: {
          is: 'LeavePoliciesPendingLeaveRequestInfo',
        },
      }
    },
    policyItemsEligibleToCarryoverWidget(): DataTableWidgetModel {
      return {
        is: 'DataTableWidget',
        title: '',
        query: {
          resource: Resources.LEAVE_POLICY_ITEMS,
          relationListResource: this.relationListResource,
          filters: [this.carryOverEnabledFilter],
        },
        component: {
          columns: LeavePolicyItemsCarryoverColumns,
        },
      }
    },
    relationListResource(): RelationListResource {
      return {
        resource: Resources.LEAVE_POLICIES,
        id: this.leavePolicy,
        attribute: 'leavePolicyItems',
      }
    },
    pendingLeaveRequestTab(): CarryoverDialogTab {
      return {
        title: this.$t('res.leave-requests.label'),
        amount: this.pendingLeaveRequestsCounter,
        resource: Resources.LEAVE_REQUESTS,
        widget: this.pendingLeaveRequestsWidget,
      }
    },
    policyItemsToCarryoverTab(): CarryoverDialogTab {
      return {
        title: this.carryOverLabel,
        amount: this.policyItemsCounter,
        resource: Resources.LEAVE_POLICY_ITEMS,
        widget: this.policyItemsEligibleToCarryoverWidget,
      }
    },
    tabs(): CarryoverDialogTab[] {
      return [
        ...this.skipIfNoPendingRequests(this.pendingLeaveRequestTab),
        this.policyItemsToCarryoverTab,
      ]
    },
    warningColor(): UiChipColor {
      return ServiceLevelImpactColors.MEDIUM_HIGH
    },
  },
  created() {
    this.fetchAssignedEmployees()
    this.unsubscribeFn = this.$appContext.eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ resource }) => {
        if (resource === Resources.LEAVE_REQUESTS) {
          this.fetchPendingLeaveRequestsByEmployeeList(this.assignedEmployees)
        }
      },
    )
  },
  methods: {
    setLoadingState(value: boolean): void {
      this.loading = value
    },
    skipIfNoPendingRequests(tab: CarryoverDialogTab): CarryoverDialogTab[] {
      return this.hasPendingLeaveRequests ? [tab] : []
    },
    setPendingLeaveRequestsCounter(value: number) {
      this.pendingLeaveRequestsCounter = value
    },
    setPolicyItemsCounter(value: number) {
      this.policyItemsCounter = value
    },
    closeDialog() {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG_CLOSE,
      )
    },
    fetchAssignedEmployees() {
      this.$auth
        .getApi()
        .get(Resources.LEAVE_POLICIES, this.leavePolicy, {
          fields: [{ attribute: 'employees' }],
        })
        .then((leavePolicy: { employees: [{ id: number }] }) => {
          this.assignedEmployees = leavePolicy.employees.map(
            (employee) => employee.id,
          )
          this.fetchPendingLeaveRequestsByEmployeeList(this.assignedEmployees)
        })
    },
    fetchPendingLeaveRequestsByEmployeeList(employeesIds: number[]) {
      this.setLoadingState(true)

      const employeesFilter: APIFilter = {
        attribute: 'employee',
        operator: 'IN',
        value: employeesIds.join(','),
      }

      this.$auth
        .getApi()
        .aggregate(Resources.LEAVE_REQUESTS, {
          measures: [{ attribute: 'id', operation: 'COUNT', alias: 'count' }],
          filters: [
            this.pendingStatusFilter,
            this.previousPeriodFilter,
            employeesFilter,
          ],
        })
        .then((leaveRequests) => {
          const leaveRequestCount = leaveRequests[0].data['count']
          this.setPendingLeaveRequestsCounter(leaveRequestCount)
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },
    updateRecordCounter($event, resource: Resources) {
      if (resource === Resources.LEAVE_POLICY_ITEMS) {
        this.setPolicyItemsCounter($event.count ?? 0)
      }
    },
    showCarryoverSuccessMessage() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
        message: this.$t('common.operation_successful'),
      })
    },
    showCarryoverFailureMessage(message: string) {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message,
      })
    },
    submitCarryover() {
      this.$appContext.entityServices.persister
        .executeEntityAction(
          Resources.LEAVE_POLICIES,
          this.action,
          this.leavePolicy,
        )
        .then(() => {
          this.showCarryoverSuccessMessage()
          this.closeDialog()
        })
        .catch((error) => {
          this.$crash.captureException(error)
          this.showCarryoverFailureMessage(error.message)
        })
    },
  },
})
</script>
