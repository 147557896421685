<template>
  <ActionBarPreset :actions="actions" />
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

import { ActiveWindowProvider, LayoutWindowEvent } from '@/tt-app-layout'
import { ResourceTranslator } from '@/tt-widget-entity-flow/ResourceTranslator'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'

import { Resources } from '../../types'
import ActionBarPreset from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset.vue'
import { ViewAction } from '@/tt-widget-entity-flow/components/action-bar/ActionBarPreset'
import { getEditFormState } from '../../tools/helpers/form-state-generator'
import {
  EntityActionIntentInterface,
  EntityEditIntentInterface,
} from '@/tt-widget-entity-flow'
import { FieldErrorRule } from '@tracktik/tt-json-schema-form'

type VueWithInjections = VueConstructor<
  Vue & { getItemHook: () => EntityItemHook } & ActiveWindowProvider
>

export default (Vue as VueWithInjections).extend({
  name: 'AlarmsActionBar',
  components: {
    ActionBarPreset,
  },
  inject: ['activeWindow', 'getItemHook'],
  computed: {
    actions(): ViewAction[] {
      const openFormAction = async (actionName: string) => {
        const options: Pick<EntityActionIntentInterface, 'formOptions'> = {
          formOptions: { fieldErrorRule: FieldErrorRule.ONLY_TOUCHED },
        }
        const payload = await this.getItemHook().getActionFormAsPage(
          actionName,
          options,
        )

        this.activeWindow.next(payload)
      }

      return [
        {
          icon: 'mdi-archive',
          title: ResourceTranslator.translateActionLabel(
            Resources.ALARMS,
            'archive',
          ),
          onClick: () => openFormAction('archive'),
        },
        {
          icon: 'mdi-cancel',
          title: ResourceTranslator.translateActionLabel(
            Resources.ALARMS,
            'cancel',
          ),
          onClick: () => openFormAction('cancel'),
        },
        {
          icon: 'mdi-note-plus-outline',
          title: ResourceTranslator.translateActionLabel(
            Resources.ALARMS,
            'add-note',
          ),
          onClick: () => openFormAction('add-note'),
        },
        {
          icon: 'mdi-pencil-outline',
          title: this.$t('common.edit.btn'),
          onClick: async () => this.openTasksForm(),
        },
      ]
    },
  },
  methods: {
    async openTasksForm(): Promise<void> {
      const formConfig: EntityEditIntentInterface = {
        resourceName: Resources.ALARMS,
        title: '',
        entityId: this.getItemHook().getEntityId(),
        itemHook: this.getItemHook(),
        formOptions: {
          fieldErrorRule: FieldErrorRule.ONLY_TOUCHED,
          requiredFieldLabelTransformer: null,
        },
      }

      const payload = await getEditFormState(this.$appContext, formConfig)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.SIDE_SHEET_ADD_PAGE, {
        is: 'GenericFormPage',
        title: this.$t('tt-entity-design.alarms.edit-alarm'),
        props: { payload },
      })
    },
  },
})
</script>
