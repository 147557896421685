<template>
  <div class="flex--column">
    <!-- Hidden component to collect the required fields -->
    <EntityViewAttributeCollector
      v-if="!hook.isReadyToFetch"
      v-show="false"
      v-slot="{ itemHook }"
      :resource-name="hook.resource"
      @input="setFields"
      @action="loadActions"
    >
      <component
        :is="listItem.is"
        v-bind="listItem.props"
        :key="itemHook.entityReference.entityId"
      />
    </EntityViewAttributeCollector>

    <WidgetWrapper
      v-if="hook.resource && hook.isInitialized && listItem.is"
      class="list-widget"
      v-bind="$props"
      @rendered="onWidgetRendered"
    >
      <template #default>
        <VerticalStackCard v-slot="{ clientWidth, clientHeight }">
          <div
            class="flex-column"
            :class="clientWidth && clientHeight ? 'd-flex' : 'd-none'"
            style="height: 100%; width: 100%"
          >
            <EntityLooper
              ref="overflow-container"
              v-slot="{ itemHook, index }"
              :entities="hook.entities"
              :resource-name="hook.resource"
              :actions="hook.actions"
              style="overflow-y: scroll"
              class="hide-scroll-in-print-view fill-height"
            >
              <div
                :key="itemHook.getEntityId()"
                class="list-item-div"
                :class="{
                  selected: isRecordSelected(itemHook.getEntityId()),
                  'cursor-pointer': !isPreviewDisabled,
                }"
                @click="click(itemHook)"
              >
                <component
                  :is="listItem.is"
                  v-bind="{ index: formattedIndex(index), ...listItem.props }"
                />
                <v-divider class="mx-6" />
              </div>
            </EntityLooper>

            <div class="list-footer">
              <v-divider class="px-3" />
              <ListPagination
                v-if="!hook.loading && hook.state.totalEntities"
                :container-type="container.type"
                :width="clientWidth"
                v-bind="{ ...pagination }"
                class="hide-in-print-view"
                @offsetUpdated="setOffset"
                @limitUpdated="setLimit"
              />
            </div>
          </div>
        </VerticalStackCard>
      </template>

      <template #loader>
        <WidgetLoader :widget="hook.widget">
          <div class="fill-height">
            <v-skeleton-loader
              v-for="index in 8"
              :key="index"
              type="list-item-avatar-three-line"
            />
          </div>
        </WidgetLoader>
      </template>
    </WidgetWrapper>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import xor from 'lodash/xor'

import BaseWidget from '@/tt-widget-components/components/BaseWidget'
import ListPagination from '@/tt-widget-components/components/ListPagination.vue'
import { ActiveWindow } from '@/tt-app-layout'
import { CollectionWidgetEventNames } from '@/tt-widget-components/types'
import { EntityItemHook } from '@/tt-widget-entity-flow/EntityItemHook'
import type { ResizeEventPayload } from '@/tt-widget-components/components/ResizeObserver/ResizeObserver'
import {
  WidgetContainerInterface,
  WidgetContainerType,
} from '@/tt-widget-factory'

import ListWidgetHook from './ListWidgetHook'
import { ListWidgetEventNames } from './types'

type WidgetInstance = {
  activeWindow?: ActiveWindow
  container: WidgetContainerInterface
}

export type ListWidgetOnSelectCallback = (
  hook: ListWidgetHook,
  itemHook: EntityItemHook,
  widgetInstance: WidgetInstance,
) => void

const canPreviewInSameContainer = ({ container }: WidgetInstance) => {
  const { DASHBOARD_CELL, STANDALONE, DASHBOARD_TAB } = WidgetContainerType

  return (
    !container ||
    ![DASHBOARD_CELL, STANDALONE, DASHBOARD_TAB].includes(container.type)
  )
}

// @TODO: To review, EntityItemHook should not handle the dispatch of the preview
const previewItem: ListWidgetOnSelectCallback = (
  hook,
  itemHook,
  widgetInstance,
) => {
  if (hook.widget.disableResourcePreviewOnClick) return

  if (canPreviewInSameContainer(widgetInstance)) {
    itemHook.dispatchPreview(
      {
        activeWindow: widgetInstance.activeWindow ?? null,
      },
      false,
    )
  } else {
    itemHook.dispatchPreview({}, false)
  }
}

export default BaseWidget.extend({
  name: 'ListWidget',
  data() {
    return {
      paginationWidth: 0,
    }
  },
  inject: {
    activeWindow: { default: null },
  },
  components: {
    ListPagination,
  },
  props: {
    hook: {
      type: Object as PropType<ListWidgetHook>,
      required: true,
    },
    onSelect: {
      type: Function as PropType<ListWidgetOnSelectCallback>,
      default: previewItem,
    },
    selectedRecords: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  /**
   * List widget supports customization of the component that handles each
   * item through the `listItem.is` prop in the widget's configuration.
   * The entities may be injected in such components.
   */
  provide(): { entities: Record<string, any>[] } {
    return {
      entities: this.hook.entities,
    }
  },
  methods: {
    setWidth({ clientWidth }: ResizeEventPayload) {
      this.paginationWidth = clientWidth
    },
    moveToTop() {
      ;(this.$refs['overflow-container'] as Vue).$el.scrollTop = 0
    },
    click(itemHook: EntityItemHook) {
      if (!this.multiple && this.isRecordSelected(itemHook.getEntityId()))
        return
      const valueToEmit = this.multiple
        ? xor(this.selectedRecords, [itemHook.getEntityId()])
        : [itemHook.getEntityId()]

      this.$emit(CollectionWidgetEventNames.SELECTION_LIST_UPDATED, valueToEmit)
      this.onSelect && this.onSelect(this.hook, itemHook, this)
    },
    isRecordSelected(entityId: number): boolean {
      return this.selectedRecords.includes(entityId)
    },
    setFields(fields: string[]) {
      if (fields && fields.length) {
        this.$emit('set-required-fields', fields)
        this.hook.requiredFields = fields
      }
    },
    loadActions() {
      this.hook.loadEntitiesActions = true
    },
    setOffset(newOffset: number) {
      this.hook.queryManager.setOffset(newOffset)
      this.hook.update()
      this.moveToTop()
    },
    setLimit(newLimit: number) {
      this.hook.queryManager.setLimit(newLimit)
      this.hook.update()
      this.moveToTop()
    },
    onWidgetRendered() {
      this.$emit('rendered')
    },
    /**
     * Formatted index allows you not to lose the index count when you reach the pagination limit
     */
    formattedIndex(index: number): number | null {
      if (index == null) return null

      const offset = this.hook.queryManager.query.offset ?? 0

      return index + offset
    },
  },
  computed: {
    isPreviewDisabled(): boolean {
      return !!this.hook.widget.disableResourcePreviewOnClick
    },
    total(): number {
      return Math.ceil(this.hook?.state?.totalEntities)
    },
    listItem(): any {
      return this.hook.listItem ?? {}
    },
    pagination(): {
      total: any
      limit: any
      offset: any
    } {
      return {
        total: this.hook.state.totalEntities,
        limit: this.hook.queryManager.query.limit,
        offset: this.hook.queryManager.query.offset,
      }
    },
  },
  watch: {
    'hook.entities'(entities) {
      this.$emit(ListWidgetEventNames.RECORDS_LOADED, entities)
      this.onWidgetRendered()
    },
  },
})
</script>

<style scoped>
.list-widget >>> .list-item-div.selected {
  border-color: var(--v-info-darken2);
}

.list-footer {
  position: sticky;
  bottom: 0;
}

@media print {
  .hide-scroll-in-print-view {
    overflow: hidden !important;
  }

  .hide-in-print-view {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  background-color: rgba(234, 238, 255, 0.2) !important;
}

/**
Remove row background hover effect when hovering on a clickable element (ie: relations)
 */
.cursor-pointer:has(.text-clickable:hover) {
  background-color: transparent !important;
}
</style>
