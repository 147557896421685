<template>
  <div>
    <div v-if="isLoadingSchema && !form">
      <v-skeleton-loader v-for="index in 4" :key="index" type="list-item" />
    </div>
    <span v-if="form && !isLoadingSchema" class="d-flex justify-end ma-4">
      <TPdfExportButton @click="view" />
    </span>
    <v-row v-if="form && !isLoadingSchema" class="px-3" no-gutters>
      <v-col>
        <div class="px-6">
          <ReportFieldsForm v-model="formModel" :form="form" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { AppContext } from '@/tt-app-context'
import { Resources } from '@/tt-entity-design/src/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { ReportFormData } from '@/tt-widget-views/reports/types'
import ReportFieldsForm from '@/tt-widget-views/reports/components/ReportFieldsForm.vue'
import TPdfExportButton from '@/tt-ui/components/TPdfExportButton.vue'
import { getResourceDocument } from '@/tt-widget-entity-flow/helper'
import i18n from '@/plugins/i18n'

export default Vue.extend({
  name: 'ViewReportsFields',
  components: { ReportFieldsForm, TPdfExportButton },
  props: {
    reportId: {
      type: Number,
      required: true,
    },
    entityReference: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: null as ReportFormData,
      formModel: null as Record<string, unknown>,
      isLoadingSchema: false,
    }
  },
  created() {
    this.fetchReport()
  },
  methods: {
    async view() {
      const translationKey = 'tt-entity-design.report'
      const documentPreset = getResourceDocument(
        Resources.REPORTS,
        translationKey,
      )

      if (!documentPreset) {
        console.error(
          `No document preset registered for resource "${
            Resources.REPORTS
          }" and document name ${this.$t(translationKey)}`,
        )

        return
      }

      const reportInput = await documentPreset.toDocument(
        this.$appContext,
        { ...this.entityReference, entityId: this.reportId },
        i18n.locale,
      )

      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.VIEW_DOCUMENT,
        reportInput,
      )
    },
    fetchReport() {
      const appContext: AppContext = this.$appContext

      this.isLoadingSchema = true

      const setState = ({ jsonFormSchema }) => {
        const formOptions = { ...jsonFormSchema.formOptions, readOnly: true }
        this.form = { ...jsonFormSchema, formOptions }
        this.formModel = this.form.values
      }

      appContext.authModule
        .getApi()
        .get(Resources.REPORTS, this.reportId, {
          extension: ['jsonFormSchema'],
        })
        .then(setState)
        .catch((error) => this.onSubmitError(error))
        .finally(() => {
          this.isLoadingSchema = false
        })
    },
    onSubmitError(error): void {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_ERROR, {
        message: error,
      })
    },
  },
})
</script>
