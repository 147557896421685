import {
  addPreviewTab,
  registerResourceForm,
} from '@/tt-widget-entity-flow/helper'
import { Resources } from '@/tt-entity-design/src/types'
import {
  modularManager,
  PresetItem,
  PresetLevels,
  PresetTypes,
} from '@/tt-app-modular'

import ClientsDetailMainContact from './ClientsDetailMainContact.vue'
import ClientsDetailMore from './ClientsDetailMore.vue'
import { ClientActions } from './types'

import ClientsSetInvoiceSplitSettings from './ClientsSetInvoiceSplitSettings.vue'
import { createI18nResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import { Attribute } from '@/tt-entity-design/src/schema-types'

const { CLIENTS } = Resources

const getAttr = (attr: Attribute<typeof CLIENTS>): string => attr

export default {
  install(Vue) {
    Vue.component('ClientsDetailMainContact', ClientsDetailMainContact)
    Vue.component('ClientsDetailMore', ClientsDetailMore)

    // Add the schedule preview in the tabs
    addPreviewTab(CLIENTS, {
      title: 'tt-entity-design.schedule',
      is: 'SchedulePreview',
    })

    // Adding extra detail cards
    const mainContactDetail: PresetItem = {
      title: 'tt-entity-design.main_contact',
      data: { is: 'ClientsDetailMainContact', opened: false },
      order: 2,
      level: PresetLevels.DEFAULTS,
    }

    const moreDetail: PresetItem = {
      title: 'tt-entity-design.more',
      data: { is: 'ClientsDetailMore', opened: false },
      order: 1,
      level: PresetLevels.DEFAULTS,
    }

    modularManager.setResourcePresets(CLIENTS, PresetTypes.DETAILS_CARD, [
      mainContactDetail,
      moreDetail,
    ])

    modularManager.registerDynamicResourceJsonSchemaActions(CLIENTS, [
      ClientActions.ImportExceptionDates,
    ])

    Vue.component(
      'ClientsSetInvoiceSplitSettings',
      ClientsSetInvoiceSplitSettings,
    )
    registerResourceForm(
      'clients',
      'ClientsSetInvoiceSplitSettings',
      'set-invoice-split-settings',
    )

    addPreviewTab(CLIENTS, {
      title: createI18nResourceKey(CLIENTS, getAttr('zones')),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('zones'),
      },
    })

    addPreviewTab(CLIENTS, {
      title: createI18nResourceKey(CLIENTS, getAttr('positions')),
      is: 'EntityRelationListCustomPreviewWrapper',
      props: {
        attributeName: getAttr('positions'),
      },
    })
  },
}
