import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'customId',
    headerText: 'ID',
  },
  {
    attributeName: 'name',
    component: {
      is: 'AssetPersonNameImportIconAttr',
      props: {
        cssClass: 'font-weight-bold',
      },
    },
  },
  'jobTitle',
  'type',
  'primaryPhone',
  'email',
] as ColumnInputDefinition[]
