<template>
  <div class="warning--alert">
    <v-alert
      :value="value"
      icon="mdi-alert"
      class="font-weight-bold"
      dense
      text
      type="warning"
      transition="scale-transition"
    >
      <span class="warning-message">
        {{ $t('tt-ui.alert.warning-message') }}
      </span>
    </v-alert>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'TEntityToolbarAlert',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped>
.warning--alert ::v-deep .v-alert {
  font-size: 0.75em !important;
  padding: 0 12px 0 0 !important;
  margin: 0 !important;
  background-color: #fff6e5 !important;
  color: var(--v-warning-darken4) !important;
}

.warning--alert ::v-deep .v-icon__svg {
  height: 20px !important;
  width: 20px !important;
}

.warning--alert ::v-deep .v-alert__icon.v-icon {
  font-size: 30px !important;
  padding-left: 10px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  align-self: center !important;
}

.warning-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
