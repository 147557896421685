import { EntityPersistRunner } from '@/tt-widget-entity-flow/EntityPersistRunner'
import { EventManagerInterface } from '@/tt-event-manager'
import { LayoutWindowEvent } from '@/tt-app-layout'

type ConfirmDeleteData = {
  resourceName: string
  entityId: number
  message: string
}

type ConfirmExecuteActionsData = {
  resourceName: string
  entityId: number
  actionName: string
  message: string
}

type ConfirmExecuteActionsDependencies = {
  eventManager: EventManagerInterface
  persister: EntityPersistRunner
}

export const confirmDeleteEntity = (
  { entityId, message, resourceName }: ConfirmDeleteData,
  { eventManager, persister }: ConfirmExecuteActionsDependencies,
): void => {
  const onAccept = () => persister.deleteEntity(resourceName, entityId)

  eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
    message,
    accept: onAccept,
  })
}

export const confirmExecuteEntityAction = (
  { actionName, entityId, message, resourceName }: ConfirmExecuteActionsData,
  { eventManager, persister }: ConfirmExecuteActionsDependencies,
): void => {
  const onAccept = () =>
    persister.executeEntityAction(resourceName, actionName, entityId)

  eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
    message,
    accept: onAccept,
  })
}
