export enum TextClass {
  BOLD = 'font-weight-bold',
  LIGHT = 'font-weight-light',
  THIN = 'font-weight-thin',
  LEFT = 'text-left',
  CENTER = 'text-center',
  RIGHT = 'text-right',
  UNDERLINE = 'text-decoration-underline',
  LINE_TROUGH = 'text-decoration-line-through',
  OVERLINE = 'text-decoration-overline',
  OPACITY_MEDIUM = 'text--secondary',
  OPACITY_DISABLED = 'text--disabled',
  UPPERCASE = 'text-uppercase',
  LOWERCASE = 'text-lowercase',
  CAPITALIZE = 'text-capitalize',
  NO_WRAP = 'text-no-wrap secondary',
  TRUNCATE = 'text-truncate',
}
