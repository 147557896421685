<template>
  <v-dialog v-model="showDialog" width="320" persistent>
    <template #activator="{ on }">
      <v-text-field
        :value="formattedDatetime"
        v-bind="{
          ...$attrs,
          ...vuetifyDefaults,
          label,
          placeholder,
          errorMessages,
        }"
        prepend-inner-icon="event_note"
        readonly
        clearable
        v-on="on"
        @click:clear="clear"
      >
        <template #append-outer>
          <v-tooltip v-if="timeZone" top>
            <template #activator="{ on, attrs }">
              <v-icon color="grey" dark v-bind="attrs" light v-on="on">
                mdi-map-clock
              </v-icon>
            </template>
            <span>{{ timeZone }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </template>

    <template #default>
      <v-card>
        <v-card-text class="pa-0">
          <v-tabs v-model="activeTab" fixed-tabs>
            <v-tab key="calendar">
              <slot name="dateIcon">
                <v-icon>event</v-icon>
              </slot>
            </v-tab>
            <v-tab key="timer" :disabled="!datePart">
              <slot name="timeIcon">
                <v-icon>access_time</v-icon>
              </slot>
            </v-tab>
            <v-tab-item key="calendar">
              <v-date-picker
                v-model="datePart"
                v-bind="$attrs"
                :locale="locale"
                full-width
                scrollable
                actions
              />
            </v-tab-item>
            <v-tab-item key="timer">
              <v-time-picker
                v-model="timePart"
                :format="format"
                full-width
                class="v-time-picker-custom"
                scrollable
                ampm-in-title
              />
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click.native="close">
            {{ $t('common.cancel.btn') }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" :disabled="!datetime" @click="save">
            {{ $t('common.ok.btn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script lang="ts">
import moment from 'moment-timezone'

import { vuetifyDefaults } from '@tracktik/tt-json-schema-form'

import BaseInput from '@/tt-widget-components/components/BaseInput'
import { ApiDateTimeFormats } from '@/helpers/formats/dates'
import { formatDate } from '@/helpers/dates/formatDate'
import { parseDate } from '@/helpers/dates/parseDate'

import { getTimeFormat, convertDateWithTimezone } from './utils'

const DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm'

enum TABS {
  DATE,
  TIME,
}

export default BaseInput.extend({
  name: 'DateTimeField',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null,
    },
    userDatetimeFormat: {
      type: String,
      default: DEFAULT_FORMAT,
    },
    locale: {
      type: String,
      default: 'en-us',
    },
    timeZone: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
      activeTab: TABS.DATE,
      vuetifyDefaults,
      datePart: null,
      timePart: null,
    }
  },
  computed: {
    datetime(): string {
      const { datePart, timePart } = this
      const date = datePart && timePart && parseDate(`${datePart} ${timePart}`)
      return date ? formatDate(date, ApiDateTimeFormats.ISO_8601) : ''
    },
    formattedDatetime(): string {
      const { model, userDatetimeFormat, timeZone } = this
      if (!model) return ''

      //@todo: wrap all usage of moment into a service and add tests
      const momentObj = timeZone ? moment.tz(model, timeZone) : moment(model)
      return momentObj.format(userDatetimeFormat)
    },
    format(): 'ampm' | '24hr' {
      return getTimeFormat(this.userDatetimeFormat)
    },
  },
  methods: {
    save() {
      const newDateTime = this.timeZone
        ? convertDateWithTimezone(this.datetime, this.timeZone)
        : this.datetime
      this.$emit('input', newDateTime)
      this.close()
    },
    close() {
      this.showDialog = false
      this.activeTab = TABS.DATE
      this.datePart = null
      this.timePart = null
      this.$emit('blur')
    },
    clear() {
      this.$emit('input', null)
      this.$emit('blur')
    },
  },
  watch: {
    datePart(datePart) {
      this.activeTab = datePart ? TABS.TIME : TABS.DATE
    },
  },
})
</script>
