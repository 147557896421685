import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'

export default [
  {
    attributeName: 'name',
    style: {
      cssClass: 'font-weight-bold',
    },
  },
  'customId',
  'description',
  'parentCategory',
  {
    attributeName: 'status',
    component: {
      is: 'AssetsStatusAttr',
    },
  },
] as ColumnInputDefinition[]
