<template>
  <div>
    <v-autocomplete
      ref="input"
      v-model="model"
      :disabled="hasInvalidUser"
      :hint="invalidUserMessage"
      :hide-details="!hasInvalidUser"
      persistent-hint
      :items="items"
      :loading="isLoading"
      :search-input.sync="searchText"
      return-object
      hide-selected
      :no-data-text="$t(`common.no_results`)"
      :hide-no-data="!searchText || isLoading"
      no-filter
      multiple
      deletable-chips
      clearable
      filled
      item-text="name"
      :placeholder="$t('intents.widget_share.input.placeholder')"
      @click="search(searchText)"
    >
      <template #selection="{ item }">
        <v-chip class="mb-2 mt-2">
          <SharableUserGroupAvatar :item="item" :size="24" />
          <span class="ml-2" style="font-size: 0.8em" v-text="item.name" />
        </v-chip>
      </template>
      <template #item="{ item }">
        <SharableUserGroupAvatar :item="item" tag="v-list-item-avatar" />
        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
          <v-list-item-subtitle class="grey" v-text="item.subtitle" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import debounce from 'lodash/debounce'
import Vue, { PropType } from 'vue'

import SharableUserGroupAvatar from './SharableUserGroupAvatar.vue'
import { ShareService } from '../ShareService'
import { UserGroupItem } from '../types'

export default Vue.extend({
  name: 'SharableFormAutocomplete',
  components: {
    SharableUserGroupAvatar,
  },
  props: {
    hasInvalidUser: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    hiddenTags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      items: [] as UserGroupItem[],
      searchText: '',
    }
  },
  computed: {
    invalidUserMessage(): string {
      return this.$t('intents.widget_share.input.invalid_user_hint')
    },
    model: {
      get(): any[] {
        return this.value
      },
      set(model: any[]) {
        this.$emit('input', model)
        this.searchText = null
      },
    },
  },
  methods: {
    search(val) {
      const stopLoading = () => (this.isLoading = false)
      const setItems = (res: UserGroupItem[]) => {
        this.items = res.filter(
          (item) => !this.hiddenTags.includes(item.aclTag),
        )
      }
      const onError = (err) => {
        console.warn(err)
        this.$crash.captureException(err)
      }

      new ShareService(this.$appContext)
        .search(val ?? '')
        .then(setItems)
        .catch(onError)
        .finally(stopLoading)
    },
    searchDebounce: debounce(function (val) {
      // @ts-ignore
      this.search(val)
    }, 500),
  },
  watch: {
    searchText(searchText) {
      if (!searchText) return

      this.isLoading = true
      this.searchDebounce(searchText)
    },
  },
})
</script>
