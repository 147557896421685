import { Action, Resource } from '@/tt-entity-design/src/schema-types'
import { CTAType } from './type'

export const SYSTEM_EXCEPTION_TICKETS: Resource = 'system-exception-tickets'
export const TAKE: Action<typeof SYSTEM_EXCEPTION_TICKETS> = 'take'
export const ASSIGN: Action<typeof SYSTEM_EXCEPTION_TICKETS> = 'assign'
export const UN_ASSIGN: Action<typeof SYSTEM_EXCEPTION_TICKETS> = 'un-assign'
export const LEAVE: Action<typeof SYSTEM_EXCEPTION_TICKETS> = 'leave'
export const TYPE_VALUES: Record<CTAType, CTAType> = {
  CHECKPOINT_LATE_SCAN_TICKET: 'CHECKPOINT_LATE_SCAN_TICKET',
  CLOCK_IN_OUTSIDE_ALLOWED_AREA_TICKET: 'CLOCK_IN_OUTSIDE_ALLOWED_AREA_TICKET',
  CLOCK_IN_WITHOUT_LAT_LON_TICKET: 'CLOCK_IN_WITHOUT_LAT_LON_TICKET',
  CLOCK_OUT_EARLY_SHIFT_TICKET: 'CLOCK_OUT_EARLY_SHIFT_TICKET',
  GPS_DISABLED_TICKET: 'GPS_DISABLED_TICKET',
  INACTIVE_MOBILE_USER_TICKET: 'INACTIVE_MOBILE_USER_TICKET',
  PANIC_TRIGGERED_TICKET: 'PANIC_TRIGGERED_TICKET',
  PUNCH_IN_WRONG_NUMBER_TICKET: 'PUNCH_IN_WRONG_NUMBER_TICKET',
  REPORT_PRIORITY_TICKET: 'REPORT_PRIORITY_TICKET',
  SIGNAL_LATE_TICKET: 'SIGNAL_LATE_TICKET',
  UNAUTHORIZED_NUMBER_SIGNAL_TICKET: 'UNAUTHORIZED_NUMBER_SIGNAL_TICKET',
  UNCLOSED_SHIFT_TICKET: 'UNCLOSED_SHIFT_TICKET',
  UNCOVERED_SHIFT_TICKET: 'UNCOVERED_SHIFT_TICKET',
  USER_IP_BLOCKED_TICKET: 'USER_IP_BLOCKED_TICKET',
  WILL_BE_LATE_TICKET: 'WILL_BE_LATE_TICKET',
  SLA_CROSSED_TICKET: 'SLA_CROSSED_TICKET',
  CANCELLATION_TIMER_EXCEEDED_TICKET: 'CANCELLATION_TIMER_EXCEEDED_TICKET',
  THRESHOLD_ALERT_TICKET: 'THRESHOLD_ALERT_TICKET',
  THRESHOLD_WARNING_TICKET: 'THRESHOLD_WARNING_TICKET',
  TIMER_EXCEEDED_TICKET: 'TIMER_EXCEEDED_TICKET',
  RUNSHEET_STARTED_EARLY_TICKET: 'RUNSHEET_STARTED_EARLY_TICKET',
  RUNSHEET_STARTED_LATE_TICKET: 'RUNSHEET_STARTED_LATE_TICKET',
  RUNSHEET_STOPPED_EARLY_TICKET: 'RUNSHEET_STOPPED_EARLY_TICKET',
  RUNSHEET_STOPPED_LATE_TICKET: 'RUNSHEET_STOPPED_LATE_TICKET',
  ALLOW_UNQUALIFIED_EMPLOYEE_TICKET: 'ALLOW_UNQUALIFIED_EMPLOYEE_TICKET',
}
