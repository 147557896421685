import { FormOptions, JSONSchema7 } from '@tracktik/tt-json-schema-form'

export enum ReportTemplateActions {
  AssignLibrary = 'assign-library',
  SortFields = 'sort-fields',
  UnassignLibrary = 'unassign-library',
}

export enum ReportTemplateType {
  DISPATCHABLE_TASK = 'DISPATCHABLE_TASK',
}

export type ReportTemplate = {
  name: string
}

export type ReportTemplatesJsonFormSchemaExtensionResponse = {
  formOptions?: FormOptions
  schema: JSONSchema7
  value?: Record<string, unknown>
}
