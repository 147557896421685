export enum ClientActions {
  ImportExceptionDates = 'import-exception-dates',
  AssignVendors = 'assign-vendors',
  UnassignVendors = 'unassign-vendors',
}

export type ClientSplitInvoiceSetting = {
  destinationAccount: number
  splitPercentage: number
}
