<template>
  <v-list class="navigation--container fill-height" nav dense flat>
    <v-list-item-group
      v-for="({ menuTitle, subMenus, key }, index) in menuItems"
      :key="index"
      no-action
    >
      <v-tooltip>
        <template #activator="{ on }">
          <h4 v-on="key === 'back-office-reports-menu' && on">
            {{ $t(menuTitle) }}
          </h4>
        </template>
        <span v-text="$t('back-office-reports.tooltip')" />
      </v-tooltip>
      <v-list-item-group
        v-for="{ subMenuTitle, tabs } in subMenus"
        :key="subMenuTitle"
        no-action
        class="ml-3"
      >
        <v-list-item-content class="pt-2 pb-2">
          <h5>{{ $t(subMenuTitle) }}</h5>
        </v-list-item-content>
        <v-list-item
          v-for="{ title, key } in tabs"
          :key="title"
          :class="{ 'active-item': key === activeTab }"
          dense
          @click="menuClick(key)"
        >
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t(title) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list-item-group>
  </v-list>
</template>
<script lang="ts">
import { LayoutComponents } from '@/tt-app-layout'
import { VueWithLayoutManager } from '@/tt-widget-views/types'
import Vue, { PropType } from 'vue'
import {
  EFFECTIVE_DATE_TABS,
  SCHEDULING_TABS,
  EARNINGS_PREMIUM_TABS,
  HUMAN_CAPITAL_MANAGEMENT_TABS,
  LEAVE_MANAGEMENT_TABS,
} from '../constants'
import {
  BackOfficeReportsMenuItem,
  BackOfficeReportsSubMenuItem,
  BackOfficeReportsMenus,
  BackOfficeReportsSubMenus,
  BackOfficeReportsViews,
  BackOfficeReportsTab,
} from '../types'

/**
 * @TODO:Update Nomenclature to Reporting & Analytics (BOSS-4284)
 */
export default (Vue as VueWithLayoutManager).extend({
  name: 'BackOfficeReportsLeftNavigationMenu',
  inject: ['layoutManager'],
  props: {
    menuTabs: {
      type: Array as PropType<BackOfficeReportsMenus[]>,
      required: true,
    },
    subMenuTabs: {
      type: Array as PropType<BackOfficeReportsSubMenus[]>,
      required: true,
    },
    activeTab: {
      type: String as PropType<BackOfficeReportsViews>,
      required: true,
    },
    visibleTabs: {
      type: Array as PropType<BackOfficeReportsViews[]>,
      default: () => [],
    },
  },
  computed: {
    menuItems(): BackOfficeReportsMenuItem[] {
      const menuItems = [
        {
          key: BackOfficeReportsMenus.FINANCIAL_OPERATIONAL_MENU,
          menuTitle: 'back-office-reports.financial-operational.title',
          subMenus: [],
        },
        {
          key: BackOfficeReportsMenus.BACK_OFFICE_REPORTS_MENU,
          menuTitle: 'back-office-reports.title',
          subMenus: this.backOfficeReportsSubMenuItems,
        },
      ]

      return menuItems.filter(({ key }) => this.menuTabs.includes(key))
    },
    backOfficeReportsSubMenuItems(): BackOfficeReportsSubMenuItem[] {
      const items = [
        {
          key: BackOfficeReportsSubMenus.EFFECTIVE_DATE_SUB_MENU,
          subMenuTitle: 'back-office-reports.effective-date.title',
          tabs: EFFECTIVE_DATE_TABS.filter(this.availableMenuItems).map(
            this.createMenuItem,
          ),
        },
        {
          key: BackOfficeReportsSubMenus.SCHEDULING_SUB_MENU,
          subMenuTitle:
            'back-office-reports.scheduling.scheduling-report.title',
          tabs: SCHEDULING_TABS.filter(this.availableMenuItems).map(
            this.createMenuItem,
          ),
        },
        {
          key: BackOfficeReportsSubMenus.EARNINGS_PREMIUM_SUB_MENU,
          subMenuTitle: 'back-office-reports.earnings-premium.title',
          tabs: EARNINGS_PREMIUM_TABS.filter(this.availableMenuItems).map(
            this.createMenuItem,
          ),
        },
        {
          key: BackOfficeReportsSubMenus.HCM_ACTIVITY_SUB_MENU,
          subMenuTitle: 'back-office-reports.hcm-activity.title',
          tabs: HUMAN_CAPITAL_MANAGEMENT_TABS.filter(
            this.availableMenuItems,
          ).map(this.createMenuItem),
        },
        {
          key: BackOfficeReportsSubMenus.LEAVE_MANAGEMENT_SUB_MENU,
          subMenuTitle: 'tt-entity-design.leave-management.label',
          tabs: LEAVE_MANAGEMENT_TABS.filter(this.availableMenuItems).map(
            this.createMenuItem,
          ),
        },
      ]

      return items.filter(({ key }) => this.subMenuTabs.includes(key))
    },
    isSidePanelOpen(): boolean {
      return this.layoutManager.state.sideSheet.state
    },
  },
  methods: {
    closeSidePanel() {
      return (
        this.isSidePanelOpen &&
        this.layoutManager.close(LayoutComponents.sideSheet)
      )
    },
    menuClick(tab: BackOfficeReportsViews) {
      this.$emit('menu-click', tab)
      this.closeSidePanel()
    },
    availableMenuItems(tab: BackOfficeReportsTab): boolean {
      return this.visibleTabs.includes(tab.key)
    },
    createMenuItem({ title, key }): {
      title: string
      key: BackOfficeReportsViews
    } {
      return { title, key }
    },
  },
})
</script>
<style scoped>
.navigation--container {
  min-width: 250px;
  width: auto;
  padding: 15px 15px 15px 0px;
  background-color: var(--v-muted-base);
}

.navigation--container >>> .v-list-item__title {
  padding: 5px 0;
}

.navigation--container >>> .v-list-item--dense {
  min-height: 25px;
}

.navigation--container >>> .v-list-item__content {
  padding: 4px 0;
}

.active-item {
  background-color: var(--v-muted-darken1);
}
</style>
