import { PluginFunction } from 'vue'

const ReportConditionSetWizardPage = () =>
  import(
    /* webpackChunkName: "ReportConditionSetWizardPage" */
    './pages/ReportConditionSetWizardPage.vue'
  )
const ReportLibraryFormPage = () =>
  import(
    /* webpackChunkName: "ReportLibraryFormPage" */
    './pages/ReportLibraryFormPage.vue'
  )
const ReportTemplateBuilderJsonField = () =>
  import(
    /* webpackChunkName: "ReportTemplateBuilderJsonField" */
    './components/ReportTemplateBuilderJsonField.vue'
  )
const ReportTemplateBuilderJsonObject = () =>
  import(
    /* webpackChunkName: "ReportTemplateBuilderJsonObject" */
    './components/ReportTemplateBuilderJsonObject.vue'
  )
const ReportTemplateFieldFormPage = () =>
  import(
    /* webpackChunkName: "ReportTemplateFieldFormPage" */
    './pages/ReportTemplateFieldFormPage.vue'
  )
const ReportTemplateFieldGroupFormPage = () =>
  import(
    /* webpackChunkName: "ReportTemplateFieldGroupFormPage" */
    './pages/ReportTemplateFieldGroupFormPage.vue'
  )
const ReportTemplateFieldsPage = () =>
  import(
    /* webpackChunkName: "ReportTemplateFieldsPage" */
    './pages/ReportTemplateFieldsPage.vue'
  )
const ReportTemplateFieldsView = () =>
  import(
    /* webpackChunkName: "ReportTemplateFieldsView" */
    './views/ReportTemplateFieldsView.vue'
  )

export { ReportTemplateFieldsView }

export const ReportTemplatesPlugin: PluginFunction<void> = (Vue) => {
  Vue.component('ReportConditionSetWizardPage', ReportConditionSetWizardPage)
  Vue.component('ReportLibraryFormPage', ReportLibraryFormPage)
  Vue.component(
    'ReportTemplateBuilderJsonField',
    ReportTemplateBuilderJsonField,
  )
  Vue.component(
    'ReportTemplateBuilderJsonObject',
    ReportTemplateBuilderJsonObject,
  )
  Vue.component('ReportTemplateFieldFormPage', ReportTemplateFieldFormPage)
  Vue.component(
    'ReportTemplateFieldGroupFormPage',
    ReportTemplateFieldGroupFormPage,
  )
  Vue.component('ReportTemplateFieldsPage', ReportTemplateFieldsPage)
  Vue.component('ReportTemplateFieldsView', ReportTemplateFieldsView)
}
