<template>
  <v-card>
    <v-card-title>
      <h3>{{ resourceLabel }}</h3>

      <v-spacer />

      <v-btn icon @click="$emit('clear')">
        <v-icon v-text="`mdi-close`" />
      </v-btn>
    </v-card-title>

    <v-card-text>
      <EntityPresetRelation
        :resource-name="resource"
        :entity="entity"
        fetch-enabled
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { createResourceKey } from '@/tt-widget-entity-flow/ResourceTranslator'
import EntityPresetRelation from '@/tt-widget-entity-flow/components/EntityPresetRelation.vue'
import { AssignType } from '../types'

export default Vue.extend({
  name: 'DispatchAssignSelectedItem',
  components: {
    EntityPresetRelation,
  },
  props: {
    resource: {
      type: String as PropType<AssignType>,
      required: true,
    },
    entityId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  computed: {
    resourceLabel(): string {
      return this.$t(createResourceKey(this.resource))
    },
    entity(): unknown {
      return {
        id: this.entityId,
      }
    },
  },
})
</script>
