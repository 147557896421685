import isEqual from 'lodash/isEqual'
import unionBy from 'lodash/unionBy'
import uniq from 'lodash/uniq'
import uniqueId from 'lodash/uniqueId'
import Vue from 'vue'

import { AttributeName } from '@/tt-widget-components/lib/names'
import { EntityIntentTypes } from '@/tt-widget-entity-flow/intents/types'
import {
  EventHandler,
  EventPayload,
  GlobalEventHandler,
  UnsubscribeFunction,
} from '@/tt-event-manager/types'
import { Filter } from '@/tt-widget-components'
import { isEmpty } from '@/helpers/isEmpty'

import {
  ContextDictionary,
  ContextManagerBaseInterface,
  ContextManagerInterface,
  ResourceAndContextAttributeMap,
  ResourceFiltersMap,
  WidgetHookInterface,
} from '../types'
import { Resources } from '@/tt-entity-design/src/types'
import { AuthModule, SessionRegion } from '@tracktik/tt-authentication'
import { createEntityCollector } from '@/tt-widget-components/base/EntityCollector/EntityCollector'
import {
  Entity,
  EntityCollector,
} from '@/tt-widget-components/base/EntityCollector/type'
import { RegionManager } from '@/tt-region-manager/types'
import { isNullOperatorType } from '../../tt-entity-filter/util'
import { Attribute } from '../services/resource-meta/types'
import {
  byTemporalTypePriority,
  groupByTemporalType,
} from './ContextManagerUtil'
import { FieldTypeTemporal } from '@/tt-entity-filter/temporal-filters/types'

const { CONTEXT_CHANGE } = EntityIntentTypes

const { DATE_RANGE_ATTRIBUTE, REGION_ATTRIBUTE, ACCOUNT_ATTRIBUTE } =
  AttributeName

export default class ContextManager implements ContextManagerInterface {
  private readonly contextId: string
  private readonly parentContext:
    | ContextManagerBaseInterface
    | ContextManagerInterface

  private _context: ContextDictionary
  private _resourceAndContextAttributeMap: ResourceAndContextAttributeMap[] = []
  private widgetHooks: WidgetHookInterface[] = []
  private accountCollector: EntityCollector
  private regionManager: RegionManager

  /**
   *
   * @param parentContext
   * @param context
   */
  constructor(
    authModule: AuthModule,
    regionManager: RegionManager,
    parentContext: ContextManagerBaseInterface,
    context: ContextDictionary = {},
  ) {
    this.regionManager = regionManager
    this.parentContext = parentContext
    this.contextId = uniqueId('context_')
    this._context = Vue.observable({ ...context })
    this.parentContext.subscribeEvent(CONTEXT_CHANGE, (context) =>
      this.setContext(context),
    )
    this.accountCollector = createEntityCollector(
      { authModule },
      { resource: Resources.ACCOUNTS, fields: ['name', 'type'] },
    )
  }

  /**
   * Return the context dictionary
   */
  get context(): ContextDictionary {
    return this._context
  }

  /**
   * Subscribe a global event
   *
   * @param handler
   */
  subscribeGlobal(handler: GlobalEventHandler): void {
    this.parentContext.subscribeGlobal(handler)
  }

  /**
   * Get the list of resources as strings
   */
  get resources(): string[] {
    return uniq(
      this._resourceAndContextAttributeMap.map(
        (resourceAndContextAttributeMap: ResourceAndContextAttributeMap) =>
          resourceAndContextAttributeMap.resource,
      ),
    )
  }

  /**
   * Return the list of the context filters we should show based
   * on the registered widgets
   *
   * ex: ['dateRangeAttribute']
   */
  get contextFilterTypes(): string[] {
    const filters = []

    if (!this._resourceAndContextAttributeMap) {
      return []
    }
    this._resourceAndContextAttributeMap.forEach(
      (resourceAndContextAttributeMap: ResourceAndContextAttributeMap) => {
        if (!resourceAndContextAttributeMap.contextAttributeMap) {
          return
        }
        Object.keys(resourceAndContextAttributeMap.contextAttributeMap)
          .filter((k: string) => {
            const val = resourceAndContextAttributeMap.contextAttributeMap[k]

            return !(isEmpty(val) || val === false || val === undefined)
          })
          .forEach((k: string) => {
            if (!filters.includes(k)) {
              filters.push(k)
            }
          })
      },
    )

    return filters
  }

  getResourceFilterLayer(layerName: string): ResourceFiltersMap {
    return this._context.filterLayers?.[layerName] ?? null
  }

  getSelectedAccounts(): Entity[] {
    return this.accountCollector.getEntities()
  }

  // @TODO: add tests
  getSelectedRegions(): SessionRegion[] {
    const selectedRegionsIds = this.getSelectedRegionIds()
    const userRegions = this.regionManager.getAllUserRegions()

    return userRegions.filter((item) => selectedRegionsIds.includes(item.id))
  }

  /**
   *
   * @param layerName
   * @param filterMap
   */
  setResourceFilterLayer(layerName: string, filterMap: ResourceFiltersMap) {
    this._context.filterLayers = this._context.filterLayers ?? {}
    this._context.filterLayers[layerName] = filterMap
    this.dispatchContextEvent(this._context)
  }

  /**
   * Set the full context value
   *
   * @param context
   */
  private setContext(context: ContextDictionary) {
    this._context = { ...this._context, ...context }

    const hasZeroRegionSelected = this.getSelectedRegionIds().length === 0

    if (
      hasZeroRegionSelected &&
      this.regionManager.isContextRegionHandledByFrontend()
    ) {
      this.applyCurrentContextRegions()
    } else {
      this.dispatchContextEvent(this._context)
    }
  }

  /**
   * Dispatch context event
   *
   * @param eventName
   * @param payload
   */
  dispatchContextEvent(payload: EventPayload[typeof CONTEXT_CHANGE]) {
    this.dispatchEvent(CONTEXT_CHANGE, payload, this.contextId)
  }

  /**
   * Dispatch an event
   *
   * @param eventName
   * @param payload
   * @param contextId
   */
  dispatchEvent<E extends keyof EventPayload, P extends EventPayload[E]>(
    eventName: E,
    payload: P,
    contextId?: string,
  ): void {
    this.parentContext.dispatchEvent(eventName, payload, contextId)
  }

  /**
   * Subscribe to an event
   *
   * @param eventName
   * @param handler
   * @param contextIds
   */
  subscribeEvent(
    eventName: string,
    handler: EventHandler,
    contextIds: string[] = [],
  ): UnsubscribeFunction {
    return this.parentContext.subscribeEvent(eventName, handler, [
      this.contextId,
      ...contextIds,
    ])
  }

  /**
   * Register a widget
   *
   * @param widgetHook
   */
  registerWidget(widgetHook: WidgetHookInterface): () => void {
    // Parent subscriptions
    let parentFx = null
    if (typeof this.parentContext['registerWidget'] === 'function') {
      parentFx = this.parentContext['registerWidget'](widgetHook)
    }

    this.widgetHooks.push(widgetHook)

    this.updateResourcesAndContextAttributeMap()

    return (isParent = false) => {
      this.widgetHooks = this.widgetHooks.filter(
        (registeredWidget) => registeredWidget !== widgetHook,
      )
      // Parent subscription
      if (parentFx) {
        parentFx(true)
      }
      // Update the resource
      if (!isParent) {
        this.updateResourcesAndContextAttributeMap()
      }
    }
  }

  /**
   * Update the resources and set the resource and context attribute map
   */
  updateResourcesAndContextAttributeMap() {
    let resourceAndContextAttributeMaps: ResourceAndContextAttributeMap[] = []
    this.widgetHooks.forEach((widgetHook) => {
      const hookResources = widgetHook.getResourcesAndContext()

      if (hookResources.length) {
        resourceAndContextAttributeMaps = [
          ...resourceAndContextAttributeMaps,
          ...hookResources,
        ]
      }
    })
    if (
      isEqual(
        resourceAndContextAttributeMaps,
        this._resourceAndContextAttributeMap,
      )
    ) {
      return
    }

    this._resourceAndContextAttributeMap = resourceAndContextAttributeMaps
  }

  /**
   * Get the context filters that are current set
   */
  public getContextFilters(): Filter[] {
    return this.context?.modelContextFilters || []
  }

  /**
   * Get a specific context filter, if set
   */
  public getContextFilter(name: AttributeName): Filter | undefined {
    return this.getContextFilters().find(({ attribute }) => attribute === name)
  }

  removeContextFilter(attribute: AttributeName): Promise<void> {
    const attributeDiffers = (filter: Filter) => filter.attribute !== attribute
    const newFilters = this.getContextFilters().filter(attributeDiffers)

    return this.setContextFilters(newFilters)
  }

  /**
   * Clear the filters and state
   */
  clearContextFilters(): void {
    this.removeContextFilter(ACCOUNT_ATTRIBUTE)
    this.removeContextFilter(REGION_ATTRIBUTE)
    this.removeContextFilter(DATE_RANGE_ATTRIBUTE)
  }

  setRegionContextFilter(ids: string[]) {
    this.setContextFilter({
      attribute: REGION_ATTRIBUTE,
      operator: 'IN',
      value: ids.join(','),
    })
  }

  setContextFilter(filter: Filter): Promise<void> {
    const filters = this.getContextFilters()
    const newFilters = unionBy([filter], filters, 'attribute')

    return this.setContextFilters(newFilters)
  }

  async setContextFilters(filters: Filter[]): Promise<void> {
    const cleanedFilters = filters.filter(
      ({ operator, value }) => value !== '' || isNullOperatorType(operator),
    )

    this.setContext({ modelContextFilters: cleanedFilters })

    await this.accountCollector.setNewIds(this.getSelectedAccountIds())
  }

  getSelectedAccountIds(): string[] {
    const account = this.getContextFilter(ACCOUNT_ATTRIBUTE)

    const rawValue = (account?.value ?? null) as string | number | null

    return rawValue === null ? [] : String(rawValue).split(',')
  }

  getSelectedRegionIds(): string[] {
    const region = this.getContextFilter(REGION_ATTRIBUTE)

    const rawValue = (region?.value ?? null) as string | number | null

    return rawValue ? String(rawValue).split(',') : []
  }

  getSelectedDateRange(): Filter | null {
    return this.getContextFilter(DATE_RANGE_ATTRIBUTE)
  }

  hasAccessToSelectedAccounts() {
    if (this.accountCollector.isLoading()) return true

    return this.accountCollector.hasAllEntities()
  }

  hasAccessToSelectedRegions() {
    const userRegionIds = this.regionManager
      .getAllUserRegions()
      .map((region) => region.id)

    const hasAccessToRegion = (regionId: string) =>
      userRegionIds.includes(regionId)

    return this.getSelectedRegionIds().every(hasAccessToRegion)
  }

  getFiltersCount(): number {
    const dateRangeFilterCount = this.getSelectedDateRange()?.value ? 1 : 0
    const accountFilterCount = this.getSelectedAccountIds().length
    const regionFilterCount = this.getSelectedRegionIds().length

    return dateRangeFilterCount + accountFilterCount + regionFilterCount
  }

  /**
   * Applies all the current context region IDs from the `RegionManager` as selected filters in the `ContextManager`.
   */
  private applyCurrentContextRegions(): void {
    const contextRegionIds = this.regionManager.getContextRegionIds()
    this.setRegionContextFilter(contextRegionIds)
  }

  /**
   * Returns the best date range type based on the context attribute mapping.
   * Will be used to find the best date range type to display in the UI.
   */
  getDateRangeBestType(
    getAttributeMeta: (resource: string, attr: string) => Attribute,
  ): FieldTypeTemporal | undefined {
    const hasDateContextFilter = (context: ResourceAndContextAttributeMap) =>
      !!context.contextAttributeMap.dateRangeAttribute

    const getAttributeType = ({
      resource,
      contextAttributeMap,
    }: ResourceAndContextAttributeMap): FieldTypeTemporal =>
      getAttributeMeta(
        resource,
        contextAttributeMap.dateRangeAttribute as string,
      ).type as FieldTypeTemporal

    return this._resourceAndContextAttributeMap
      .filter(hasDateContextFilter)
      .map(getAttributeType)
      .map(groupByTemporalType)
      .sort(byTemporalTypePriority)[0]
  }
}
