<template>
  <RegionTreeFilter
    :filter="payload.filter"
    :user-regions="payload.userRegions"
    @apply="apply"
    @clear="clear"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { RegionFilterPayload } from '@/tt-widget-entity-flow/intents/RegionFilterIntent'
import RegionTreeFilter from './RegionTreeFilter.vue'

export default Vue.extend({
  name: 'RegionFilterModal',
  components: {
    RegionTreeFilter,
  },
  props: {
    payload: {
      type: Object as PropType<RegionFilterPayload>,
      required: true,
    },
  },
  methods: {
    clear(): void {
      this.apply([])
    },
    apply(regionIds: string[]) {
      this.payload.onUpdate(regionIds)
      this.payload.onClose()
    },
  },
})
</script>
