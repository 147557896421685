import { EventManagerInterface } from '@/tt-event-manager'
import { cloneDeep } from 'lodash'
import {
  AlertInterface,
  AppLayoutState,
  ConfirmInterface,
  defaultAppLayoutState,
  DIALOG_DEFAULT_WIDTH,
  DialogInterface,
  LayoutComponents,
  LayoutConfiguration,
  LayoutWindowEvent,
  LEFT_SHEET_DEFAULT_WIDTH,
  PageInterface,
  SideMenuInterface,
  SnackBarInterface,
  SnackBarTypes,
  LayoutMultiPages,
  NotificationChipsInterface,
  DialogLoadingInterface,
  ActionMenuConfig,
} from '@/tt-app-layout/types'
import Vue from 'vue'
import { DocumentInput } from '@/tt-entity-documents/types'
import { validateAssertion } from './utils'
import i18n from '@/plugins/i18n'
export type LayoutManagerProvider = {
  layoutManager: LayoutManager
}

const getAsyncItem = async (item) =>
  typeof item === 'function' ? await item({}) : item

export default class LayoutManager {
  private eventManager: EventManagerInterface
  public configuration: LayoutConfiguration
  public state: AppLayoutState
  private unsubscribe = []

  /**
   * Layout configuration
   *
   * @param eventManager
   * @param layoutConfiguration
   */
  constructor(
    eventManager: EventManagerInterface,
    layoutConfiguration?: LayoutConfiguration,
  ) {
    this.configuration = layoutConfiguration ?? {
      sideMenu: {},
    }
    this.eventManager = eventManager
    this.state = Vue.observable(cloneDeep(defaultAppLayoutState))
    this.setupEvents()
  }

  async getNavigation() {
    return Promise.all(
      this.configuration.navigation.map(
        async (config): Promise<ActionMenuConfig> => {
          const asyncItems = config.items.map(getAsyncItem)

          const items = (await Promise.all(asyncItems)).filter(
            validateAssertion,
          )

          return {
            header: config.header,
            items,
          }
        },
      ),
    )
  }

  destroy() {
    this.unsubscribe.forEach((fx) => fx())
  }

  setupEvents() {
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.VIEW_DOCUMENT,
        (input: DocumentInput) => {
          this.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
            is: 'DocumentViewer',
            title: i18n.t(input.documentName),
            width: Math.min(window.innerWidth - 20, 1500),
            height: window.innerHeight * 0.9,
            props: {
              documentInput: input,
            },
          })
        },
      ),
    )

    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.DIALOG,
        (payload: DialogInterface) =>
          this.setDialogState(
            LayoutComponents.dialog,
            payload,
            DIALOG_DEFAULT_WIDTH,
          ),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.SIDE_SHEET,
        (payload: SideMenuInterface) =>
          this.setPage(LayoutComponents.sideSheet, payload),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.SIDE_SHEET_ADD_PAGE,
        (payload: SideMenuInterface) =>
          this.setPages(LayoutComponents.sideSheet, payload),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(LayoutWindowEvent.SIDE_SHEET_CLOSE, () =>
        this.setComponentState(LayoutComponents.sideSheet, false),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.FULL_PAGE_DIALOG,
        (payload: PageInterface) =>
          this.setPageState(LayoutComponents.fullPageDialog, payload),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.BOTTOM_SHEET,
        (payload: PageInterface) =>
          this.setPageState(LayoutComponents.bottomSheet, payload),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.LEFT_SHEET,
        (payload: SideMenuInterface) =>
          this.setDialogState(
            LayoutComponents.leftSheet,
            payload,
            LEFT_SHEET_DEFAULT_WIDTH,
          ),
      ),
    )

    this.unsubscribe.push(
      this.eventManager.subscribeEvent(LayoutWindowEvent.DIALOG_CLOSE, () =>
        this.close(LayoutComponents.dialog),
      ),
    )

    this.unsubscribe.push(
      this.eventManager.subscribeEvent(LayoutWindowEvent.SIDE_MENU_TOGGLE, () =>
        this.setComponentState(
          LayoutComponents.sideMenu,
          !this.state.sideMenu.state,
        ),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(LayoutWindowEvent.SIDE_MENU_OPEN, () =>
        this.setComponentState(LayoutComponents.sideMenu, true),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(LayoutWindowEvent.SIDE_MENU_CLOSE, () =>
        this.setComponentState(LayoutComponents.sideMenu, false),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(LayoutWindowEvent.TOP_BAR_OPEN, () =>
        this.setComponentState(LayoutComponents.topBar, true),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(LayoutWindowEvent.TOP_BAR_CLOSE, () =>
        this.setComponentState(LayoutComponents.topBar, false),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.ALERT,
        (payload: AlertInterface) =>
          this.setPayloadState(LayoutComponents.alertDialog, payload),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.CONFIRM,
        (payload: ConfirmInterface) =>
          this.setPayloadState(LayoutComponents.alertDialog, payload),
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.SNACK_SUCCESS,
        (payload: SnackBarInterface) => {
          this.setPayloadState(LayoutComponents.snackBar, {
            ...payload,
            type: SnackBarTypes.SUCCESS,
          } as SnackBarInterface)
        },
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.SNACK_INFO,
        (payload: SnackBarInterface) => {
          this.setPayloadState(LayoutComponents.snackBar, {
            ...payload,
            type: SnackBarTypes.INFO,
          } as SnackBarInterface)
        },
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.SNACK_ERROR,
        (payload: SnackBarInterface) => {
          this.setPayloadState(LayoutComponents.snackBar, {
            ...payload,
            type: SnackBarTypes.ERROR,
          } as SnackBarInterface)
        },
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.NOTIFICATION_CHIPS_ADD,
        (payload: NotificationChipsInterface) => {
          this.addNotificationChip(LayoutComponents.notificationChips, payload)
        },
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.NOTIFICATION_CHIPS_REMOVE,
        (payload: NotificationChipsInterface) => {
          this.removeNotificationChip(
            LayoutComponents.notificationChips,
            payload,
          )
        },
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.DIALOG_LOADING_OPEN,
        (payload: DialogLoadingInterface) => {
          this.setPayloadState(LayoutComponents.dialogLoading, {
            width: DIALOG_DEFAULT_WIDTH,
            ...payload,
          })
        },
      ),
    )
    this.unsubscribe.push(
      this.eventManager.subscribeEvent(
        LayoutWindowEvent.DIALOG_LOADING_CLOSE,
        () => {
          this.close(LayoutComponents.dialogLoading)
        },
      ),
    )
  }

  /**
   * Set the page
   * @param component
   * @param page
   */
  setPageState(component: LayoutComponents, page: PageInterface) {
    // review type FE-699
    //@ts-ignore
    this.state[component] = {
      state: true,
      page: page,
    }
  }
  setPageTitle(
    component: LayoutComponents,
    newTitle: string,
    pageIndex: number,
  ) {
    //@ts-ignore
    this.state[component].pages[pageIndex].title = newTitle
  }
  /**
   * Set the page
   * @param component
   * @param page
   * @param defaultWidth
   */
  setPage(
    component: LayoutComponents.sideSheet,
    page: SideMenuInterface,
    defaultWidth?: string | number,
  ) {
    // review type FE-699
    this.state[component] = {
      state: true,
      pages: [page],
      width: page.width ? page.width : defaultWidth,
    }
  }

  /**
   * Set the page
   * @param component
   * @param page
   * @param defaultWidth
   */
  setPages(
    component: LayoutMultiPages,
    page: SideMenuInterface,
    defaultWidth?: string | number,
  ) {
    // review type FE-699
    this.state[component] = {
      state: true,
      pages: [...this.getPages(LayoutComponents.sideSheet), page],
      width: page.width || defaultWidth,
    }
  }

  /**
   * Set the page
   * @param component
   * @param page
   * @param defaultWidth
   */
  setDialogState(
    component: LayoutComponents,
    page: DialogInterface | SideMenuInterface,
    defaultWidth: number | string,
  ) {
    // review type FE-699
    //@ts-ignore
    this.state[component] = {
      state: true,
      page,
      width: page.width ? page.width : defaultWidth,
      height: page.height,
    }
  }

  /**
   * Get the state of a component
   * @param component
   */
  getComponentState<T extends keyof AppLayoutState>(
    component: T,
  ): AppLayoutState[T] {
    return this.state[component]
  }

  /**
   * Set the state of a component
   * @param component
   * @param state
   */
  setComponentState(component: LayoutComponents, state: boolean) {
    this.state[component].state = state
  }

  /**
   * Set the payload state
   * @param component
   * @param payload
   */
  setPayloadState(component: LayoutComponents, payload: any) {
    //review type FE-699
    //@ts-ignore
    this.state[component] = {
      state: true,
      payload: payload,
    }
  }

  close<T extends LayoutComponents>(component: T) {
    this.state[component] = {
      ...this.state[component],
      state: false,
    }
  }

  resetPages = (component: LayoutMultiPages) => {
    this.state[component].pages = []
  }

  getPages(component: LayoutMultiPages): PageInterface[] {
    return this.state[component].pages || []
  }

  nextPage(component: LayoutMultiPages, page: PageInterface) {
    this.state[component].pages = [...this.getPages(component), page]
  }

  setPageStack(component: LayoutMultiPages, pages: PageInterface[]) {
    this.state[component].pages = [...pages]
  }

  getActivePage(component: LayoutMultiPages): PageInterface | null {
    const pages = this.state[component]?.pages
    const pagesLength = pages?.length

    return pagesLength ? pages[pagesLength - 1] : null
  }

  /**
   * Add notification chip
   * Adds a notification chip to the array of notifications, creating a list
   * at the bottom of the page with one or several chips
   */
  addNotificationChip(
    component: LayoutComponents.notificationChips,
    payload: NotificationChipsInterface,
  ) {
    this.state[component].chips = [...this.state[component].chips, payload]
  }

  /**
   * Remove notification chip
   * Filter the existing notifications and remove the selected chip from the stack
   */
  removeNotificationChip(
    component: LayoutComponents.notificationChips,
    payload: NotificationChipsInterface,
  ) {
    this.state[component].chips = this.state[component].chips.filter(
      (item) => item?.key !== payload?.key,
    )
  }
}
