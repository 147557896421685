import { Breakpoint } from 'vuetify/types/services/breakpoint'
import { VueConstructor } from 'vue'

import {
  ActionMenuItem,
  ActionMenuItemCallback,
  ActionMenuName,
} from '@/tt-app-layout/types'
import { AppContext } from '@/tt-app-context/types'
import { DocumentInput } from '@/tt-entity-documents/types'
import { EntityItemViewInterface } from '@/tt-widget-entity-flow/EntityItemHook'
import { WidgetCategoryModel, WidgetStoreModel } from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'

export enum LayoutExtensionSlots {
  BODY = 'ANYWHERE',
  TOOLBAR_LEFT = 'TOOLBAR_LEFT',
  TOOLBAR_RIGHT = 'TOOLBAR_RIGHT',
  SIDE_MENU_TOP = 'SIDE_MENU_TOP',
  SIDE_MENU_BOTTOM = 'SIDE_MENU_BOTTOM',
  USER_MENU_ITEM = 'USER_MENU_ITEM',
  FOOTER_LEFT = 'FOOTER_LEFT',
  FOOTER_RIGHT = 'FOOTER_RIGHT',
  BRANDING = 'BRANDING_SLOT',
}

/**
 * Extension slot. Where you can plug
 */
export type ExtensionSlot = LayoutExtensionSlots

/**
 * Extension component reference
 */
export interface ExtensionComponentReference {
  is: string | VueConstructor
  props?: any
}

export type ExtensionInterfaceEnabledCallback = (
  extension: ExtensionInterface,
  breakpoint: Breakpoint,
) => boolean

/**
 * Tab slot item interface for connecting tabs
 */
export interface TabSlotItemInterface {
  title: string
  is: string | VueConstructor
  props?: any
}

export interface ModularItemsInterface {
  name?: string
  payload?: any
  assert?: boolean | (() => boolean)
  [key: string]: any
}

export interface GlobalEventInterface {
  name: string
}

/**
 * Extension reference
 */
export interface ExtensionInterface {
  name: string
  enabled?: boolean | ExtensionInterfaceEnabledCallback
  slots?: ExtensionSlot[]
  component?: ExtensionComponentReference
  items?: any[]
  globalEvents?: GlobalEventInterface[]
}

export enum PresetLevels {
  DEFAULTS,
  APPLICATION,
  INTEGRATION,
  MODULE,
  USER_DEFINED,
}

export enum PresetTypes {
  ACTIONS_CARD = 'actionsCard',
  ATTRIBUTE_VIEW = 'attributeView',
  CALENDAR_EVENT = 'calendarEvent',
  COLUMN = 'column',
  COLUMNS = 'columns',
  DETAILS_CARD = 'detailCard',
  DOCUMENT = 'document',
  FORM = 'form',
  LIST_ITEM = 'listItem',
  LOGO_COLOR = 'logoColor',
  META_CARD = 'metaCard',
  PREVIEW_TABS = 'previewTab',
  RELATION_REFERENCE = 'relationReference',
}

export enum ActionFormTypes {
  FULL_ENTITY = 'form',
  CREATE = 'create',
  EDIT = 'edit',
}

export interface DocumentPresetInterface<T = DocumentInput> {
  name: string

  toDocument(
    appContext: AppContext,
    entityReference: EntityItemViewInterface,
    locale?: string,
  ): Promise<T>
}

// Presets
export interface PresetItem {
  preset?: string // The preset
  title: string // The user title
  tag?: string // A tag that can be taken
  level?: PresetLevels // Where it is registered on
  data: any
  hide?: boolean // force hiding the tt-preset-view
  order?: number
}

export type ActionBarPreset = {
  is: VueConstructor
}

export type ModularManagerState = {
  slots: Record<string, ExtensionInterface[]>
  modularItems: ModularItemsInterface[]
  presets: Partial<Record<Resources, PresetItem[]>>
  extensions: ExtensionInterface[]
  menus: Record<string, ActionMenuItemCallback[]>
  jsonSchemaActions: Partial<Record<Resources, string[]>>
  /**
   * Dictionnary of custom action bar component registered by resource.
   * If defined for a resource, it will show the custom component instead of the default list of API actions.
   */
  resourceActionBar: Partial<Record<Resources, ActionBarPreset>>
}

export interface ModularManagerInterface {
  hasItems(name: string): boolean

  hasExtensions(slotName: string): boolean

  addItem(name: string, payload: any)

  getResourcePreset(
    resource: string,
    presetType: PresetTypes,
    tag?: string,
  ): PresetItem | null

  getResourcePresets(
    resource: string,
    presetType: PresetTypes,
    tag?: string,
  ): PresetItem[] | null

  getResourceAttributePreset(
    resource: string,
    presetType: PresetTypes,
    attributeName: string,
    tag?: string,
  ): PresetItem | null

  getPresets(presetKey: string, tag?: string): PresetItem[] | null

  getPreset(presetKey: string, tag?: string): PresetItem | null

  addResourcePreset(resource: string, preset: PresetTypes, payload: PresetItem)

  addPreset(presetKey: string, payload: PresetItem)

  getResourceAttributePresetKey(
    resource: string,
    preset: string,
    attributeName: string,
  )

  hasResourcePreset(resource: string, preset: string, tag?: string): boolean

  hasPreset(presetKey: string, tag?: string): boolean

  addWidgetActionMenuItem(
    payload: ActionMenuItemCallback<WidgetStoreModel>,
  ): void

  addCategoryActionMenuItem(
    payload: ActionMenuItemCallback<WidgetCategoryModel>,
  ): void

  getMenuItems(
    appContext: AppContext,
    menuName: ActionMenuName,
    modelContext?: WidgetStoreModel | WidgetCategoryModel,
    resource?: Resources,
  ): Promise<ActionMenuItem[]>

  getItems(name: string): ModularItemsInterface[]

  registerExtension(extension: ExtensionInterface)

  setResourcePresets(
    resource: string,
    preset: PresetTypes,
    payload: PresetItem[],
  )

  hasDynamicResourceJsonSchemaAction(resource: string, action: string): boolean

  getDynamicResourceJsonSchemaActions(resource: string): string[]

  registerDynamicResourceJsonSchemaActions(
    resource: string,
    actions: string[],
  ): void

  getSlot(slotName: string): ExtensionInterface[]

  /**
   * Register a custom action bar for a resource
   */
  registerActionBar(resource: Resources, actionBarPreset: ActionBarPreset): void

  hasActionBar(resource: Resources): boolean

  /**
   * Get the registered action bar of the resource (if any)
   */
  getActionBar(resource: Resources): ActionBarPreset | null
}
