<template>
  <v-app id="side-sheet-stand-alone">
    <v-main>
      <ProvideLayoutManager :layout-manager="manager">
        <AppLayoutSideSheet />
      </ProvideLayoutManager>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { LayoutConfiguration } from '../types'
import AppLayoutSideSheet from './AppLayoutSideSheet.vue'
import ProvideLayoutManager from './ProvideLayoutManager.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'

export default Vue.extend({
  name: 'AppLayoutSideSheetStandAlone',
  components: {
    ProvideLayoutManager,
    AppLayoutSideSheet,
  },
  props: {
    layoutConfiguration: {
      type: Object as PropType<LayoutConfiguration>,
      default: () => ({}),
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager, this.layoutConfiguration),
    }
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
})
</script>
