<template>
  <v-container fluid>
    <div class="d-flex">
      <div class="d-flex flex-column justify-center pa-2">
        <v-card class="pa-2" color="bg-grey3" elevation="0">
          <h6 class="caption d-flex justify-end text-right text-no-wrap">
            <span class="mr-2" v-text="$t(startLabel)" />
            <tt-attr
              name="startedOn"
              format="asDateTime"
              value-css-class="font-weight-bold"
            />
          </h6>
          <h6 class="caption d-flex justify-end text-right text-no-wrap">
            <span class="mr-2" v-text="$t(endLabel)" />
            <tt-attr
              name="endedOn"
              format="asDateTime"
              value-css-class="font-weight-bold"
            />
          </h6>
        </v-card>
      </div>
      <div>
        <tt-tile class="tile" :avatar-size="48">
          <tt-attr-img slot="avatar" name="employee.avatar" />
          <tt-attr slot="title" name="employee.name" class="font-weight-bold" />

          <tt-attr
            slot="line2"
            name="employee.jobTitle"
            prepend-icon="mdi-account-circle"
            prepend-icon-color="green"
          />
        </tt-tile>
      </div>
      <div class="d-flex align-items-center justify-center">
        <ApplyActionProvider>
          <v-row>
            <v-col
              v-for="action in supportedActions"
              :key="action"
              cols="auto"
              align-self="center"
            >
              <tt-attr-action :action="action">
                <LeaveRequestsBtnAction :action="action" />
              </tt-attr-action>
            </v-col>
          </v-row>
        </ApplyActionProvider>
      </div>
    </div>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'

import type Resource from '@/config/resources-meta.json'
// Getting the supported API actions
type Actions = keyof (typeof Resource)['leave-requests']['actions']

export default Vue.extend({
  name: 'LeavePoliciesPendingLeaveRequestInfo',
  computed: {
    startLabel(): string {
      return 'res.leave-requests.attr.startedOn.label'
    },
    endLabel(): string {
      return 'res.leave-requests.attr.endedOn.label'
    },
    supportedActions(): Actions[] {
      return ['approve', 'decline']
    },
  },
})
</script>
